import i18n from 'i18n-js';
import country from 'country-list-js';
import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, Alert, Platform } from 'react-native';
import { Actionsheet, useDisclose, Spinner, useToast } from 'native-base';
import styled from 'styled-components/native';
import usersApi from '../../apis/users';
import HeaderWithIcons from '../../components/common/HeaderWithIcons';
import useAsync from '../../hooks/useAsync';
import calculateAgeFromDate from '../../utils/calculateAgeFromDate';
import ProfileCardWithTitle from '../../components/common/ProfileCardWithTitle';
import handleErrorResponse from '../../services/handleErrorResponse';
import MYButton from '../../components/ui/MYButton';

export default function BlockedUsersScreen({ navigation }) {
  const { isOpen, onOpen, onClose } = useDisclose();
  const [selectedUSerId, setselectedUSerId] = useState(null);
  const toast = useToast();

  function alert(title = undefined, msg, footer = [{ text: 'ok', onPress: () => null, style: 'cancel' }]) {
    if (Platform.OS === 'web') {
      toast.show({ title: title, status: "info", description: msg })
    } else {
      Alert.alert(title, msg, footer);
    }
  }

  const { execute, status, response, error } = useAsync(() => {
    return usersApi.blockedUsers();
  }, true);

  function handleUnBlockUser() {
    usersApi
      .unblockUser({
        userId: selectedUSerId,
      })
      .then(async (res) => {
        onClose();
        execute();
        alert('Success', 'successfull');
      })
      .catch((error) => {
        if (error.response) {
          alert('Error', handleErrorResponse(error.response.data?.message));
        }
      });
  }

  const RenderEmpty = <Text style={{ textAlign: 'center', marginTop: 30 }}>No users found</Text>;

  return (
    <Container>
      <HeaderWithIcons title={i18n.t('blocked_users.header_title')} onPress={() => navigation.goBack()} />

      <ScrollView>
        {status === 'pending' && <Spinner size="lg" style={{ marginTop: 50 }} />}
        {response?.data?.results?.length > 0 ? (
          <ItemsContainer>
            {response?.data?.results?.map((i, index) => {
              const item = i?.userDetails?.[0];
              const userCountry = country.findByIso2(item?.countryCode?.toUpperCase())?.name;
              // const userCity = null;
              const userCity = item?.city;
              return (
                <Item key={index}>
                  <TouchableOpacity
                    onPress={() => {
                      onOpen();
                      setselectedUSerId(item?._id);
                    }}>
                    <ProfileCardWithTitle
                      img={item?.avatar ? { uri: item?.avatar } : require('../../assets/images/defaultavatar1.png')}
                      name={item?.name}
                      age={item?.birthdate && calculateAgeFromDate(item?.birthdate) + ' ' + i18n.t('global.years_old')}
                      location={userCity}
                    />
                  </TouchableOpacity>
                </Item>
              );
            })}
          </ItemsContainer>
        ) : (
          status !== 'pending' && RenderEmpty
        )}
      </ScrollView>

      <Actionsheet
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setselectedUSerId();
        }}>
        <Actionsheet.Content>
          <ActionSheetContainer>
            <MYButton color="red" style={{ width: '100%', marginTop: 24 }} onPress={handleUnBlockUser}>
              {i18n.t('blocked_users.unblock')}
            </MYButton>
            <MYButton color="grayOutline" style={{ width: '100%', marginTop: 20 }} onPress={onClose}>
              {i18n.t('global.close')}
            </MYButton>
          </ActionSheetContainer>
        </Actionsheet.Content>
      </Actionsheet>
    </Container>
  );
}

const ActionSheetContainer = styled.View`
  padding: 16px 25px 20px;
  width: 100%;
`;

const Container = styled.View`
  padding: 0 20px;
  display: flex;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
  flex: 1;
`;
const ScrollView = styled.ScrollView``;

const ItemsContainer = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;
const Item = styled.View`
  width: 33%;
`;
