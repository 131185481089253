import { Input } from 'native-base';
import React from 'react';

import { useTheme } from '../template/ThemeProvider';

export default function Myinput(props) {
  const { isDark = false } = useTheme();
  // const isDark = false;
  return <Input style={{ color: isDark ? '#ffffff' : '#000' }} {...props} />;
}
