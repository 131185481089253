// import country from 'country-list-js';
import * as ExpoLocation from 'expo-location';
import i18n from 'i18n-js';
import { Spinner, useToast } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Alert, RefreshControl, Text, TouchableOpacity, Platform, ScrollView, VirtualizedList, FlatList } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';

import startupApi from '../../apis/startup';
import usersApi from '../../apis/users';
import BrandHeader from '../../components/common/BrandHeader';
import ProfileCardWithTitle from '../../components/common/ProfileCardWithTitle';
import StartupBanners from '../../components/common/StartupBanners';
import ActionSheetFilters from '../../components/pages/search/ActionSheetFilters';
import IconSettings1 from '../../components/svg/IconSettings1';
import useAsync from '../../hooks/useAsync';
import { open } from '../../redux/slices/ActionSheetSlice';
import { close as closeModal, open as openModal } from '../../redux/slices/ModalSlice';
import { addToViewedBanners, saveStartUpData } from '../../redux/slices/StartupSlice';
import handleErrorResponse from '../../services/handleErrorResponse';
import calculateAgeFromDate from '../../utils/calculateAgeFromDate';

// function ifCloseToBottom({ layoutMeasurement, contentOffset, contentSize }) {
//   const paddingToBottom = 1;
//   return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom;
// }
const limit = 21;

export default function SearchHomeScreen({ navigation }) {
  const dispatch = useDispatch();
  const startupReduxState = useSelector((state) => state.startup);
  const [filters, setfilters] = useState(null);
  const [rawfilters, setRawfilters] = useState(null);
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationTotal, setPaginationTotal] = useState(0);
  const [Data, setData] = useState([]);
  const [loading, setloading] = useState(true);
  const [refreshing, setrefreshing] = useState(false);
  const [error, seterror] = useState(null);
  const toast = useToast();

  function alert(title = undefined, msg, footer = [{ text: 'ok', onPress: () => null, style: 'cancel' }]) {
    if (Platform.OS === 'web') {
      toast.show({ title: title, status: 'info', description: msg });
    } else {
      Alert.alert(title, msg, footer);
    }
  }

  function getUsers(page = 1) {
    setloading(true);

    if (page === 1) {
      setrefreshing(true);
      setData([]);
    }

    return usersApi
      .searchUsers(`page=${page}&limit=${limit}${filters ? `&${filters}` : ''}`)
      .then((res) => {
        if (res?.data?.results?.length) {
          setPaginationPage((prev) => (page === 1 ? 1 : prev + 1));
          setPaginationTotal(res.data?.total);

          return page === 1 ? setData(res.data.results) : setData((prev) => [...(prev || []), ...res.data.results]);
        } else {
          if (page === 1) {
            setData([]);
          }
        }
      })
      .catch((error) => {
        const err = error.response ? handleErrorResponse(error.response.data?.message) : 'Problem in getting data';
        // console.log('error send msg', error.response);
        alert('Error', err);
        seterror(err);
      })
      .finally(() => {
        setloading(false);
        setrefreshing(false);
      });
  }

  const {
    execute: execute2,
    status: status2,
    response: response2,
  } = useAsync(() => {
    return startupApi.postStartUp();
  }, true);

  useEffect(() => {
    if (filters) {
      getUsers(1);
    }
  }, [filters]);

  // useEffect(() => {
  //   const unsubscribe = navigation.addListener('focus', () => {
  //     if (filters || rawfilters) {
  //       getUsers(1);
  //       setRawfilters(null);
  //       setfilters(null);
  //     } else if (!Data?.length) {
  //       getUsers(1);
  //     }
  //   });

  //   return unsubscribe;
  // }, [navigation, Data, filters, rawfilters]);

  useEffect(() => {
    getUsers(1);
  }, []);

  function showStartupBanners(bannerIndex = 0) {
    if (bannerIndex + 1 > response2?.data?.banners?.length) {
      return;
    }

    const banner = response2?.data?.banners?.[bannerIndex];

    function showNextBanner() {
      showStartupBanners(bannerIndex + 1);
    }

    if (startupReduxState?.viewedBannersIds?.includes(banner?._id)) {
      showNextBanner();
      return;
    }

    function handleOnClose(isViewed) {
      dispatch(closeModal());

      if (isViewed) {
        dispatch(addToViewedBanners({ id: banner?._id }));
      }

      setTimeout(() => {
        showNextBanner();
      }, 350);
    }

    dispatch(
      openModal({
        content: () => <StartupBanners banner={banner} navigation={navigation} onClose={handleOnClose} />,
        onClose: (isViewed) => handleOnClose(isViewed),
        size: 'lg',
      })
    );
  }

  useEffect(() => {
    if (response2?.data) {
      dispatch(saveStartUpData(response2?.data));
    }

    if (response2?.data?.banners?.length > 0) {
      showStartupBanners(0);
    }

    (async () => {
      const { status } = await ExpoLocation.requestForegroundPermissionsAsync();
      if (status !== 'granted') {
        // Alert.alert('Permission to access location was denied');
        return;
      }

      const location = await ExpoLocation.getCurrentPositionAsync({});

      if (location?.coords?.latitude && location?.coords?.longitude) {
        usersApi
          .updateProfile({
            'loc.type': 'Point',
            'loc.coordinates': [`${location?.coords?.longitude}`, `${location?.coords?.latitude}`],
          })
          .then((res) => {
            // console.log(res);
          })
          .catch(() => {
            // console.log(err?.response);
          });
      }
    })();
  }, [response2]);

  function handleOpenActionSheet() {
    dispatch(
      open({
        height: 560,
        content: () => <ActionSheetFilters onFilter={(v) => setfilters(v)} setRawfilters={(v) => setRawfilters(v)} rawfilters={rawfilters} />,
      })
    );
  }

  const RenderEmpty = <Text style={{ textAlign: 'center', marginTop: 30 }}>{error || i18n.t('search.no_user_found')}</Text>;

  const renderItem = ({ item }) => (
    <Item>
      <TouchableOpacity onPress={() => navigation.navigate('SingleUserProfileScreen', { userData: item })}>
        <ProfileCardWithTitle
          img={item?.avatar ? { uri: item?.avatar } : require('../../assets/images/defaultavatar1.png')}
          name={item?.name}
          age={item?.birthdate && calculateAgeFromDate(item?.birthdate) + ' ' + i18n.t('global.years_old')}
          location={item?.city}
        />
      </TouchableOpacity>
    </Item>
  );

  return (
    <Container>
      <BrandHeader
        rightNode={
          <TouchableOpacity onPress={() => handleOpenActionSheet()}>
            <IconSettings1 />
          </TouchableOpacity>
        }
      />
      {!Data?.length && loading && <LoadingSpinner size="lg" />}

      {Data?.length > 0 ? (
        <FlatList
          data={Data}
          renderItem={renderItem}
          keyExtractor={(item, index) => index}
          numColumns={3}
          refreshControl={<RefreshControl refreshing={Data?.length > 0 && refreshing} onRefresh={() => getUsers(1)} />}
          ListFooterComponent={Data?.length < paginationTotal ? <LoadingSpinner size="lg" /> : null}
          onEndReachedThreshold={2}
          onEndReached={() => {
            if (Data?.length && Data?.length < paginationTotal) {
              getUsers(paginationPage + 1);
            }
          }}
        />
      ) : (
        !loading && RenderEmpty
      )}
    </Container>
  );
}

const Container = styled.View`
  padding: 0 20px;
  display: flex;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
  flex: 1;
`;

// const ItemsContainer = styled.View`
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   justify-content: flex-start;
// `;
const Item = styled.View`
  width: 33%;
  /* width: 50%; */
  /* flex-direction: row; */
  /* flex: 1; */
`;
const LoadingSpinner = styled(Spinner)`
  width: 100%;
  height: 100px;
`;
