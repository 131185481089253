import i18n from 'i18n-js';
import { Actionsheet, useDisclose } from 'native-base';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { View, Text, BackHandler, Animated, PanResponder, Dimensions, ScrollView, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';

import evaluationApi from '../../apis/evaluation';
import HeaderWithIcons from '../../components/common/HeaderWithIcons';
import UserProfileCard from '../../components/common/UserProfileCard';
import ActionSheetReport from '../../components/pages/userProfile/ActionSheetReport';
import ArrowLeft from '../../components/svg/ArrowLeft';
import IconClose from '../../components/svg/IconClose';
import IconLoveEmpty from '../../components/svg/IconLoveEmpty';
import IconReport from '../../components/svg/IconReport';
const { width, height } = Dimensions.get('screen');

const ACTION_OFFSET = 100;
const OUT_OF_SCREEN = width + 1 * width;

// const dataArry = [
//   {
//     name: 'Maryam',
//     age: '25',
//     image: require('../../assets/images/defaultavatar1.png'),
//     location_title: 'Toronto',
//   },
//   {
//     name: 'Maryam',
//     age: '25',
//     image: require('../../assets/images/defaultavatar1.png'),
//     location_title: 'Toronto',
//   },
//   {
//     name: 'Maryam',
//     age: '25',
//     image: require('../../assets/images/defaultavatar1.png'),
//     location_title: 'Toronto',
//   },
//   {
//     name: 'Maryam',
//     age: '25',
//     image: require('../../assets/images/defaultavatar1.png'),
//     location_title: 'Toronto',
//   },
//   {
//     name: 'Maryam',
//     age: '25',
//     image: require('../../assets/images/defaultavatar1.png'),
//     location_title: 'Toronto',
//   },
//   {
//     name: 'Maryam',
//     age: '25',
//     image: require('../../assets/images/defaultavatar1.png'),
//     location_title: 'Toronto',
//   },
// ];

export default function SwipableCardsScreen({ route, navigation }) {
  useEffect(() => {
    const backAction = () => {
      navigation.goBack();
      return true;
    };

    const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

    return () => backHandler.remove();
  }, []);
  const { list } = route.params;
  const [Data, setData] = useState(list);
  const [surfedCounter, setsurfedCounter] = useState(0);
  const [InViewData, setInViewData] = useState(Data?.slice(0, 2));
  const { isOpen, onOpen, onClose } = useDisclose();

  const swipe = useRef(new Animated.ValueXY()).current;
  const tiltSign = useRef(new Animated.Value(1)).current;

  const panResponder = PanResponder.create({
    onMoveShouldSetPanResponder: (_, gestureState) => {
      return Math.abs(gestureState.dx) > Math.abs(gestureState.dy * 4);
    },
    onPanResponderMove: (_, { dx, dy, y0 }) => {
      swipe.setValue({ x: dx, y: dy });
      tiltSign.setValue(y0 > height / 2 ? 1 : -1);
    },
    onPanResponderRelease: (_, { dx, dy }) => {
      const direction = Math.sign(dx);
      const isActionActive = Math.abs(dx) > ACTION_OFFSET;

      if (isActionActive) {
        Animated.timing(swipe, {
          duration: 300,
          toValue: {
            x: direction * OUT_OF_SCREEN,
            y: dy,
          },
          useNativeDriver: true,
        }).start(removeTopCard);

        handleSaveLikeDislike(direction);
      } else {
        Animated.spring(swipe, {
          toValue: {
            x: 0,
            y: 0,
          },
          useNativeDriver: true,
          friction: 5,
        }).start();
      }
    },
  });

  const rotate = Animated.multiply(swipe.x, tiltSign).interpolate({
    inputRange: [-ACTION_OFFSET, 0, 100],
    outputRange: ['8deg', '0deg', '-8deg'],
  });

  const animatedCardStyle = {
    transform: [...swipe.getTranslateTransform(), { rotate }],
  };

  const animatedSecondCardStyle = {
    scaleX: swipe.x.interpolate({
      inputRange: [-ACTION_OFFSET, -25, 25, ACTION_OFFSET],
      outputRange: [1, 0.9, 0.9, 1],
      extrapolate: 'clamp',
    }),
    scaleY: swipe.x.interpolate({
      inputRange: [-ACTION_OFFSET, -25, 25, ACTION_OFFSET],
      outputRange: [1, 0.9, 0.9, 1],
      extrapolate: 'clamp',
    }),
  };

  const likeOpacity = swipe.x.interpolate({
    inputRange: [25, ACTION_OFFSET],
    outputRange: [0, 1],
    extrapolate: 'clamp',
  });

  const nopeOpacity = swipe.x.interpolate({
    inputRange: [-ACTION_OFFSET, -25],
    outputRange: [1, 0],
    extrapolate: 'clamp',
  });

  const RenderChoiceOpacity = useCallback(() => {
    return (
      <OverlyContainer pointerEvents="none">
        <NopeOverlay style={{ opacity: nopeOpacity }}>
          <IconNope width={100} height={100} />
        </NopeOverlay>
        <LikedOverlay style={{ opacity: likeOpacity }}>
          <LoveIcon width={100} height={100} />
        </LikedOverlay>
      </OverlyContainer>
    );
  }, [likeOpacity, nopeOpacity]);

  const removeTopCard = useCallback(() => {
    setInViewData((prev) => [...prev.slice(1), ...Data?.slice(surfedCounter + 2, surfedCounter + 3)]);
    setsurfedCounter((prev) => prev + 1);

    swipe.setValue({ x: 0, y: 0 });
  }, [swipe, surfedCounter, InViewData]);

  async function handleSaveLikeDislike(direction) {
    // console.log('direction', direction, 'InViewData', InViewData[0], surfedCounter);
    if (!InViewData[0]?._id) {
      return;
    }

    if (direction >= 1) {
      evaluationApi.likeUser(InViewData[0]?._id).then((res) => console.log(res.data));
    } else {
      evaluationApi.dislikeUser(InViewData[0]?._id).then((res) => console.log(res.data));
    }
  }

  const handleChoice = useCallback(
    (direction) => {
      Animated.timing(swipe.x, {
        toValue: direction * OUT_OF_SCREEN,
        duration: 750,
        useNativeDriver: true,
      }).start(removeTopCard);
      handleSaveLikeDislike(direction);
    },
    [removeTopCard, swipe.x, InViewData]
  );

  return (
    <Container>
      <BgImage source={require('../../assets/images/userprofilebg1.jpg')} />
      <HeaderWithIcons
        leftNode={
          <IconBox>
            <ArrowLeft fill="#ffffff" />
          </IconBox>
        }
        rightNode={
          InViewData?.length > 0 && (
            <TouchableOpacity onPress={onOpen}>
              <IconBox style={{ paddingTop: 15 }}>
                <IconReport color="#ffffff" />
              </IconBox>
            </TouchableOpacity>
          )
        }
        onPress={() => navigation.goBack()}
      />

      {InViewData?.map((item, index) => {
        const isFirst = index === 0;
        const dragHandlers = isFirst ? panResponder.panHandlers : {};
        return (
          <UserProfileCardWrapper key={index} {...dragHandlers} style={[isFirst && animatedCardStyle, index === 1 && animatedSecondCardStyle]}>
            {isFirst && <RenderChoiceOpacity />}
            <UserProfileCard handleChoice={handleChoice} showDislike isLiked={false} length={Data?.length} navigation={navigation} userData={item} />
          </UserProfileCardWrapper>
        );
      }).reverse()}
      {InViewData?.length <= 0 && <NoMoreProfileText>{i18n.t('discover.no_more_users')}</NoMoreProfileText>}
      <Actionsheet isOpen={isOpen} onClose={onClose}>
        <ActionSheetReport onClose={onClose} userId={InViewData[0]?._id} />
      </Actionsheet>
    </Container>
  );
}

const Container = styled.View`
  display: flex;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
  flex: 1;
`;
const BgImage = styled.Image`
  position: absolute;
  width: 100%;
  border-bottom-left-radius: 76px;
  height: 222px;
`;
const IconBox = styled.View`
  width: 52px;
  height: 52px;
  border: 1px solid;
  border-radius: 200px;
  border-color: ${(p) => `${p.theme.myColors.pale_grey}42`};
  padding: 17px 0 0 16px;
  ${(p) => !p.children && `border: none`}
`;
const UserProfileCardWrapper = styled(Animated.View)`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0 35px;
  margin-top: 130px;
  padding-bottom: 130px;
  /* background-color: red; */
`;
const OverlyContainer = styled.View`
  position: absolute;
  background-color: transparent;
  /* width: 100%;
  height: 100%; */
  top: 0;
  bottom: 130px;
  left: 35px;
  right: 35px;
  z-index: 2;
`;
const LikedOverlay = styled(Animated.View)`
  position: absolute;
  background-color: #f53f5f86;
  width: 100%;
  height: 100%;
  border-radius: 12px;
`;
const LoveIcon = styled(IconLoveEmpty)`
  margin: 50px 0 0 50px;
`;

const NopeOverlay = styled(Animated.View)`
  position: absolute;
  background-color: #6c6cff86;
  width: 100%;
  height: 100%;
  border-radius: 12px;
`;
const IconNope = styled(IconClose)`
  margin: 50px 50px 0 auto;
`;
const NoMoreProfileText = styled.Text`
  margin: 250px auto;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dark2)};
  text-align: center;
  font-family: Gilroy-Medium;
`;
