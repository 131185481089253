import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={24} height={24} color="#49497A" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path fillRule="evenodd" clipRule="evenodd" d="M4.25 7A.75.75 0 015 6.25h14a.75.75 0 010 1.5H5A.75.75 0 014.25 7z" fill="currentColor" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 6.25a.75.75 0 01.75.75v11A1.25 1.25 0 008 19.25h8A1.25 1.25 0 0017.25 18V7a.75.75 0 011.5 0v11A2.75 2.75 0 0116 20.75H8A2.75 2.75 0 015.25 18V7A.75.75 0 016 6.25zM8.25 3.75A.75.75 0 019 3h6a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 10.25a.75.75 0 01.75.75v5a.75.75 0 01-1.5 0v-5a.75.75 0 01.75-.75zM14 10.25a.75.75 0 01.75.75v5a.75.75 0 01-1.5 0v-5a.75.75 0 01.75-.75z"
        fill="currentColor"
      />
    </Svg>
  );
}

export default SvgComponent;
