import i18n from 'i18n-js';
// import country from 'country-list-js';
import { LinearGradient } from 'expo-linear-gradient';
import { Spinner } from 'native-base';
import React, { useEffect } from 'react';
import { TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';

import usersApi from '../../../apis/users';
import useAsync from '../../../hooks/useAsync';
import calculateAgeFromDate from '../../../utils/calculateAgeFromDate';
import BgCircleMask_ from '../../svg/BgCircleMask';
import IconLocation_ from '../../svg/IconLocation';
import LogoWhite_ from '../../svg/LogoWhite';
// import { useTheme } from '../../template/ThemeProvider';

export default function Header({ navigation, doRefresh }) {
  //   const { colors, isDark, setScheme } = useTheme();
  const userReduxState = useSelector((state) => state.user);
  const myGender = userReduxState?.userInfo?.genderEnumId === 1 ? 1 : 2;

  const { execute, status, response, error } = useAsync(() => {
    return usersApi.getSuggestionList();
  }, false);

  useEffect(() => {
    execute();
  }, [doRefresh]);

  const firstUser = response?.data?.length > 0 && response?.data?.[0];

  const RenderEmpty = (
    <TouchableOpacity onPress={() => navigation.navigate('Search')}>
      <CardImage
        source={myGender === 1 ? require('../../../assets/images/defaultavatar2.png') : require('../../../assets/images/defaultavatar1.png')}
      />
      {/* <CardTitle>No new users!</CardTitle> */}
      <EmptyCardSubtitle>{i18n.t('discover.search_for_more_people')}</EmptyCardSubtitle>
    </TouchableOpacity>
  );

  // const userCountry = country.findByIso2(firstUser?.countryCode?.toUpperCase())?.name;
  const userCity = firstUser?.city;

  return (
    <Container>
      <GradientContainer colors={['#9f64ff', '#6d28fc']}>
        <BgCircleMask />
        <BgCircleMask2 />
        <Logo />
      </GradientContainer>

      <Card
        style={{
          shadowColor: '#6c6aae',
          shadowOffset: { width: 0, height: 1 },
          shadowOpacity: 0.17,
          shadowRadius: 2,
          elevation: 13,
        }}>
        {status === 'pending' && <Spinner size="lg" style={{ marginTop: 50 }} />}
        {response?.data?.length > 0 ? (
          <TouchableOpacity onPress={() => navigation.navigate('SwipableCardsScreen', { list: response?.data })}>
            <CardImage
              source={
                firstUser?.avatar
                  ? { uri: firstUser?.avatar }
                  : firstUser?.genderEnumId === 1
                  ? require('../../../assets/images/defaultavatar1.png')
                  : require('../../../assets/images/defaultavatar2.png')
              }
            />
            <CardTitle>{firstUser?.name}</CardTitle>
            <CardSubtitle>{firstUser?.birthdate ? calculateAgeFromDate(firstUser?.birthdate) + ' ' + i18n.t('global.years_old') : ''}</CardSubtitle>
            {userCity && (
              <CardLocationBox>
                <LocationIcon color="#fff" />
                <LocationText>{userCity || ''}</LocationText>
              </CardLocationBox>
            )}
          </TouchableOpacity>
        ) : (
          status !== 'pending' && RenderEmpty
        )}
      </Card>
      <CardBg1 />
      <CardBg2 />
    </Container>
  );
}

const Container = styled.View`
  /* background: linear-gradient(45deg, #1d3475, #060d1f); */
  position: relative;
  padding-bottom: 136px;
  /* padding-bottom: 236px; */
  /* background-color: red; */
`;

const GradientContainer = styled(LinearGradient)`
  padding-bottom: 171px;
`;

const BgCircleMask = styled(BgCircleMask_)`
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`;
const BgCircleMask2 = styled(BgCircleMask_)`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  transform: rotate(180deg);
`;

const Logo = styled(LogoWhite_)`
  margin: 38px auto 0;
`;

const Card = styled.View`
  width: 192px;
  height: 280px;
  position: absolute;
  top: 84px;
  left: 50%;
  margin-left: -96px;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dusk : p.theme.myColors.white)};
  padding: 14.5px 13px 97.7px 12px;
  border-radius: 14px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
`;
const CardBg1 = styled.View`
  width: 184px;
  height: 265px;
  position: absolute;
  top: 90px;
  left: 50%;
  margin-left: -85px;
  border-radius: 14px;
  z-index: 2;
  transform: rotate(5deg);
  background-color: ${(p) => (p.theme?.isDark ? '#3f3f68' : '#DACAF9')};
`;
const CardBg2 = styled.View`
  width: 177px;
  height: 250px;
  position: absolute;
  top: 100px;
  left: 50%;
  margin-left: -70px;
  border-radius: 14px;
  z-index: 1;
  transform: rotate(7.5deg);
  background-color: ${(p) => (p.theme?.isDark ? '#35355d' : '#C2B1E5')};
`;

const CardImage = styled.Image`
  border-radius: 14px;
  width: 167px;
  height: 167px;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark_grey_blue : p.theme.myColors.pale_grey2)};
`;
const CardTitle = styled.Text`
  font-family: Gilroy-Bold;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dusk)};
  font-size: 14px;
  margin-top: 20px;
`;
const CardSubtitle = styled.Text`
  font-family: Gilroy-Medium;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.bluey_grey : p.theme.myColors.bluey_grey)};
  font-size: 12px;
  font-weight: 300;
  /* margin-top: 2px; */
  margin-top: 2px;
`;
const EmptyCardSubtitle = styled.Text`
  font-family: Gilroy-Medium;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.bluey_grey : p.theme.myColors.bluey_grey)};
  font-size: 12px;
  font-weight: 300;
  /* margin-top: 2px; */
  margin-top: 36px;
  /* margin-bottom: 8px; */
  text-align: center;
`;
const CardLocationBox = styled.View`
  background-color: ${(p) => p.theme.myColors.bright_sky_blue};
  border-radius: 4px;
  padding: 1px 8px 2.1px 3px;
  width: auto;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const LocationIcon = styled(IconLocation_)`
  /* margin: 3px 1px 0 0; */
`;
const LocationText = styled.Text`
  font-family: Gilroy-Medium;
  color: ${(p) => p.theme.myColors.white};
  font-size: 12px;
  font-weight: 600;
`;
