import React, { useState, useEffect } from 'react';
import { Platform, RefreshControl } from 'react-native';
import styled from 'styled-components/native';

import Header from '../../components/pages/discover/Header';
import PeopleLikedYou from '../../components/pages/discover/PeopleLikedYou';
import YourLikes from '../../components/pages/discover/YourLikes';
import YourLikesWeb from '../../components/pages/discover/YourLikesWeb';
import YoutMatches from '../../components/pages/discover/YoutMatches';

export default function DiscoverHomeScreen({ navigation }) {
  const [refreshing, setRefreshing] = React.useState(false);
  const [doRefresh, setdoRefresh] = useState(1);

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    setdoRefresh((prev) => prev + 1);
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  }, []);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // do something
      setdoRefresh((prev) => prev + 1);
    });

    return unsubscribe;
  }, [navigation]);

  return (
    <ScrollView refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}>
      <Header navigation={navigation} doRefresh={doRefresh} />
      <Container>
        {Platform.OS === 'web' ? (
          <YourLikesWeb navigation={navigation} doRefresh={doRefresh} />
        ) : (
          <YourLikes navigation={navigation} doRefresh={doRefresh} />
        )}
        <PeopleLikedYou navigation={navigation} doRefresh={doRefresh} />
        <YoutMatches navigation={navigation} doRefresh={doRefresh} />
      </Container>
    </ScrollView>
  );
}

const ScrollView = styled.ScrollView`
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
`;

const Container = styled.View`
  margin-top: 36px;
  padding: 0 25px;
`;
