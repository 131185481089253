import React from 'react';
import { Platform } from 'react-native';
import styled from 'styled-components/native';

import IconLocation_ from '../svg/IconLocation';
import { useTheme } from '../template/ThemeProvider';

export default function ProfileCardWithTitle({ img, name, age, location = null }) {
  const { isDark, colors } = useTheme();

  return (
    <Container>
      <ImageWrapper>
        <Image source={img} />
        {location && (
          <CardLocationBox>
            <LocationIcon color="#fff" />
            <LocationText>{location}</LocationText>
          </CardLocationBox>
        )}
      </ImageWrapper>
      <Title>{name}</Title>
      <SubTitle>{age}</SubTitle>
    </Container>
  );
}

const Container = styled.View`
  padding: 5px;
  width: 100%;
`;
const ImageWrapper = styled.View`
  position: relative;
  border-radius: 12px;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark_grey_blue : p.theme.myColors.pale_grey2)};
`;

const Image = styled.Image`
  width: 100%;
  height: ${Platform.OS === 'web' ? '120px' : 'auto'};
  aspect-ratio: 1;
  border-radius: 12px;
  margin: 0 auto;
`;

const Title = styled.Text`
  font-family: Gilroy-Medium;
  font-size: 13px;
  text-align: center;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dark2)};
  margin-top: 6px;
`;
const SubTitle = styled.Text`
  font-family: Gilroy-Light;
  font-size: 8px;
  text-align: center;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.light_blue_grey : p.theme.myColors.slate_blue)};
  margin-top: 1px;
`;

const CardLocationBox = styled.View`
  background-color: #221f3b9c;
  /* opacity: 0.7; */
  border-radius: 4px;
  padding: 1px 8px 2.1px 3px;
  width: auto;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  right: 8px;
  bottom: 6px;
`;
const LocationIcon = styled(IconLocation_)`
  /* margin: 3px 1px 0 0; */
  width: 10px;
  height: 10px;
`;
const LocationText = styled.Text`
  font-family: Gilroy-Medium;
  color: ${(p) => p.theme.myColors.white};
  font-size: 8px;
  font-weight: 600;
`;
