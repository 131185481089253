import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path fillRule="evenodd" clipRule="evenodd" d="M.25 10A.75.75 0 011 9.25h12a.75.75 0 010 1.5H1A.75.75 0 01.25 10z" fill="#9C9CC2" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.999.25a9.734 9.734 0 11-7.212 16.252.75.75 0 111.114-1.004 8.235 8.235 0 106.1-13.748 8.165 8.165 0 00-6.096 2.748.75.75 0 11-1.122-.996A9.665 9.665 0 019.999.25"
        fill="#9C9CC2"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.53 6.47a.75.75 0 010 1.06L2.06 10l2.47 2.47a.75.75 0 11-1.06 1.06l-3-3a.75.75 0 010-1.06l3-3a.75.75 0 011.06 0z"
        fill="#9C9CC2"
      />
    </Svg>
  );
}

export default SvgComponent;
