import * as React from 'react';
import Svg, { Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const SvgComponent = (props) => (
  <Svg viewBox="0 0 262 211" width={65} height={65} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      d="M133.153 160.026h-87.58a44.863 44.863 0 0 1-31.675-13.102A44.813 44.813 0 0 1 .732 115.292V45.31a44.814 44.814 0 0 1 13.169-31.635A44.87 44.87 0 0 1 45.58.575h87.58a45.141 45.141 0 0 1 31.726 13.121 44.365 44.365 0 0 1 13.122 31.61v69.986a44.818 44.818 0 0 1-13.172 31.637 44.875 44.875 0 0 1-31.684 13.097ZM36.494 47.042a6.501 6.501 0 0 0-4.738 1.99 6.764 6.764 0 0 0-.63 8.857l1.165 1.148L72.619 90.49a27.744 27.744 0 0 0 17.287 6.018 28.194 28.194 0 0 0 17.359-6.018l39.994-31.977.711-.71a6.856 6.856 0 0 0-.104-8.857 7.375 7.375 0 0 0-4.682-2.305h-.375a6.739 6.739 0 0 0-4.603 1.78l-39.972 31.88a13.876 13.876 0 0 1-8.833 3.194 14.12 14.12 0 0 1-8.893-3.194L40.622 48.41a6.897 6.897 0 0 0-4.128-1.37Z"
      fill="#6C6CFF"
    />
    <Path
      d="m222.777 188.519-76.528 14.866a39.694 39.694 0 0 1-29.753-5.824 39.648 39.648 0 0 1-16.948-25.124l-11.739-60.35a39.628 39.628 0 0 1 6.303-29.633 39.674 39.674 0 0 1 25.4-16.535l76.528-14.866a40.489 40.489 0 0 1 29.938 5.924 38.73 38.73 0 0 1 16.787 25.008l11.738 60.351a39.63 39.63 0 0 1-6.304 29.65 39.677 39.677 0 0 1-25.422 16.533Zm-103.446-80.993a5.821 5.821 0 0 0-3.808 2.519 5.882 5.882 0 0 0 .935 7.744l1.21.793 40.545 20.268a24.929 24.929 0 0 0 16.12 2.252 25.322 25.322 0 0 0 14.164-8.135l29.6-34.378.501-.733a5.953 5.953 0 0 0-1.588-7.598 6.598 6.598 0 0 0-4.479-1.192l-.327.064a6.042 6.042 0 0 0-3.743 2.32l-29.546 34.26a12.458 12.458 0 0 1-7.18 4.252 12.675 12.675 0 0 1-8.309-1.243l-40.228-20.718a6.2 6.2 0 0 0-3.837-.477l-.03.002Z"
      fill="url(#a)"
    />
    <Defs>
      <LinearGradient id="a" x1={143.41} y1={102.791} x2={213.822} y2={208.581} gradientUnits="userSpaceOnUse">
        <Stop stopColor="#9A9AFF" />
        <Stop offset={1} stopColor="#fff" />
      </LinearGradient>
    </Defs>
  </Svg>
);

export default SvgComponent;
