import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  visible: false,
  renderContent: null,
  height: null,
};

export const slice = createSlice({
  name: 'actionSheet',
  initialState,
  reducers: {
    open: (state, action) => {
      state.visible = true;
      state.renderContent = action.payload?.content;
      state.height = action.payload?.height || null;
    },
    close: (state) => {
      state.visible = false;
      state.renderContent = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { open, close } = slice.actions;

export default slice.reducer;
