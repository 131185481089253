import i18n from 'i18n-js';
import { Input, useToast, FormControl, WarningOutlineIcon } from 'native-base';
import React, { useState, useEffect } from 'react';
import { Alert, BackHandler, TouchableOpacity, Platform } from 'react-native';
import styled from 'styled-components/native';

import authApi from '../../apis/auth';
import HeaderWithIcons from '../../components/common/HeaderWithIcons';
import IconLock from '../../components/svg/IconLock';
// import IconLock from '../../components/svg/IconLock';
import { useTheme } from '../../components/template/ThemeProvider';
import MYButton from '../../components/ui/MYButton';
import Myinput from '../../components/ui/MYInput';
import handleErrorResponse from '../../services/handleErrorResponse';
import { saveToSecureStore } from '../../services/secureStoreService';

export default function SetPasswordScreen({ route, navigation }) {
  const { colors, isDark, setScheme } = useTheme();
  const toast = useToast();
  const [passwordValue, setPasswordValue] = useState(null);
  const [PasswordValueRepeat, setPasswordValueRepeat] = useState(null);
  const [loadingApi, setloadingApi] = useState(false);
  const { email } = route.params;

  function alert(title = undefined, msg) {
    if (Platform.OS === 'web') {
      toast.show({ title: title, status: "info", description: msg })
    } else {
      Alert.alert(title, msg, [{ text: 'ok', onPress: () => null, style: 'cancel' }]
      );
    }
  }

  useEffect(() => {
    const backAction = () => {
      navigation.goBack();
      return true;
    };

    const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

    return () => backHandler.remove();
  }, []);

  function handleSubmitSignUp() {
    setloadingApi(true);
    authApi
      .register({
        email: email?.replace(' ', '').toLowerCase(),
        password: passwordValue,
      })
      .then(async (res) => {
        if (res?.data?.access_token) {
          navigation.navigate('Auth_SelectGender', {
            email,
            access_token: `${res?.data?.access_token}`,
          });
        } else {
          // console.log(res);
          alert('Error', 'Problem in registering data');
        }

        setloadingApi(false);
      })
      .catch((error) => {
        setloadingApi(false);

        if (error.response.data?.message === 'email_not_verified') {
          navigation.navigate('Auth_Verification', {
            email,
            password: passwordValue,
          });

          return;
        }

        alert('Error', error.response ? handleErrorResponse(error.response.data?.message) : 'Error in saving data');
      });
  }

  function handlePressNext() {
    if (!passwordValue || !PasswordValueRepeat) {
      // toast
      toast.show({
        description: !passwordValue ? 'please enter your password' : 'please enter your password repeat',
      });
      return;
    }

    if (passwordValue !== PasswordValueRepeat) {
      // toast
      toast.show({
        description: i18n.t('change_password.error_passes_not_same'),
      });
      return;
    }

    if (passwordValue.length < 8) {
      // toast
      toast.show({
        description: 'Passwords must be at minimum 8 character!',
      });
      return;
    }

    handleSubmitSignUp();
  }

  return (
    <Container>
      <InnerContainer>
        <HeaderWithIcons title={i18n.t('auth.setpassword_header_title')} onPress={() => navigation.navigate('Auth_Signup')} />
        <TopContainer>
          <FormControl isInvalid>
            <InputsBox>
              <Myinput
                type="password"
                variant="unstyled"
                InputLeftElement={<IconLock color={isDark ? colors.bluey_grey : colors.dark} />}
                placeholder={i18n.t('auth.placeholder_password')} // mx={4}
                value={passwordValue || ''}
                onChangeText={(txt) => setPasswordValue(txt)}
              // error="required"
              />
            </InputsBox>
            <InputsBox2>
              <Myinput
                type="password"
                variant="unstyled"
                InputLeftElement={<IconLock color={isDark ? colors.bluey_grey : colors.dark} />}
                placeholder={i18n.t('auth.placeholder_repeat_password')} // mx={4}
                value={PasswordValueRepeat || ''}
                onChangeText={(txt) => setPasswordValueRepeat(txt)}
              // error="required"
              />
            </InputsBox2>
            {/* <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>* Email is required</FormControl.ErrorMessage> */}
          </FormControl>
        </TopContainer>
      </InnerContainer>

      <ButtonsContainer>
        <MYButton color="red" style={{ width: '100%' }} onPress={handlePressNext} loading={loadingApi}>
          {i18n.t('auth.continue')}
        </MYButton>
      </ButtonsContainer>
    </Container>
  );
}

const Container = styled.KeyboardAvoidingView`
  display: flex;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
  flex: 1;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
`;
const InnerContainer = styled.ScrollView`
  width: 100%;
`;
const ButtonsContainer = styled.View`
  padding: 0 34px;
  width: 100%;
  margin-bottom: 28px;
  flex-shrink: 0;
`;
const TopContainer = styled.View`
  flex-shrink: 0;
  margin-bottom: auto;
  margin-top: 27px;
  width: 100%;
  padding: 0 35px;
`;
const InputsBox = styled.View`
  border-radius: 28px;
  border: solid 1px;
  border-color: ${(p) => (p.theme.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey)};
  padding: 15px 20px;
`;
const InputsBox2 = styled.View`
  border-radius: 28px;
  border: solid 1px;
  border-color: ${(p) => (p.theme.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey)};
  padding: 15px 20px;
  margin-top: 10px;
`;
const InputsContainerSeperatorH = styled.View`
  height: 1px;
  background: ${(p) => (p.theme.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey)};
`;
const SignUpBtn = styled.View`
  margin: 0 auto 29px;
  display: flex;
  flex-direction: row;
`;
const DontHaveAccount = styled.Text`
  text-align: center;
  color: ${(p) => (p.theme.isDark ? p.theme.myColors.bluey_grey : p.theme.myColors.dark2)};
  font-family: Gilroy-Medium;
  font-weight: 500;
`;
const SignUpLink = styled.Text`
  color: ${(p) => p.theme.myColors.coral};
  font-family: Gilroy-Medium;
`;
const TermsText = styled.Text`
  color: ${(p) => (p.theme.isDark ? p.theme.myColors.pale_grey2 : p.theme.myColors.bluey_grey)};
  font-family: Gilroy-Medium;
  font-size: 10px;
  margin-top: 34px;
  line-height: 18px;
  margin-left: 15px;
`;
const TermsLink = styled.Text`
  color: ${(p) => p.theme.myColors.bright_sky_blue};
  font-family: Gilroy-Medium;
  font-size: 10px;
  margin-top: 34px;
  line-height: 18px;
`;
