import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" color="#000" width={14} height={14} viewBox="0 0 488.878 488.878" {...props}>
    <Path fill="currentColor" d="m143.294 340.058-92.457-92.456L0 298.439l122.009 122.008.14-.141 22.274 22.274L488.878 98.123l-51.823-51.825z" />
  </Svg>
);

export default SvgComponent;
