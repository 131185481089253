import * as React from 'react';
import Svg, { Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const SvgComponent = (props) => (
  <Svg viewBox="0 0 249 210" width={65} height={65} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      d="M139.268 160.023h-99.93a38.64 38.64 0 0 1-38.62-38.578V68.883a38.64 38.64 0 0 1 38.62-38.58 1.754 1.754 0 0 0 1.691-1.06l.528-1.058.748-1.552c2.315-4.862 4.93-10.386 6.527-13.58A21.551 21.551 0 0 1 68.398.574h41.721a21.619 21.619 0 0 1 19.667 12.477c1.391 2.787 3.508 7.26 5.558 11.595l1.257 2.66.894 1.944a1.989 1.989 0 0 0 1.769 1.059 38.642 38.642 0 0 1 38.624 38.573v52.562a38.64 38.64 0 0 1-38.62 38.578ZM89.303 57.289a34.74 34.74 0 0 0-24.717 10.266 34.258 34.258 0 0 0-10.099 24.507 34.754 34.754 0 0 0 21.49 32.152 34.75 34.75 0 0 0 13.326 2.632 34.508 34.508 0 0 0 24.541-10.173 34.694 34.694 0 0 0 7.638-37.905 34.674 34.674 0 0 0-7.552-11.291 34.591 34.591 0 0 0-24.627-10.189Zm49.695-2.106a8.009 8.009 0 1 0 8.068 8.053 8.026 8.026 0 0 0-4.977-7.453 8.022 8.022 0 0 0-3.091-.6Zm-49.695 58.385a21.458 21.458 0 0 1-21.526-21.502v-.087a20.697 20.697 0 0 1 6.202-15.043 21.524 21.524 0 0 1 36.75 17.157 21.516 21.516 0 0 1-11.255 16.921 21.517 21.517 0 0 1-10.16 2.554h-.01Z"
      fill="#20CAFF"
    />
    <Path
      d="m215.875 173.776-85.448 26.098a33.892 33.892 0 0 1-42.703-21.606l-13.188-43.176a33.893 33.893 0 0 1 23.343-41.774 1.571 1.571 0 0 0 1.164-1.31l.184-1.01.24-1.465c.755-4.609 1.611-9.819 2.175-12.86a19.337 19.337 0 0 1 13.626-15.367l35.676-10.896a19.41 19.41 0 0 1 19.937 5.115c1.89 1.925 4.822 5.048 7.66 8.067l1.74 1.857 1.253 1.365a1.8 1.8 0 0 0 1.78.41 33.894 33.894 0 0 1 42.703 21.605l13.187 43.173a33.896 33.896 0 0 1-23.329 41.774Zm-68.5-71.338a31.713 31.713 0 0 0-18.556 14.89 28.857 28.857 0 0 0-3.603 11.127 28.867 28.867 0 0 0 1.112 11.643 29.368 29.368 0 0 0 14.888 17.548 31.916 31.916 0 0 0 23.611 1.925 31.512 31.512 0 0 0 18.43-14.766 28.958 28.958 0 0 0 2.617-22.869 28.965 28.965 0 0 0-14.884-17.559 31.58 31.58 0 0 0-23.616-1.942l.001.003Zm41.96-14.72a6.884 6.884 0 0 0-4.536 6.065 6.887 6.887 0 1 0 13.471-1.565 7.023 7.023 0 0 0-3.527-4.118 7.032 7.032 0 0 0-5.409-.385l.001.004Zm-27.84 60.947a19.534 19.534 0 0 1-14.603-1.181 17.962 17.962 0 0 1-5.715-4.491 17.968 17.968 0 0 1-3.471-6.386l-.016-.054a17.428 17.428 0 0 1 1.528-13.977 19.893 19.893 0 0 1 26.11-8.053 18.004 18.004 0 0 1 9.187 10.877 18.99 18.99 0 0 1-13.02 23.265Z"
      fill="url(#a)"
    />
    <Defs>
      <LinearGradient id="a" x1={218.651} y1={190.936} x2={134.134} y2={47.358} gradientUnits="userSpaceOnUse">
        <Stop stopColor="#fff" />
        <Stop offset={1} stopColor="#67DAFF" />
      </LinearGradient>
    </Defs>
  </Svg>
);

export default SvgComponent;
