import * as ExpoLocation from 'expo-location';
import i18n from 'i18n-js';
import { Checkbox, useToast } from 'native-base';
import React, { useState, useEffect } from 'react';
import { Alert, Platform } from 'react-native';
import CountryPicker, { DEFAULT_THEME, DARK_THEME } from 'react-native-country-picker-modal';
import RangeSlider, { Slider } from 'react-native-range-slider-expo';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/native';

import usersApi from '../../../apis/users';
import { close } from '../../../redux/slices/ActionSheetSlice';
import { useTheme } from '../../template/ThemeProvider';
import MYButton from '../../ui/MYButton';

const minAge = 18;
const maxAge = 70;
export default function ActionSheetFilters({ onFilter, setRawfilters, rawfilters }) {
  const { colors, isDark } = useTheme();
  const dispatch = useDispatch();

  const [fromValue, setFromValue] = useState(rawfilters?.fromValue && rawfilters?.fromValue >= minAge ? rawfilters?.fromValue : minAge);
  const [toValue, setToValue] = useState(rawfilters?.toValue && rawfilters?.toValue <= maxAge ? rawfilters?.toValue : maxAge);

  const [isDistanceEnabled, setisDistanceEnabled] = useState(rawfilters?.isDistanceEnabled || false);
  const [distanceValue, setDistanceValue] = useState(rawfilters?.isDistanceEnabled ? rawfilters?.distanceValue : 0);

  const [isCountryEnabled, setisCountryEnabled] = useState(rawfilters?.isCountryEnabled || false);
  const [countryCode, setCountryCode] = useState(rawfilters?.countryCode || 'FR');
  // const [country, setCountry] = useState(null);
  const [location, setLocation] = useState(null);

  const toast = useToast();

  function alert(title = undefined, msg) {
    if (Platform.OS === 'web') {
      toast.show({ title: title, status: 'info', description: msg });
    } else {
      Alert.alert(title, msg, [{ text: 'ok', onPress: () => null, style: 'cancel' }]);
    }
  }

  useEffect(() => {
    if (isDistanceEnabled) {
      (async () => {
        const { status } = await ExpoLocation.requestForegroundPermissionsAsync();
        if (status !== 'granted') {
          alert(null, 'Permission to access location was denied');
          setisDistanceEnabled(false);
          return;
        }

        const location = await ExpoLocation.getCurrentPositionAsync({});
        setLocation(location);
      })();
    }
  }, [isDistanceEnabled]);

  useEffect(() => {
    if (location?.coords?.latitude && location?.coords?.longitude) {
      usersApi
        .updateProfile({
          'loc.type': 'Point',
          'loc.coordinates': [`${location?.coords?.longitude}`, `${location?.coords?.latitude}`],
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err?.response);
        });
    }
  }, [location]);

  function handleSubmit() {
    const filterQuery = `age_min=${fromValue}&age_max=${toValue}${isDistanceEnabled && distanceValue ? `&distance=${distanceValue * 1000}` : ''}${
      isCountryEnabled && countryCode ? `&country_code=${countryCode}` : ''
    }`;
    onFilter && onFilter(filterQuery);
    setRawfilters({ fromValue, toValue, isDistanceEnabled, distanceValue, isCountryEnabled, countryCode });
    dispatch(close());
  }

  function SetDistanceEnabled(v) {
    setisDistanceEnabled(v);

    if (v && isCountryEnabled) {
      setisCountryEnabled(false);
    }
  }

  function SetCountryEnabled(v) {
    setisCountryEnabled(v);

    if (v && isDistanceEnabled) {
      setisDistanceEnabled(false);
    }
  }

  // console.log('location', location);
  const onSelectCountry = (country) => {
    setCountryCode(country.cca2);
    // setCountry(country);
    SetCountryEnabled(true);
  };

  function onChangeDistanceSlider(distanceValue) {
    setDistanceValue(distanceValue);
    SetDistanceEnabled(true);
  }

  return (
    <Container>
      <Title>{i18n.t('search.filters_header')}</Title>
      <AgeHeaderContainer>
        <AgeTitle>{i18n.t('search.filters_age')}</AgeTitle>
        <AgeValue>
          {fromValue} - {toValue}
          {/* 18 - 70 */}
        </AgeValue>
      </AgeHeaderContainer>
      <RangeSlider
        min={18}
        max={70}
        fromValueOnChange={(value) => setFromValue(value)}
        toValueOnChange={(value) => setToValue(value)}
        initialFromValue={fromValue}
        initialToValue={toValue}
        showRangeLabels={false}
        // showValueLabels={false}
        // style={{ zIndex: 999 }}
        fromKnobColor={colors.cornflower}
        toKnobColor={colors.cornflower}
        inRangeBarColor={colors.cornflower}
        outOfRangeBarColor={isDark ? colors.dusk2 : colors.light_blue_grey}
      />

      <AgeHeaderContainer>
        <Checkbox isChecked={isDistanceEnabled} onChange={(v) => SetDistanceEnabled(v)}>
          {i18n.t('search.filters_distance')}
        </Checkbox>
        <AgeValue>{distanceValue}KM</AgeValue>
        {/* <AgeValue>1000KM</AgeValue> */}
      </AgeHeaderContainer>
      <Slider
        min={1}
        max={1000}
        valueOnChange={(value) => onChangeDistanceSlider(value)}
        // initialFromValue={11}
        initialValue={distanceValue}
        showRangeLabels={false}
        // showValueLabels={false}
        // style={{ zIndex: 999 }}
        knobColor={colors.cornflower}
        outOfRangeBarColor={colors.cornflower}
        inRangeBarColor={isDark ? colors.dusk2 : colors.light_blue_grey}
      />

      <CountryHeaderContainer>
        <CountryTitle>
          <Checkbox isChecked={isCountryEnabled} onChange={(v) => SetCountryEnabled(v)}>
            {i18n.t('search.filters_country')}
          </Checkbox>
        </CountryTitle>
      </CountryHeaderContainer>

      <CountryPickerWraper>
        <CountryPicker
          theme={isDark ? DARK_THEME : DEFAULT_THEME}
          countryCode={countryCode}
          withFilter
          withFlag
          withCountryNameButton
          withAlphaFilter
          // withCallingCode
          withEmoji
          onSelect={onSelectCountry}
          // visible
        />
      </CountryPickerWraper>

      <MYButton color="blue" style={{ width: '100%', marginTop: 30, marginBottom: 30 }} onPress={handleSubmit}>
        {i18n.t('search.filters_search_btn')}
      </MYButton>
    </Container>
  );
}

const Container = styled.View`
  padding: 15px;
  height: 100%;
`;
const Title = styled.Text`
  font-family: Gilroy-Bold;
  text-align: center;
  font-size: 16px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dusk)};
`;
const AgeHeaderContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const AgeTitle = styled.Text`
  font-size: 14px;
  font-family: Gilroy-SemiBold;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.light_blue_grey : p.theme.myColors.bluey_grey)};
`;
const AgeValue = styled.Text`
  font-family: Gilroy-Bold;
  font-size: 14px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dark)};
`;
const CountryHeaderContainer = styled.View``;
const CountryTitle = styled.View`
  margin: 0 auto 20px 0;
`;
const CountryPickerWraper = styled.View`
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dusk : p.theme.myColors.pale_grey)};
  padding: 18px 11px;
  border-radius: 12px;
`;
