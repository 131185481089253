import { Modal } from 'native-base';
import React, { useEffect, useRef, useState } from 'react';
import { BackHandler } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import { close } from '../../redux/slices/ModalSlice';
import { useTheme } from './ThemeProvider';

export default function ModalProvider() {
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state.modal);
  const [showModal, setShowModal] = useState(false);
  const { isDark = false } = useTheme();

  useEffect(() => {
    let backHandler;

    const backAction = () => {
      dispatch(close());
      return true;
    };

    if (reduxState?.visible) {
      setShowModal(true);
      backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);
    } else {
      setShowModal(false);
      if (backHandler) backHandler.remove();
    }
    return () => {
      if (backHandler) backHandler.remove();
    };
  }, [reduxState?.visible]);

  if (!reduxState?.visible) {
    return null;
  }
  return (
    <Modal
      isOpen={showModal}
      onClose={() => (reduxState?.onClose && typeof reduxState?.onClose === 'function' ? reduxState?.onClose() : dispatch(close()))}
      closeOnOverlayClick={false}
      size={reduxState?.size || 'md'}>
      <Modal.Content style={{ backgroundColor: isDark ? '#332f53' : '#ffffff' }}>
        {reduxState?.renderContent && typeof reduxState?.renderContent === 'function' && reduxState?.renderContent()}
      </Modal.Content>
    </Modal>
  );
}
