import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={6} height={10} viewBox="0 0 6 10" color="#000" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.47.47a.75.75 0 011.06 0l4 4a.75.75 0 010 1.06l-4 4A.75.75 0 01.47 8.47L3.94 5 .47 1.53a.75.75 0 010-1.06z"
        fill="currentColor"
      />
    </Svg>
  );
}

export default SvgComponent;
