import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" color="#6C6CFF" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 8.25A1.25 1.25 0 003.75 9.5v9A1.25 1.25 0 005 19.75h14a1.25 1.25 0 001.25-1.25v-9A1.25 1.25 0 0019 8.25H5zm-1.945-.695A2.75 2.75 0 015 6.75h14a2.75 2.75 0 012.75 2.75v9A2.75 2.75 0 0119 21.25H5a2.75 2.75 0 01-2.75-2.75v-9c0-.73.29-1.429.805-1.945z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.881 3.555a2.75 2.75 0 011.945-.805h4.348a2.75 2.75 0 012.75 2.75v2a.75.75 0 01-1.5 0v-2a1.25 1.25 0 00-1.25-1.25H9.826a1.25 1.25 0 00-1.25 1.25v2a.75.75 0 01-1.5 0v-2c0-.73.29-1.429.805-1.945zM21.626 9.087a.75.75 0 01-.213 1.04l-6.355 4.19a2.75 2.75 0 01-1.512.455H10.45a2.752 2.752 0 01-1.513-.455l-6.351-4.191a.75.75 0 01.826-1.252l6.351 4.191c.204.135.443.207.687.207h3.094c.244 0 .483-.072.687-.207l6.355-4.191a.75.75 0 011.04.213z"
        fill="currentColor"
      />
    </Svg>
  );
}

export default SvgComponent;
