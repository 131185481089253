import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import usersApi from '../../apis/users';
import { deleteValueFromSecureStore, saveToSecureStore } from '../../services/secureStoreService';
import { clearData } from './MessagesSlice';
import { resetViewedBanners } from './StartupSlice';

const initialState = {
  isSignedIn: false,
  access_token: null,
  userInfo: null,
  userSettings: {
    language: 'en',
    darkmode: 'inherit',
  },
};

export const fetchUserProfile = createAsyncThunk('user/fetchProfile', () => {
  return usersApi
    .fetchMyProfile()
    .then((res) => {
      if (res.data) {
        // console.log('resres', res.data);
        return res.data;
      }
      // console.log('resres', res.data);
    })
    .catch(() => {
      // console.log(err);
      throw new Error(res.data?.message || 'error');
    });
});

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action) => {
      state.isSignedIn = true;
      state.access_token = action.payload?.access_token;
      state.userInfo = action.payload?.userInfo;
    },
    logout: (state) => {
      state.isSignedIn = false;
      state.access_token = null;
      state.userInfo = null;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload?.userInfo;
    },
    setUserSettings: (state, action) => {
      state.userSettings = { ...state.userSettings, ...action.payload };
    },
  },
  extraReducers: {
    [fetchUserProfile.pending]: (state) => {
      // state.loading = true;
      // state.error = null;
    },
    [fetchUserProfile.fulfilled]: (state, action) => {
      // console.log('action', action);
      state.userInfo = action.payload;
    },
    [fetchUserProfile.rejected]: (state, action) => {
      // state.loading = false;
      // state.data = null;
      // state.error = action.error?.message || 'خطایی رخ داده است';
    },
  },
});

// Action creators are generated for each case reducer function
export const { login, logout, setUserInfo, setUserSettings } = slice.actions;

export const doLogin = (payload) => async (dispatch) => {
  await saveToSecureStore('access_token', `${payload?.access_token}`);
  dispatch(login({ access_token: `${payload?.access_token}`, userInfo: payload?.userInfo }));
  dispatch(clearData());
  dispatch(resetViewedBanners());
};

export const doLogOut = (payload) => async (dispatch) => {
  await deleteValueFromSecureStore('access_token');
  dispatch(logout());
  dispatch(clearData());
  dispatch(resetViewedBanners());
};

export default slice.reducer;
