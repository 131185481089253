import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.187 2.187a.75.75 0 011.06 0l2.455 2.455a3.58 3.58 0 011.048 2.53M4.187 2.187a.75.75 0 000 1.06zm0 1.06l2.455 2.455zm2.455 2.455a2.08 2.08 0 01-1.47 3.548H1a.75.75 0 100 1.5h4.172A3.578 3.578 0 008.75 7.172m-2.108-1.47zM9.25 1A.75.75 0 0110 .25a9.75 9.75 0 110 19.5.75.75 0 010-1.5 8.25 8.25 0 100-16.5.75.75 0 01-.75-.75z"
        fill="#49497A"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1.75a8.25 8.25 0 100 16.5.75.75 0 010 1.5 9.75 9.75 0 110-19.5.75.75 0 010 1.5z"
        fill="#49497A"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.072 7.75a.983.983 0 00-.88.543l-1.133 2.266a.985.985 0 000 .88l.133.267a.984.984 0 00.88.544 2.484 2.484 0 012.067 1.107l1.777 2.665a.75.75 0 01-1.248.832l-1.777-2.665a.986.986 0 00-.82-.439m0 0a2.485 2.485 0 01-2.22-1.374l-.134-.266a2.485 2.485 0 010-2.221l1.133-2.266a2.483 2.483 0 012.222-1.373h4.408a.75.75 0 010 1.5h-4.408"
        fill="#6C6CFF"
      />
    </Svg>
  );
}

export default SvgComponent;
