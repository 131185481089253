import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = (props) => (
  <Svg data-name="Iconly/Bold/Volume Off" xmlns="http://www.w3.org/2000/svg" width={18} height={18} {...props}>
    <Path
      data-name="Volume Off"
      d="M.205 14.049a.7.7 0 0 1 0-.986l2.12-2.119H2.3A1.978 1.978 0 0 1 .332 9.039a20.542 20.542 0 0 1 0-4.2A2.012 2.012 0 0 1 2.3 2.99h1.374L6.295.847A2.388 2.388 0 0 1 7.612.315 1.9 1.9 0 0 1 9.4 1.759a6.129 6.129 0 0 1 .175 1.1l.063.508c.01.078.02.152.028.232l3.4-3.4a.694.694 0 0 1 .981.983L1.185 14.047a.71.71 0 0 1-.491.2.723.723 0 0 1-.489-.198zm7.343-.429a2.059 2.059 0 0 1-1.2-.5l-.976-.755a.654.654 0 0 1-.053-.971c.208-.254 3.486-3.241 3.594-3.347a.632.632 0 0 1 .453-.147c.292.074.348.492.344.821-.014.954-.045 1.618-.1 2.028l-.036.338a6.451 6.451 0 0 1-.171 1.074 1.909 1.909 0 0 1-1.779 1.464z"
      style={{
        fill: '#c2c2e7',
      }}
      transform="translate(2.25 2.25)"
    />
  </Svg>
);

export default SvgComponent;
