import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import i18n from 'i18n-js';
// import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';

import IconDiscover from '../components/svg/IconDiscover';
import IconDiscoverFill from '../components/svg/IconDiscoverFill';
import IconMessages from '../components/svg/IconMessages';
import IconMessagesFill from '../components/svg/IconMessagesFill';
import IconProfile from '../components/svg/IconProfile';
import IconProfileFill from '../components/svg/IconProfileFill';
import IconSearch from '../components/svg/IconSearch';
import IconSearchFill from '../components/svg/IconSearchFill';
import { useTheme } from '../components/template/ThemeProvider';
import DiscoverHomeScreen from '../screens/discover/DiscoverHomeScreen';
import MessagesHomeScreen from '../screens/messages/MessagesHomeScreen';
import MyProfileHomeScreen from '../screens/myprofile/MyProfileHomeScreen';
import SearchHomeScreen from '../screens/search/SearchHomeScreen';

// use this if you want have multi screen in one tab
// const DiscoverStack = createNativeStackNavigator();

// function TabDiscoverNavigator() {
//   return (
//     <DiscoverStack.Navigator screenOptions={{ headerShown: false }}>
//       <DiscoverStack.Screen name="DiscoverHome" component={DiscoverHomeScreen} />
//     </DiscoverStack.Navigator>
//   );
// }

const BottomTab = createBottomTabNavigator();

export default function BottomTabNavigator() {
  const insets = useSafeAreaInsets();
  const { colors, isDark } = useTheme();
  const messagesReduxState = useSelector((state) => state.messages);
  const userReduxState = useSelector((state) => state.user);
  const [unreadCount, setUnreadCount] = React.useState(0);

  React.useEffect(() => {
    if (messagesReduxState?.data?.length) {
      const filtered = messagesReduxState?.data?.filter((a) => a?.unreadCounts?.find((a) => a._id === userReduxState?.userInfo?._id)?.count > 0);

      let sum = 0;
      for (let index = 0; index < filtered.length; index++) {
        const element = filtered[index];

        sum = sum + element?.unreadCounts?.find((a) => a._id === userReduxState?.userInfo?._id)?.count;
      }
      // console.log('filtered', filtered);
      setUnreadCount(sum);
    }
  }, [messagesReduxState]);

  return (
    <BottomTab.Navigator
      initialRouteName="Search"
      screenOptions={({ route }) => ({
        headerShown: false,
        tabBarIcon: ({ focused, color, size }) => {
          function handleIcon() {
            switch (route.name) {
              case 'Discover':
                return focused ? <IconDiscoverFill /> : <IconDiscover color={isDark ? colors.slate_blue : colors.light_blue_grey} />;
              case 'Search':
                return focused ? <IconSearchFill /> : <IconSearch color={isDark ? colors.slate_blue : colors.light_blue_grey} />;
              case 'Messages':
                return focused ? (
                  <Badge>
                    <IconMessagesFill />
                    {unreadCount > 0 && (
                      <BadgeTxtWraper>
                        <BadgeTxt>{unreadCount >= 99 ? '+99' : unreadCount}</BadgeTxt>
                      </BadgeTxtWraper>
                    )}
                  </Badge>
                ) : (
                  <Badge>
                    <IconMessages color={isDark ? colors.slate_blue : colors.light_blue_grey} />
                    {unreadCount > 0 && (
                      <BadgeTxtWraper>
                        <BadgeTxt>{unreadCount >= 99 ? '+99' : unreadCount}</BadgeTxt>
                      </BadgeTxtWraper>
                    )}
                  </Badge>
                );
              case 'Profile':
                return focused ? <IconProfileFill /> : <IconProfile color={isDark ? colors.slate_blue : colors.light_blue_grey} />;

              default:
                return <IconDiscover />;
            }
          }

          return <IconWrapper>{handleIcon()}</IconWrapper>;
        },
        tabBarActiveTintColor: colors.cornflower,
        tabBarInactiveTintColor: isDark ? colors.slate_blue : colors.bluey_grey,
        tabBarStyle: {
          height: 65 + insets.bottom,
          // padding: 10,
          // paddingVertical: 5,

          backgroundColor: isDark ? colors.dark2 : colors.white,
          borderTopColor: isDark ? '#333155' : '#f4f4f8',
        },
        tabBarLabelStyle: { marginBottom: 7 },
      })}>
      <BottomTab.Screen name="Discover" component={DiscoverHomeScreen} options={{ title: i18n.t('app.menu_discover') }} />
      <BottomTab.Screen name="Search" component={SearchHomeScreen} options={{ title: i18n.t('app.menu_search') }} />
      <BottomTab.Screen name="Messages" component={MessagesHomeScreen} options={{ title: i18n.t('app.menu_messages') }} />
      <BottomTab.Screen name="Profile" component={MyProfileHomeScreen} options={{ title: i18n.t('app.menu_profile') }} />
    </BottomTab.Navigator>
  );
}

const IconWrapper = styled.View`
  /* height: 80px; */
  /* padding: 20px 0; */
  /* padding-bottom: 30px; */
`;

const Badge = styled.View`
  flex-direction: row;
  align-items: center;
  padding: 8px 0 0 0;
`;

const BadgeTxtWraper = styled.View`
  color: ${(p) => p.theme.myColors.white};
  position: absolute;
  left: 18px;
  top: 1px;
  font-family: Gilroy-Light;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 9999px;
  background-color: ${(p) => (p.theme.isDark ? p.theme.myColors.coral : p.theme.myColors.coral)};
`;
const BadgeTxt = styled.Text`
  color: ${(p) => p.theme.myColors.white};
  font-family: Gilroy-Light;
  font-size: 12px;
`;
