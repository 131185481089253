import React, { useState, useEffect } from 'react';
import { View, Text } from 'react-native';
import styled from 'styled-components/native';
import HeaderWithIcons from '../../components/common/HeaderWithIcons';

export default function UserProfileGalleryScreen({ navigation }) {
  return (
    <Container>
      <HeaderWithIcons onPress={() => navigation.goBack()} />
      <Text>UserProfileGalleryScreen</Text>
    </Container>
  );
}

const Container = styled.View`
  display: flex;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
  flex: 1;
`;
