import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" color="#000" width={19} height={19} viewBox="0 0 594.149 594.149" {...props}>
    <Path
      fill="currentColor"
      d="m448.8 161.925-35.7-35.7-160.65 160.65 35.7 35.7 160.65-160.65zm107.099-35.7-267.75 270.3-107.1-107.1-35.7 35.7 142.8 142.8 306-306-38.25-35.7zM0 325.125l142.8 142.8 35.7-35.7-142.8-142.8-35.7 35.7z"
    />
  </Svg>
);

export default SvgComponent;
