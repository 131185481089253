import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={18} height={20} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.78 7.595a.75.75 0 010 1.06l-3.75 3.75a.75.75 0 01-1.06 0l-2.25-2.25a.75.75 0 111.06-1.06l1.72 1.72 3.22-3.22a.75.75 0 011.06 0z"
        fill="#6C6CFF"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.704 2.564a4.25 4.25 0 00-3.407 0l-4.8 2.1a1.248 1.248 0 00-.747 1.142v3.222c0 1.207.23 2.404.679 3.525a8.733 8.733 0 003.926 4.423l2.49 1.358c.055.03.108.067.155.108a.86.86 0 01.181-.121l2.568-1.284a8.089 8.089 0 004.13-4.91c.246-.82.371-1.672.371-2.528V5.808a1.25 1.25 0 00-.749-1.145l-4.797-2.1zM6.694 1.19a5.75 5.75 0 014.61 0l4.797 2.099a2.75 2.75 0 011.648 2.519v3.79c0 1.003-.146 2-.434 2.96a9.59 9.59 0 01-4.896 5.82l-2.49 1.245a1.44 1.44 0 01-1.872-.01l-2.421-1.32a10.233 10.233 0 01-4.6-5.183A10.992 10.992 0 01.25 9.028V5.806c0-1.091.646-2.08 1.646-2.517l4.8-2.1z"
        fill="#49497A"
      />
    </Svg>
  );
}

export default SvgComponent;
