import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={18} height={18} viewBox="0 0 18 18" color="#49497A" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.346.917a1.582 1.582 0 011.726.344l1.168 1.167.031.034a1.583 1.583 0 01-.032 2.207L5.21 13.696a.75.75 0 01-.53.22H2.333a.75.75 0 01-.75-.75v-2.348c0-.199.08-.39.22-.53l9.03-9.027s-.001 0 0 0c.146-.147.32-.264.513-.344zm1.81 2.549L12.01 2.321a.083.083 0 00-.09-.018.081.081 0 00-.026.018l-8.811 8.808v1.287H4.37l8.809-8.808a.085.085 0 00.024-.06.083.083 0 00-.024-.058.76.76 0 01-.023-.024zM.75 16.5a.75.75 0 01.75-.75h15a.75.75 0 010 1.5h-15a.75.75 0 01-.75-.75z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.345 2.745a.75.75 0 011.06 0l2.35 2.35a.75.75 0 01-1.06 1.06l-2.35-2.35a.75.75 0 010-1.06z"
        fill="currentColor"
      />
    </Svg>
  );
}

export default SvgComponent;
