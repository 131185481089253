import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={18} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.726 1.5A7.009 7.009 0 001.5 8.25a6.55 6.55 0 006.28 6.75.75.75 0 01.72.749v1.807c1.777-.905 3.505-2.05 4.853-3.434 1.606-1.648 2.643-3.6 2.647-5.883A7.009 7.009 0 008.726 1.5zM.75 8.226L0 8.199a8.509 8.509 0 018.75-8.2 8.51 8.51 0 018.75 8.216v.011c0 2.786-1.275 5.099-3.072 6.944-1.791 1.838-4.131 3.247-6.368 4.263A.75.75 0 017 18.75v-2.312a8.05 8.05 0 01-7-8.236l.75.024z"
        fill="#6D28FC"
      />
    </Svg>
  );
}

export default SvgComponent;
