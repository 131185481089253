import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={21} height={19} viewBox="0 0 21 19" color="#6C6CFF" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.996 9.226c.392 0 .709.317.709.709v3.924a2.125 2.125 0 00.948 1.769l1.607 1.069a4.957 4.957 0 005.49 0l1.607-1.07a2.124 2.124 0 00.948-1.768V9.935a.708.708 0 111.417 0v3.923a3.54 3.54 0 01-1.58 2.95l-1.607 1.069a6.375 6.375 0 01-7.06 0l-1.607-1.07a3.54 3.54 0 01-1.58-2.948V9.935c0-.392.317-.709.708-.709z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.564 1.869a3.25 3.25 0 00-3.006 0L2.172 5.2a1.25 1.25 0 00-.037 2.197l6.327 3.576a3.25 3.25 0 003.198 0l6.327-3.576A1.25 1.25 0 0017.95 5.2L11.564 1.87zm-3.7-1.33a4.75 4.75 0 014.394 0l6.386 3.332c1.934 1.008 1.98 3.759.081 4.832l-6.327 3.576a4.75 4.75 0 01-4.674 0L1.397 8.703C-.502 7.63-.456 4.879 1.478 3.87L7.864.538z"
        fill="currentColor"
      />
    </Svg>
  );
}

export default SvgComponent;
