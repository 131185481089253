import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import chatApi from '../../apis/chat';

const initialState = {
  data: [],
  loading: false,
};

export const fetchMessagesList = createAsyncThunk('fetchMessages', () => {
  return chatApi
    .getRoomsList()
    .then((res) => {
      if (res.data?.results) {
        // console.log('resres', res.data);
        return res.data?.results;
      } else {
        return [];
      }
      // console.log('resres', res.data);
    })
    .catch(() => {
      // console.log(err);
      throw new Error(res.data?.message || 'error');
    });
});

export const slice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    // login: (state, action) => {
    //   state.isSignedIn = true;
    //   state.access_token = action.payload?.access_token;
    //   state.userInfo = action.payload?.userInfo;
    // },
    // logout: (state) => {
    //   state.isSignedIn = false;
    //   state.access_token = null;
    //   state.userInfo = null;
    // },
    // setUserInfo: (state, action) => {
    //   state.userInfo = action.payload?.userInfo;
    // },
    clearData: (state, action) => {
      state.data = [];
    },
  },
  extraReducers: {
    [fetchMessagesList.pending]: (state) => {
      state.loading = true;
      // state.error = null;
    },
    [fetchMessagesList.fulfilled]: (state, action) => {
      // console.log('action', action);
      state.data = action.payload;
      state.loading = false;
    },
    [fetchMessagesList.rejected]: (state, action) => {
      state.loading = false;
      // state.data = null;
      // state.error = action.error?.message || 'خطایی رخ داده است';
    },
  },
});

// Action creators are generated for each case reducer function
export const { clearData } = slice.actions;

export default slice.reducer;
