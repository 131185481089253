import i18n from 'i18n-js';
import { useToast } from 'native-base';
// import DatePicker from 'react-native-date-picker';
import React, { useState, useEffect } from 'react';
import { Alert, BackHandler, StyleSheet, Platform } from 'react-native';
import styled from 'styled-components/native';

import authApi from '../../apis/auth';
import HeaderWithIcons from '../../components/common/HeaderWithIcons';
import IconEmail from '../../components/svg/IconEmail';
import IconForgotPassword from '../../components/svg/IconForgotPassword';
import { useTheme } from '../../components/template/ThemeProvider';
import MYButton from '../../components/ui/MYButton';
import Myinput from '../../components/ui/MYInput';
import handleErrorResponse from '../../services/handleErrorResponse';

export default function ForgotPassword({ navigation }) {
  const { colors, isDark, setScheme } = useTheme();
  const toast = useToast();
  const [loadingBtn, setloadingBtn] = useState(false);
  const [emailValue, setemailValue] = useState(null);

  function alert(title = undefined, msg) {
    if (Platform.OS === 'web') {
      toast.show({ title, status: 'info', description: msg });
    } else {
      Alert.alert(title, msg, [{ text: 'ok', onPress: () => null, style: 'cancel' }]);
    }
  }

  useEffect(() => {
    const backAction = () => {
      navigation.goBack();
      return true;
    };

    const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

    return () => backHandler.remove();
  }, []);

  function handlePressNext() {
    if (!emailValue) {
      // toast
      toast.show({
        description: 'Please enter email',
      });
      return;
    }

    setloadingBtn(true);
    authApi
      .sendForgotPasswordCode({
        email: emailValue?.replace(' ', '')?.toLowerCase(),
      })
      .then(async (res) => {
        setloadingBtn(false);

        navigation.navigate('Auth_ForgotPasswordSetNew', {
          email: emailValue,
        });
      })
      .catch((error) => {
        setloadingBtn(false);

        if (error.response) {
          alert(
            'Error',
            error.response.data?.message === 'Unauthorized' ? 'Username or password is wrong' : handleErrorResponse(error.response.data?.message)
          );
        }
      });
  }

  return (
    <Container>
      <HeaderWithIcons title={i18n.t('auth.forgotpassword_header_title')} onPress={() => navigation.goBack()} />

      <SelectContainer>
        <TopImage>
          <IconForgotPassword />
        </TopImage>

        <Title>{i18n.t('auth.forgotpassword_description')}</Title>

        <InputBox>
          <Myinput
            variant="unstyled"
            InputLeftElement={<IconEmail color={isDark ? colors.bluey_grey : colors.dark} />}
            placeholder={i18n.t('auth.placeholder_email')} // mx={4}
            value={emailValue || ''}
            onChangeText={(txt) => setemailValue(txt)}
          />
        </InputBox>
      </SelectContainer>

      <ButtonsContainer>
        <MYButton color="blue" style={{ width: '100%' }} onPress={handlePressNext} loading={loadingBtn}>
          {i18n.t('auth.continue')}
        </MYButton>
      </ButtonsContainer>
    </Container>
  );
}

const InputBox = styled.View`
  border-radius: 28px;
  border: solid 1px;
  border-color: ${(p) => (p.theme.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey)};
  padding: 10px 20px;
`;

const TopImage = styled.View`
  margin: 0 auto 26px;
`;

const Title = styled.Text`
  font-family: Gilroy-Medium;
  margin-bottom: 38px;
  padding: 0 35px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dusk)};
`;

const EmailContainer = styled.View`
  margin: 0 35px 44px;
  padding: 14px 10px;
  width: auto;
  background-color: ${(p) => (p.theme?.isDark ? `${p.theme.myColors.coral}26` : `${p.theme.myColors.coral}0f`)};
  border-radius: 11px;
`;
const EmailText = styled.Text`
  font-family: Gilroy-Medium;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: ${(p) => p.theme.myColors.coral};
`;

const ResendCode = styled.Text`
  font-family: Gilroy-Medium;
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  margin: 23px 0 0 0;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.slate_blue : p.theme.myColors.bluey_grey)};
`;

const Container = styled.View`
  display: flex;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
  flex: 1;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
`;

const ButtonsContainer = styled.View`
  padding: 0 34px;
  width: 100%;
  margin-bottom: 28px;
  flex-shrink: 0;
`;

const SelectContainer = styled.View`
  margin-top: 7%;
  flex: 1;
  width: 100%;
  padding: 0 35px;
`;
