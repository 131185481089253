import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={20} height={16} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 2.25a.75.75 0 01.75.75v10A1.25 1.25 0 003 14.25h14A1.25 1.25 0 0018.25 13V3a.75.75 0 011.5 0v10A2.75 2.75 0 0117 15.75H3A2.75 2.75 0 01.25 13V3A.75.75 0 011 2.25z"
        fill="#49497A"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.256 7.744a.75.75 0 010 1.06l-6.14 6.14a.75.75 0 01-1.06-1.06l6.14-6.14a.75.75 0 011.06 0zM11.75 7.75a.75.75 0 011.061 0l6.13 6.13a.75.75 0 11-1.06 1.061l-6.13-6.13a.75.75 0 010-1.06z"
        fill="#49497A"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.67.624A2.622 2.622 0 013.018.25h14.068a2.578 2.578 0 011.823 4.402l-.218.217c-.147.147-.31.277-.487.388l-6.747 4.216a2.75 2.75 0 01-2.914 0L1.796 5.257a2.75 2.75 0 01-.487-.388l-.144-.144A2.622 2.622 0 011.67.624zM3.018 1.75a1.122 1.122 0 00-.793 1.915l.144.144c.067.067.141.126.222.176L9.338 8.2c.405.254.92.254 1.325 0l6.746-4.216c.08-.05.155-.11.222-.176l.217-.218a1.079 1.079 0 00-.762-1.841H3.018z"
        fill="#6C6CFF"
      />
    </Svg>
  );
}

export default SvgComponent;
