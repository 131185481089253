import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import startupApi from '../../apis/startup';

const initialState = {
  stripe: {
    stripe_publishableKey: '',
    stripe_urlScheme: '',
    stripe_merchantIdentifier: '',
  },
  pusher: {
    pusherToken: '',
    pusherCluster: '',
  },
  // banners: [],
  viewedBannersIds: [],
};

// export const fetchStartupApi = createAsyncThunk('startup', () => {
//   return startupApi
//     .postStartUp()
//     .then((res) => {
//       if (res.data) {
//         // console.log('resres', res.data);
//         return res.data;
//       }
//       // console.log('resres', res.data);
//     })
//     .catch(() => {
//       // console.log(err);
//       throw new Error(res.data?.message || 'error');
//     });
// });

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveStartUpData: (state, action) => {
      state.stripe = action.payload?.stripe || null;
      state.pusher = action.payload?.pusher || null;
    },
    addToViewedBanners: (state, action) => {
      if (action.payload?.id) {
        state.viewedBannersIds = [...(state.viewedBannersIds || []), action.payload?.id];
      }
    },
    resetViewedBanners: (state, action) => {
      state.viewedBannersIds = [];
    },
  },
  // extraReducers: {
  //   [fetchStartupApi.pending]: (state) => {
  //     // state.loading = true;
  //     // state.error = null;
  //   },
  //   [fetchStartupApi.fulfilled]: (state, action) => {
  //     // console.log('action', action);
  //     state.stripe = action.payload?.stripe;
  //     state.pusher = action.payload?.pusher;
  //     // state.banners = action.payload?.banners?.length > 0 ? action.payload?.banners : [];
  //   },
  //   [fetchStartupApi.rejected]: (state, action) => {
  //     // state.loading = false;
  //     // state.data = null;
  //     // state.error = action.error?.message || 'خطایی رخ داده است';
  //   },
  // },
});
export const { saveStartUpData, addToViewedBanners, resetViewedBanners } = slice.actions;

export default slice.reducer;
