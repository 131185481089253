import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={28} height={28} viewBox="0 0 28 28" color="#C2C2E7" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M25.76 27.145l-.11-.094-4.17-4.066a12.935 12.935 0 01-8 2.746A12.69 12.69 0 01.667 13.2 12.691 12.691 0 0113.482.667 12.69 12.69 0 0126.298 13.2a12.334 12.334 0 01-3.375 8.467l4.121 4.019a.95.95 0 010 1.366 1 1 0 01-1.285.095l.001-.002zM2.641 13.2a10.736 10.736 0 0010.841 10.6 10.736 10.736 0 0010.842-10.6A10.736 10.736 0 0013.482 2.6 10.736 10.736 0 002.641 13.2z"
        fill="currentColor"
      />
    </Svg>
  );
}

export default SvgComponent;
