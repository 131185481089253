import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.336 4.829a2.359 2.359 0 014.027 1.695 2.226 2.226 0 01-1.206 1.9.756.756 0 00-.407.645.75.75 0 01-1.5-.05 2.256 2.256 0 011.218-1.928h.002a.727.727 0 00.393-.608.859.859 0 00-1.718.014.75.75 0 01-1.5 0c0-.626.249-1.226.691-1.668zM13 10.708a.85.85 0 100 1.698.85.85 0 000-1.698z"
        fill="#6C6CFF"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.149 2.037a8.074 8.074 0 00-5.144.238 6.44 6.44 0 00-3.822 7.896l.034.108-1.434.442-.033-.109A7.94 7.94 0 019.46.877a9.574 9.574 0 016.101-.282l.104.03a8.54 8.54 0 015.805 5.663l.031.102a7 7 0 01-2.072 7.356l-.648.567a.092.092 0 00-.031.069v2.445c0 .894-1.055 1.353-1.708.773l-1.953-1.172a1.25 1.25 0 00-.643-.178h-1.621c-.296 0-.59-.048-.87-.141l-1.192-.398.474-1.422 1.192.397c.128.042.261.064.396.064h1.621c.498 0 .988.136 1.415.392l1.389.833v-1.593c0-.46.198-.896.543-1.198l.649-.567a5.5 5.5 0 001.628-5.78l-.032-.102a7.04 7.04 0 00-4.785-4.669l-.104-.03z"
        fill="#6C6CFF"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.308 12.398a4.155 4.155 0 00-5.997 5.261.75.75 0 01.054.47l-.396 1.898 1.893-.402a.75.75 0 01.471.053 4.15 4.15 0 001.665.384.753.753 0 01.08-.004 4.155 4.155 0 002.23-7.66zm-2.13 9.16a5.655 5.655 0 10-5.33-3.5l-.582 2.789a.75.75 0 00.89.887l2.783-.592a5.65 5.65 0 002.145.421.744.744 0 00.094-.006z"
        fill="#49497A"
      />
      <Path
        d="M4.75 15.875a.875.875 0 11-1.75 0 .875.875 0 011.75 0zM7 15.875a.875.875 0 11-1.75 0 .875.875 0 011.75 0zM9.5 15.875a.875.875 0 11-1.75 0 .875.875 0 011.75 0z"
        fill="#49497A"
      />
    </Svg>
  );
}

export default SvgComponent;
