import { Actionsheet, Spinner, useDisclose, useToast } from 'native-base';
import React, { useState, useEffect } from 'react';
import { BackHandler, Animated, TouchableOpacity, View, Text, Alert, Platform } from 'react-native';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/native';

import evaluationApi from '../../apis/evaluation';
import usersApi from '../../apis/users';
import HeaderWithIcons from '../../components/common/HeaderWithIcons';
import UserProfileCard from '../../components/common/UserProfileCard';
import ActionSheetReport from '../../components/pages/userProfile/ActionSheetReport';
import ArrowLeft from '../../components/svg/ArrowLeft';
import IconReport from '../../components/svg/IconReport';
import { close, open } from '../../redux/slices/ModalSlice';
import handleErrorResponse from '../../services/handleErrorResponse';

export default function SingleUserProfileScreen({ route, navigation }) {
  const { isOpen, onOpen, onClose } = useDisclose();
  const dispatch = useDispatch();
  const toast = useToast();

  function alert(title = undefined, msg, footer = [{ text: 'ok', onPress: () => null, style: 'cancel' }]) {
    if (Platform.OS === 'web') {
      toast.show({ title: title, status: "info", description: msg })
    } else {
      Alert.alert(title, msg, footer);
    }
  }

  useEffect(() => {
    const backAction = () => {
      navigation.goBack();
      return true;
    };

    const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

    return () => backHandler.remove();
  }, []);

  const { userData, isLiked = null, disableActions = false } = route.params;

  const [_isLiked, set_isLiked] = useState(isLiked);
  const [_loadingEvaluation, set_loadingEvaluation] = useState(false);

  useEffect(() => {
    if (isLiked === null || isLiked === undefined) set_loadingEvaluation(true);

    evaluationApi
      .isILikedThisUser(userData?._id)
      .then((res) => {
        set_loadingEvaluation(false);
        if (res.data?.evaluation) {
          set_isLiked(!!res.data?.evaluation);
        }
      })
      .catch((err) => {
        set_loadingEvaluation(false);

        if (err.response && err.response?.status !== 404) {
          alert('Error', err?.response?.message || 'error');
        }
      });
  }, []);

  function handleSaveLikeDislike(direction) {
    if (direction >= 1) {
      set_loadingEvaluation(true);
      if (_isLiked) {
        evaluationApi
          .removelikeUser(userData?._id)
          .then((res) => {
            set_loadingEvaluation(false);
            if (res.data?.status) set_isLiked(false);
          })
          .catch((err) => {
            alert('Error', err?.response?.message || 'error');
            set_loadingEvaluation(false);
          });
      } else {
        evaluationApi
          .likeUser(userData?._id)
          .then((res) => {
            set_loadingEvaluation(false);
            if (res.data?.status) set_isLiked(true);
          })
          .catch((err) => {
            alert('Error', err?.response?.message || 'error');
            set_loadingEvaluation(false);
          });
      }
    } else {
      evaluationApi.dislikeUser(userData?._id).then((res) => console.log(res.data));
    }
  }

  function handleOpenModal() {
    dispatch(
      open({
        content: () => (
          <View style={{ paddingTop: 50, paddingBottom: 50 }}>
            <Spinner accessibilityLabel="Loading" />
          </View>
        ),
      })
    );
  }

  return (
    <Container>
      <BgImage source={require('../../assets/images/userprofilebg1.jpg')} />
      <HeaderWithIcons
        leftNode={
          <IconBox>
            <ArrowLeft fill="#ffffff" />
          </IconBox>
        }
        rightNode={
          !disableActions && (
            <TouchableOpacity onPress={onOpen}>
              <IconBox style={{ paddingTop: 15 }}>
                <IconReport color="#ffffff" />
              </IconBox>
            </TouchableOpacity>
          )
        }
        onPress={() => navigation.goBack()}
      />
      <UserProfileCardWrapper>
        <UserProfileCard
          navigation={navigation}
          userData={userData}
          isLiked={_isLiked}
          disableActions={disableActions}
          isLoadingChoice={_loadingEvaluation}
          handleChoice={handleSaveLikeDislike}
        />
      </UserProfileCardWrapper>
      <Actionsheet isOpen={isOpen} onClose={onClose}>
        <ActionSheetReport onClose={onClose} userId={userData?._id} />
      </Actionsheet>
    </Container>
  );
}

const Container = styled.View`
  display: flex;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
  flex: 1;
`;
const BgImage = styled.Image`
  position: absolute;
  width: 100%;
  border-bottom-left-radius: 76px;
  height: 222px;
`;
const IconBox = styled.View`
  width: 52px;
  height: 52px;
  border: 1px solid;
  border-radius: 200px;
  border-color: ${(p) => `${p.theme.myColors.pale_grey}42`};
  padding: 17px 0 0 16px;
  ${(p) => !p.children && `border: none`}
`;
const UserProfileCardWrapper = styled(Animated.View)`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0 35px;
  margin-top: 130px;
  padding-bottom: 130px;
  /* background-color: red; */
`;
