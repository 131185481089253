import axios from 'axios';

import general from '../constants/general';
import { getValueFromSecureStore } from './secureStoreService';

const apiService = axios.create({
  baseURL: general.baseUrl,
  timeout: 60000,
  // headers: { 'X-Custom-Header': 'foobar' },
});

// Add a request interceptor
apiService.interceptors.request.use(async function (config) {
  const token = await getValueFromSecureStore('access_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

// apiService.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (error.response.status === 401) {
//       //place your reentry code
//       console.log('401111111111111111111111111111111111111');
//     }
//     return Promise.reject(error);
//   }
// );

export default apiService;
