import { Picker } from '@react-native-picker/picker';
import i18n from 'i18n-js';
import country from 'country-list-js';
import { useToast } from 'native-base';
import React, { useState, useEffect } from 'react';
import { Alert, BackHandler, TouchableOpacity } from 'react-native';
import CountryPicker, { DEFAULT_THEME, DARK_THEME } from 'react-native-country-picker-modal';
import styled from 'styled-components/native';
import { ModalSelectCity } from '../../components/common/ModalSelectCity';
import IconLocation2 from '../../components/svg/IconLocation2';
import HeaderWithIcons from '../../components/common/HeaderWithIcons';
import { useTheme } from '../../components/template/ThemeProvider';
import MYButton from '../../components/ui/MYButton';
import MySelect from '../../components/ui/MySelect';

export default function SelectCountry({ route, navigation }) {
  const { colors, isDark, setScheme } = useTheme();
  const toast = useToast();
  // const [loadingBtn, setloadingBtn] = useState(false);
  // const [selected, setSelected] = useState({
  //   countryCode: null,
  //   state: null,
  //   city: null,
  // });

  const { email, gender, name, birthday, access_token } = route.params;

  useEffect(() => {
    const backAction = () => {
      navigation.goBack();
      return true;
    };

    const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

    return () => backHandler.remove();
  }, []);

  function handlePressNext(selected) {
    if (!selected?.countryCode || !selected?.city) {
      // toast
      toast.show({
        description: 'please select country and city',
      });
      return;
    }

    navigation.navigate('Auth_SetAvatar', {
      email,
      gender,
      access_token,
      name,
      birthday,
      countryCode: selected?.countryCode,
      state: selected?.state,
      city: selected?.city,
    });
  }

  // const SelectedCountryFullName = country.findByIso2(selected?.countryCode?.toUpperCase())?.name;

  return (
    <ModalSelectCity
      navigation={navigation}
      onSubmitCity={(v) => {
        if (v?.name) {
          handlePressNext({ countryCode: v?.countryCode, state: v?.stateCode, city: v?.name });
        }
      }}
    />
  );

  // return (
  //   <Container>
  //     <HeaderWithIcons title={i18n.t('auth.signup_header_title')} onPress={() => navigation.goBack()} />

  //     <SelectContainer>
  //       <Title>{i18n.t('auth.your_country')}</Title>

  //       <InputsBox>
  //         {selected?.city ? <InputTitle>{i18n.t('edit_my_profile.city')}</InputTitle> : null}
  //         <TouchableOpacity
  //           onPress={() =>
  //             navigation.navigate('ModalSelectCity', {
  //               onGoBack: (v) => {
  //                 if (v?.name) {
  //                   setSelected({ countryCode: v?.countryCode, state: v?.stateCode, city: v?.name });
  //                 }
  //               },
  //               initialCountry: selected?.countryCode,
  //             })
  //           }>
  //           <CountryFieldBox>
  //             <CountryFieldIcon>
  //               <IconLocation2 color={isDark ? colors.bluey_grey : colors.dark} />
  //             </CountryFieldIcon>
  //             <CountryFieldTxt>{selected?.city && `${selected?.city}, ${selected?.state}, ${SelectedCountryFullName}`}</CountryFieldTxt>
  //           </CountryFieldBox>
  //         </TouchableOpacity>
  //       </InputsBox>

  //       {/* <InputsBox>
  //         {countryCode ? <InputTitle>Country</InputTitle> : null}

  //         <CountryPicker
  //           theme={isDark ? DARK_THEME : DEFAULT_THEME}
  //           countryCode={countryCode}
  //           withFilter
  //           withFlag
  //           withCountryNameButton
  //           withAlphaFilter
  //           // withCallingCode
  //           withEmoji
  //           onSelect={onSelect}
  //           // visible
  //         />
  //       </InputsBox> */}

  //       {/* <InputsBox>
  //         {city ? <InputTitle>City</InputTitle> : null}
  //         <MySelect
  //           variant="unstyled"
  //           minWidth="100%"
  //           placeholder="City" // mx={4}
  //           onValueChange={(itemValue) => setCity(itemValue)}
  //           selectedValue={city || null}
  //           options={citiesOptions}
  //         />
  //       </InputsBox> */}
  //     </SelectContainer>

  //     <ButtonsContainer>
  //       <MYButton color="blue" style={{ width: '100%' }} onPress={handlePressNext} loading={loadingBtn}>
  //         {i18n.t('auth.continue')}
  //       </MYButton>
  //     </ButtonsContainer>
  //   </Container>
  // );
}

// const Container = styled.View`
//   display: flex;
//   background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
//   flex: 1;
//   align-items: center;
//   justify-content: space-between;
//   overflow: hidden;
//   width: 100%;
// `;

// const ButtonsContainer = styled.View`
//   padding: 0 34px;
//   width: 100%;
//   margin-bottom: 28px;
//   flex-shrink: 0;
// `;

// const SelectContainer = styled.View`
//   margin-top: 7%;
//   flex: 1;
//   width: 100%;
//   padding: 0 35px;
// `;

// const Title = styled.Text`
//   font-family: Gilroy-Bold;
//   margin-bottom: 31px;
//   font-size: 22px;
//   text-align: center;
//   color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dusk)};
// `;

// const InputsBox = styled.View`
//   border-radius: 28px;
//   border: solid 1px;
//   border-color: ${(p) => (p.theme.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey)};
//   padding: 15px 20px;
//   margin: 10px 35px;
//   position: relative;
// `;

// const InputTitle = styled.Text`
//   color: ${(p) => (p.theme.isDark ? p.theme.myColors.light_blue_grey : p.theme.myColors.bluey_grey)};
//   position: absolute;
//   left: 20px;
//   top: -10px;
//   font-family: Gilroy-Light;
//   font-size: 12px;
//   padding: 0 6px;
//   background-color: ${(p) => (p.theme.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
// `;

// const CountryFieldBox = styled.View`
//   flex-direction: row;
//   align-items: center;
//   padding: 8px 0;
// `;

// const CountryFieldIcon = styled.View`
//   margin-right: 6px;
// `;
// const CountryFieldTxt = styled.Text`
//   color: ${(p) => (p.theme.isDark ? p.theme.myColors.pale_grey : p.theme.myColors.dark2)};
//   font-family: Gilroy-Medium;
//   font-size: 12px;
// `;
