import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.667 7.083a1.917 1.917 0 100 3.834 1.917 1.917 0 000-3.834zm-1.308-1.24a3.417 3.417 0 112.616 6.314 3.417 3.417 0 01-2.616-6.314zM14.333 12.416a1.916 1.916 0 100 3.832 1.916 1.916 0 000-3.832zm2.416-.5a3.416 3.416 0 00-5.573 3.724 3.417 3.417 0 105.573-3.723z"
        fill="#49497A"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.63 4.515a.75.75 0 01-.335 1.006l-5.92 2.96a.75.75 0 01-.67-1.342l5.92-2.96a.75.75 0 011.006.336zM5.37 9.855a.75.75 0 011.005-.336l5.92 2.96a.75.75 0 11-.67 1.342l-5.92-2.96a.75.75 0 01-.336-1.006z"
        fill="#49497A"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.333 1.75a1.917 1.917 0 100 3.834 1.917 1.917 0 000-3.834zm2.416-.5a3.416 3.416 0 10-4.832 4.831 3.416 3.416 0 004.832-4.83z"
        fill="#6C6CFF"
      />
    </Svg>
  );
}

export default SvgComponent;
