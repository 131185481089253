import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={18} height={14} viewBox="0 0 18 14" color="#67679B" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M8.008 13.75a2.02 2.02 0 01-1.191-.507l-2.645-2.206H2.814A1.983 1.983 0 01.859 9.103a20.965 20.965 0 010-4.258 2.026 2.026 0 011.955-1.882h1.358L6.767.789A2.261 2.261 0 018.068.25a1.911 1.911 0 011.776 1.467c.085.369.143.744.173 1.121l.036.331c.135 2.552.135 5.11 0 7.662l-.036.344a6.778 6.778 0 01-.167 1.089 1.905 1.905 0 01-1.763 1.486h-.079zm6.46-.165a.718.718 0 01-.173-.981 10.054 10.054 0 001.579-5.6 10.053 10.053 0 00-1.579-5.6.72.72 0 01.172-.981.678.678 0 01.958.177A11.49 11.49 0 0117.25 7c.038 2.27-.597 4.5-1.825 6.408a.684.684 0 01-.957.177zm-2.371-2.278a.718.718 0 01-.172-.981c.632-.992.957-2.15.933-3.326a5.966 5.966 0 00-.933-3.326.718.718 0 01.171-.981.68.68 0 01.959.178A7.408 7.408 0 0114.234 7a7.408 7.408 0 01-1.179 4.129.677.677 0 01-.958.178z"
        fill="currentColor"
      />
    </Svg>
  );
}

export default SvgComponent;
