import moment from 'moment';
import { Spinner, useToast } from 'native-base';
import Pusher from 'pusher-js/react-native';
import React, { useState, useEffect, useRef } from 'react';
import { View, Platform, Alert } from 'react-native';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';
import { debounce } from 'lodash';

import chatApi from '../../apis/chat';
import ChatHeader from '../../components/pages/chat/ChatHeader';
import InputSendMsg from '../../components/pages/chat/InputSendMsg';
import MessageBubble from '../../components/pages/chat/MessageBubble';
import Seperator from '../../components/pages/chat/Seperator';
import general from '../../constants/general';
import handleErrorResponse from '../../services/handleErrorResponse';
import { getValueFromSecureStore } from '../../services/secureStoreService';

function isCloseToBottom({ layoutMeasurement, contentOffset, contentSize }) {
  return layoutMeasurement.height + contentOffset.y >= contentSize.height - 20;
}

function ifCloseToTop({ layoutMeasurement, contentOffset, contentSize }) {
  return contentOffset.y === 0;
}

export default function ChatScreen({ route, navigation }) {
  const { userData, userId, roomId, userName, userImagesUrls, avatar, unreadCount, unSeenCount, isBlocked } = route.params;
  const [Data, setData] = useState([]);
  const [loadingHistory, setloadingHistory] = useState(true);
  const userReduxState = useSelector((state) => state.user);
  const startupReduxState = useSelector((state) => state.startup);

  const [paginationPage, setPaginationPage] = useState(0);
  const [paginationTotal, setPaginationTotal] = useState(0);
  const [_unSeenCount, setUnSeenCount] = useState(unSeenCount ? Number(unSeenCount) : 0);
  const [_isBlocked, setisBlocked] = useState(!!isBlocked);
  const scrollViewRef = useRef();
  const [accessToken, setaccessToken] = useState(null);
  const [roomId_, setroomId_] = useState(roomId);
  const [isTyping, setIsTyping] = useState(false);
  const toast = useToast();

  function alert(title = undefined, msg, footer = [{ text: 'ok', onPress: () => null, style: 'cancel' }]) {
    if (Platform.OS === 'web') {
      toast.show({ title: title, status: 'info', description: msg });
    } else {
      Alert.alert(title, msg, footer);
    }
  }

  const handleIsTyping = debounce(function () {
    // continually delays setting "isTyping" to false for 500ms until the user has stopped typing and the delay runs out
    setIsTyping(false);
  }, 5000);

  // useEffect(() => {

  // }, [isTyping]);

  function handleSendMsg(message) {
    chatApi
      .sendMsg(userId, { message })
      .then((res) => {
        setUnSeenCount((prev) => Number(prev) + 1);

        if (res.status === 201 || res.status === 200) {
          setData((prev) => [
            ...prev,
            {
              type: 'msg',
              isself: true,
              txt: message,
              date: new Date(),
              time: '',
            },
          ]);
        } else {
          throw new Error('Problem sending message');
        }

        setTimeout(() => {
          scrollViewRef && scrollViewRef?.current?.scrollToEnd({ animated: true });
        }, 300);
      })
      .catch((error) => {
        // console.log('error send msg', error.response);
        alert('Oops!', error.response ? handleErrorResponse(error.response.data?.message) : 'Problem in sending message');
      });
  }

  async function getRoomId() {
    return await chatApi
      .getRoomIdByUserId(userId)
      .then((res) => {
        // const lastMessageSenderIsMe = res?.data?.lastMessageSender === userReduxState?.userInfo?._id;
        const unSeenCount = res?.data?.unreadCounts?.find((a) => a._id !== userReduxState?.userInfo?._id);

        const isBlocked = !!res?.data?.blocks?.find((a) => a === userId);
        setisBlocked(isBlocked);

        if (res?.data?._id) {
          setroomId_(res?.data?._id);
          setUnSeenCount(unSeenCount ? Number(unSeenCount) : 0);

          return res?.data?._id;
        } else {
          return null;
        }
      })
      .catch(() => {
        // console.log('err get roomId', err.response?.data, userId);
        return null;
      });
  }

  async function getMessagesHistory(_page = 1, roomIdddd) {
    setloadingHistory(true);

    if (!roomIdddd) {
      setloadingHistory(false);

      const roomId = await getRoomId();

      if (roomId) {
        getMessagesHistory(_page, roomId);
      }

      return;
    }

    await chatApi
      .getChatHistory(roomIdddd, `?page=${_page}`)
      .then((res) => {
        const normalizeData = res.data?.results?.reverse()?.map((item) => {
          return {
            type: 'msg',
            isself: item?.reciever === userId,
            txt: item?.message,
            date: item?.createdAt,
            time: '',
          };
        });
        setData((prev) => [...(normalizeData || []), ...(prev || [])]);
        setPaginationPage((prev) => prev + 1);
        setPaginationTotal(res.data?.total);
        setTimeout(() => {
          if (_page === 1) {
            scrollViewRef && scrollViewRef?.current?.scrollToEnd({ animated: true });
          } else {
            scrollViewRef && scrollViewRef?.current?.scrollTo(40);
          }
        }, 300);
      })
      .catch(() => {
        setloadingHistory(false);
      });
    setloadingHistory(false);
  }

  async function onPusherRecievesNewMessage(data) {
    if (data?.sender === userId) {
      setData((prev) => [
        ...prev,
        {
          type: 'msg',
          isself: false,
          txt: data?.message,
          date: new Date(),
          time: '',
        },
      ]);

      setUnSeenCount(0);

      if (roomId_) {
        // for making message as seen (when fetch server seen it)
        await chatApi.getChatHistory(roomId_);
      }
    }
    setTimeout(() => {
      scrollViewRef && scrollViewRef?.current?.scrollToEnd({ animated: true });
    }, 300);
    return data;
  }

  useEffect(() => {
    getMessagesHistory(1, roomId_);

    const getAccessToken = async () => {
      const token = await getValueFromSecureStore('access_token');
      setaccessToken(token);
    };

    getAccessToken();
  }, []);

  useEffect(() => {
    if (accessToken && startupReduxState?.pusher?.pusherCluster) {
      const pusher = new Pusher(startupReduxState?.pusher?.pusherToken, {
        cluster: startupReduxState?.pusher?.pusherCluster,
        authEndpoint: `${general.baseUrl}auth/pusher`,
        auth: {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      });
      Pusher.logToConsole = true;
      const channel = pusher.subscribe(`private-chat-${userReduxState?.userInfo?._id}`);
      channel.bind('newMessageEvent', (data) => onPusherRecievesNewMessage(data));

      return () => {
        pusher.unsubscribe(`private-chat-${userReduxState?.userInfo?._id}`);
      };
    }
  }, [accessToken, startupReduxState]);

  return (
    <Container>
      <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : ''}>
        <ChatHeader
          title={userName}
          subTitle=""
          imagesUrls={userImagesUrls}
          avatar={avatar}
          navigation={navigation}
          roomId={roomId}
          isBlocked={_isBlocked}
          userId={userId}
          userData={userData}
        />

        <RenderChats
          ref={scrollViewRef}
          onScroll={({ nativeEvent }) => {
            if (ifCloseToTop(nativeEvent)) {
              if (Data?.length && Data?.length < paginationTotal) {
                getMessagesHistory(paginationPage + 1, roomId_);
              }
            }
          }}
          scrollEventThrottle={400}>
          {!Data?.length && loadingHistory ? (
            <Spinner size="lg" style={{ marginTop: 90 }} />
          ) : (
            <View>
              {Data?.length < paginationTotal ? <Spinner size="lg" /> : null}
              {Data?.map((item, index) => {
                if (item?.type === 'seperator') return <Seperator key={index} title={item?.txt} />;
                if (item?.type === 'msg') {
                  const iscurrentDate = moment().diff(moment(item?.date), 'days');
                  return (
                    <MessageBubble
                      key={index}
                      text={item?.txt}
                      time={iscurrentDate === 0 ? moment(item?.date).format('hh:mm') : moment(item?.date).format('DD MMM, hh:mm')}
                      isself={item?.isself}
                      isDoubleTick={_unSeenCount < Data?.length - index}
                    />
                  );
                }
              })}
            </View>
          )}
        </RenderChats>

        <InputSendMsg
          handleSendMsg={handleSendMsg}
          onChange={() => {
            setIsTyping(true);
            handleIsTyping();
          }}
        />
      </KeyboardAvoidingView>
    </Container>
  );
}

const Container = styled.View`
  flex: 1;
  height: 100%;
  display: flex;
  flex-grow: 1;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
`;
const KeyboardAvoidingView = styled.KeyboardAvoidingView`
  flex: 1;
  height: 100%;
  display: flex;
  flex-grow: 1;
`;
const RenderChats = styled.ScrollView`
  flex: 1;
`;
