import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" color="#000" width={24} height={24} {...props}>
    <Path fill="none" d="M0 0h24v24H0z" />
    <Path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m12 17.768 4.011 2.108a1.064 1.064 0 0 0 1.544-1.122l-.766-4.466 3.246-3.161a1.064 1.064 0 0 0-.59-1.816l-4.485-.652-2.005-4.065a1.064 1.064 0 0 0-1.909 0L9.04 8.659l-4.484.652a1.065 1.065 0 0 0-.59 1.816l3.246 3.161-.766 4.466a1.064 1.064 0 0 0 1.544 1.122L12 17.768z"
    />
  </Svg>
);

export default SvgComponent;
