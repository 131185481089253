import React from 'react';
import { Platform, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';

import IconClose from '../../components/svg/IconClose';
import { useTheme } from '../template/ThemeProvider';
import MYButton from '../ui/MYButton';

export default function StartupBanners({ banner, navigation, onClose }) {
  const { isDark, colors } = useTheme();
  const userReduxState = useSelector((state) => state.user);

  const text =
    banner?.descriptions?.find((a) => a.lang?.toLowerCase() === userReduxState?.userSettings?.language?.toLowerCase())?.txt ||
    banner?.descriptions?.find((a) => a.lang?.toLowerCase() === 'en')?.txt;
  const actionTxt =
    banner?.actionTxts?.find((a) => a.lang?.toLowerCase() === userReduxState?.userSettings?.language?.toLowerCase())?.txt ||
    banner?.actionTxts?.find((a) => a.lang?.toLowerCase() === 'en')?.txt;

  function handleAction(action) {
    switch (action) {
      case 'PAGE_DISCOVER':
        navigation.navigate('Discover');
        break;
      case 'PAGE_SEARCH':
        navigation.navigate('Search');
        break;
      case 'PAGE_MESSAGES':
        navigation.navigate('Messages');
        break;
      case 'PAGE_MYPROFILE':
        navigation.navigate('Profile');
        break;
      case 'PAGE_SUBSCRIPTIONS':
        navigation.navigate('SubscriptionHomeScreen');
        break;
      case 'CLOSE_BANNER':
        break;

      default:
        break;
    }
  }

  return (
    <Container>
      <View1>
        <TouchableOpacity onPress={() => onClose(false)}>
          <CloseIconParent>
            <CloseIcon color={isDark ? colors.white : colors.dusk} width={17} height={17} />
          </CloseIconParent>
        </TouchableOpacity>

        {banner?.image ? <ImageItem source={{ uri: banner?.image }} /> : null}
        {text && <Txt>{text}</Txt>}

        {actionTxt && (
          <MYButton
            color="red"
            style={{ width: '100%' }}
            onPress={() => {
              handleAction(banner?.action);
              setTimeout(() => {
                onClose(true);
              }, 300);
            }}>
            {actionTxt}
          </MYButton>
        )}
      </View1>
    </Container>
  );
}

const Container = styled.ScrollView``;
const View1 = styled.View`
  padding: 15px;
  position: relative;
`;

const ImageItem = styled.Image`
  width: 100%;
  height: ${Platform.OS === 'web' ? '200px' : 'auto'};
  aspect-ratio: 1;
  border-radius: 12px;
  position: relative;
  z-index: 0;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark_grey_blue : p.theme.myColors.pale_grey2)};
`;
const Txt = styled.Text`
  margin-top: 16px;
  margin-bottom: 20px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dark)};
  text-align: center;
`;
const CloseIconParent = styled.View`
  margin-top: 6px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: 6px;
  /* background: red; */
  padding: 10px;
  border-radius: 9999px;
  /* position: absolute; */
  /* top: 0;
  right: 0; */
  /* z-index: 999; */
`;
const CloseIcon = styled(IconClose)``;
