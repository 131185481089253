import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={20} height={18} viewBox="0 0 20 18" color="#000" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.462.284a2.04 2.04 0 011.17.155l.007.003 14.428 6.695a2.05 2.05 0 010 3.722l-14.428 6.7-.316-.681.31.683a2.04 2.04 0 01-2.758-2.558L3.017 9 .875 2.998A2.04 2.04 0 012.462.284zm.55 15.912l14.425-6.699a.55.55 0 000-.998L3.011 1.804a.54.54 0 00-.727.68l.002.007L4.52 8.748a.75.75 0 010 .504l-2.235 6.265a.54.54 0 00.727.679z"
        fill="currentColor"
      />
    </Svg>
  );
}

export default SvgComponent;
