import apiService from '../services/apiService';

const authApi = {
  async register(body) {
    return apiService.post('auth/register', body);
  },
  async login(body) {
    return apiService.post('auth/login', body);
  },
  async sendForgotPasswordCode(body) {
    return apiService.post('auth/send-forgot-password', body);
  },
  async forgetPasswordConfirm(body) {
    return apiService.post('auth/forgot-password', body);
  },
  async changePassword(body) {
    return apiService.post('auth/change-password', body);
  },
  async updateProfile(body, access_token) {
    return apiService.put('user/profile', body, { headers: { Authorization: `Bearer ${access_token}` } });
  },
  async emailVerify(body) {
    return apiService.post('auth/email-verify', body);
  },
  async resendEmailVerifyCode(body) {
    return apiService.post('auth/resend-email-verify-code', body);
  },
};

export default authApi;
