import { useToast } from 'native-base';
import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, Animated, Touchable, TouchableOpacity, Alert, Platform } from 'react-native';
import { Swipeable, TouchableWithoutFeedback } from 'react-native-gesture-handler';
import styled from 'styled-components/native';

import chatApi from '../../../apis/chat';
import usersApi from '../../../apis/users';
import handleErrorResponse from '../../../services/handleErrorResponse';
import IconBlockSingleColor from '../../svg/IconBlockSingleColor';
import IconDelete from '../../svg/IconDelete';
import IconMuted from '../../svg/IconMuted';
import IconUnmute from '../../svg/IconUnmute';

export default function ListItem({
  title,
  subTitle,
  time,
  unreadCount,
  unSeenCount,
  navigation,
  roomId,
  userId,
  myId,
  userName,
  userImagesUrls,
  avatar,
  doRefresh,
  openDeleteActionSheet,
  muteds,
  blocks,
  userData,
}) {
  // console.log('ListItem', roomId, userId, userName, userImagesUrls);
  const isBlocked = !!blocks?.find((a) => a === userId);
  const isMuted = !!muteds?.find((a) => a === myId);
  const toast = useToast();

  function alert(title = undefined, msg) {
    if (Platform.OS === 'web') {
      toast.show({ title, status: 'info', description: msg });
    } else {
      Alert.alert(title, msg, [{ text: 'ok', onPress: () => null, style: 'cancel' }]);
    }
  }

  function handleBlock() {
    usersApi
      .blockUser({
        userId,
      })
      .then(async (res) => {
        doRefresh();
      })
      .catch((error) => {
        if (error.response) {
          alert(null, handleErrorResponse(error.response.data?.message));
        }
      });
  }

  function handleUnBlock() {
    usersApi
      .unblockUser({
        userId,
      })
      .then(async (res) => {
        doRefresh();
      })
      .catch((error) => {
        if (error.response) {
          alert(null, handleErrorResponse(error.response.data?.message));
        }
      });
  }

  function handleMute() {
    chatApi
      .muteChat(roomId)
      .then(async (res) => {
        doRefresh();
      })
      .catch((error) => {
        if (error.response) {
          alert(null, handleErrorResponse(error.response.data?.message));
        }
      });
  }

  function handleUnMute() {
    chatApi
      .unmuteChat(roomId)
      .then(async (res) => {
        doRefresh();
      })
      .catch((error) => {
        if (error.response) {
          alert(null, handleErrorResponse(error.response.data?.message));
        }
      });
  }

  const RightActions = () => {
    return (
      <ActionsContainer>
        <ActionBox>
          <TouchableOpacity onPress={isBlocked ? handleUnBlock : handleBlock}>
            <ActionBlockContainer isBlocked={isBlocked}>
              <IconBlockSingleColor color={isBlocked ? '#ffffff' : '#ff5d3b'} />
            </ActionBlockContainer>
          </TouchableOpacity>
        </ActionBox>
        <ActionBox>
          <TouchableOpacity onPress={isMuted ? handleUnMute : handleMute}>
            <ActionMuteContainer isMuted={isMuted}>
              {!isMuted && <IconUnmute color={isMuted ? '#ffffff' : '#67679B'} />}
              {isMuted && <IconMuted color={isMuted ? '#ffffff' : '#67679B'} style={{ marginTop: -2 }} />}
            </ActionMuteContainer>
          </TouchableOpacity>
        </ActionBox>
        <ActionBox>
          <TouchableOpacity onPress={openDeleteActionSheet}>
            <ActionDeleteContainer>
              <IconDelete />
            </ActionDeleteContainer>
          </TouchableOpacity>
        </ActionBox>
      </ActionsContainer>
    );
  };

  return (
    <Swipeable renderRightActions={RightActions}>
      <TouchableWithoutFeedback
        onPress={() =>
          navigation.navigate('ChatScreen', {
            roomId,
            userId,
            userName,
            userImagesUrls,
            avatar,
            unreadCount,
            unSeenCount,
            isBlocked,
            userData,
          })
        }>
        <ChatItem>
          <AvatarBox>
            <Avatar source={avatar ? { uri: avatar } : require('../../../assets/images/defaultavatar1.png')} />
          </AvatarBox>
          <ChatTexts>
            <ChatTitle>{title}</ChatTitle>
            <ChatSubTitle>{subTitle}</ChatSubTitle>
          </ChatTexts>
          <TimeAndUnreadCount>
            {time && <Time>{time}</Time>}
            {!isMuted && unreadCount ? (
              <UnreadCount>
                <UnreadCountText>{unreadCount}</UnreadCountText>
              </UnreadCount>
            ) : null}
            {isMuted && <IconMuted />}
          </TimeAndUnreadCount>
        </ChatItem>
      </TouchableWithoutFeedback>
    </Swipeable>
  );
}

const ChatItem = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0;
  padding-right: 35px;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
`;
const AvatarBox = styled.View`
  border-radius: 1000px;
  padding: 2px;
  border: 1px solid;
  border-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey)};
  margin-left: 35px;
`;
const Avatar = styled.Image`
  border-radius: 1000px;
  width: 52px;
  height: ${Platform.OS === 'web' ? '52px' : 'auto'};
  aspect-ratio: 1;
`;
const ChatTexts = styled.View`
  flex: 1;
  margin-left: 11px;
`;
const TimeAndUnreadCount = styled.View`
  margin-left: 11px;
`;
const Time = styled.Text`
  font-family: Gilroy-Medium;
  font-size: 10px;
  font-weight: 500;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.bluey_grey : p.theme.myColors.bluey_grey)};
`;
const UnreadCount = styled.View`
  background: ${(p) => p.theme.myColors?.coral};
  border-radius: 1000;
  width: 23px;
  height: 23px;
`;
const UnreadCountText = styled.Text`
  font-family: Gilroy-Medium;
  font-size: 10px;
  font-weight: 500;
  color: ${(p) => p.theme.myColors?.white};
  padding-top: 4px;
  text-align: center;
`;
const ChatTitle = styled.Text`
  font-family: Gilroy-SemiBold;
  font-size: 14px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dark2)};
  text-align: left;
`;
const ChatSubTitle = styled.Text`
  font-family: Gilroy-Light;
  font-size: 12px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.light_blue_grey : p.theme.myColors.slate_blue)};
  text-align: left;
`;
const ActionsContainer = styled.View`
  display: flex;
  flex-direction: row;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark3 : p.theme.myColors.pale_grey2)};
  padding: 0 15px 0 4px;
`;
const ActionBox = styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: center;
  padding: 0 8px;
`;
const ActionDeleteContainer = styled.View`
  background-color: ${(p) => (p.theme?.isDark ? `${p.theme.myColors.watermelon}26` : `${p.theme.myColors.watermelon}1a`)};
  border-radius: 1000px;
  padding: 8.5px 10px;
  border: 3px solid;
  border-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark_grey_blue3 : p.theme.myColors.white)};
  width: 40px;
  height: 40px;
`;
const ActionMuteContainer = styled.View`
  background-color: ${(p) => (p?.isMuted ? '#67679B' : p.theme?.isDark ? `${p.theme.myColors.white}26` : `${p.theme.myColors.cornflower}1a`)};
  border-radius: 1000px;
  padding: 10px 8px;
  border: 3px solid;
  border-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark_grey_blue3 : p.theme.myColors.white)};
  width: 40px;
  height: 40px;
`;
const ActionBlockContainer = styled.View`
  background-color: ${(p) =>
    p?.isBlocked ? p.theme.myColors.coral : p.theme?.isDark ? `${p.theme.myColors.watermelon}26` : `${p.theme.myColors.watermelon}1a`};
  border-radius: 1000px;
  padding: 8px 8.5px;
  border: 3px solid;
  border-color: ${(p) => (p?.isBlocked ? p.theme.myColors.coral : p.theme?.isDark ? p.theme.myColors.dark_grey_blue3 : p.theme.myColors.white)};
  width: 40px;
  height: 40px;
`;
