import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={14} height={16} viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M4.099 15.471a2.187 2.187 0 01-2.2-2.12c-.235-2.135-.627-7.183-.634-7.234a.593.593 0 01.144-.417.53.53 0 01.392-.175h10.4a.543.543 0 01.392.175.559.559 0 01.136.419c0 .051-.4 5.106-.628 7.234a2.188 2.188 0 01-2.247 2.12 143.75 143.75 0 01-5.755-.002zM.785 4.318a.547.547 0 01-.535-.55v-.285a.543.543 0 01.535-.551h2.187a.961.961 0 00.931-.761l.114-.512A1.492 1.492 0 015.45.5h3.1a1.491 1.491 0 011.425 1.123l.122.547a.96.96 0 00.931.762h2.187a.543.543 0 01.535.551v.285a.547.547 0 01-.535.551L.785 4.318z"
        fill="#F53F5F"
      />
    </Svg>
  );
}

export default SvgComponent;
