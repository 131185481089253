import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={20} height={18} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.027 2.12a4.022 4.022 0 00-1.72-.37H6.3c-2.646 0-4.55 2.525-4.55 5.01 0 1.208.475 2.407 1.254 3.554.779 1.146 1.833 2.2 2.916 3.1 1.08.9 2.169 1.631 2.997 2.138a20.233 20.233 0 001.083.62 20.233 20.233 0 001.083-.62 25.819 25.819 0 002.997-2.137c1.083-.902 2.137-1.955 2.916-3.101.779-1.147 1.254-2.346 1.254-3.554 0-2.485-1.904-5.01-4.55-5.01h-.006a4.022 4.022 0 00-3.119 1.441.75.75 0 01-1.15 0 4.022 4.022 0 00-1.398-1.07zM6.297.25A5.522 5.522 0 0110 1.637 5.52 5.52 0 0113.703.25c3.692.002 6.047 3.435 6.047 6.51 0 1.607-.63 3.096-1.513 4.396-.884 1.302-2.05 2.457-3.197 3.412a27.316 27.316 0 01-3.175 2.264c-.438.267-.809.476-1.08.62-.134.072-.248.13-.334.171a2.6 2.6 0 01-.199.085.77.77 0 01-.435.02.838.838 0 01-.145-.049 2.66 2.66 0 01-.123-.055 8.85 8.85 0 01-.335-.171c-.27-.145-.641-.354-1.079-.621a27.318 27.318 0 01-3.175-2.264c-1.147-.955-2.313-2.11-3.197-3.412C.88 9.856.25 8.366.25 6.76.25 3.685 2.605.252 6.297.25z"
        fill="#F53F5F"
      />
    </Svg>
  );
}

export default SvgComponent;
