import { useCallback, useEffect, useState } from 'react';

// Hook
export default function useAsync(asyncFunction, immediate = true) {
  const [status, setStatus] = useState('idle');
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);
  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(
    (param) => {
      setStatus('pending');
      setValue(null);
      setError(null);

      return (
        asyncFunction(param)
          // eslint-disable-next-line promise/always-return
          .then((response) => {
            setValue(response);
            setStatus('success');
          })
          .catch((e) => {
            setError(e);
            setStatus('error');
          })
      );
    },
    [asyncFunction]
  );
  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [immediate]);
  return { execute, status, response: value, error };
}
