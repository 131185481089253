import * as React from 'react';
import Svg, { G, Path, Defs, ClipPath } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={40} height={38} viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath="url(#prefix__clip0)">
        <Path
          d="M23.145 11.545a7.519 7.519 0 016.078-3.436c3.601-.008 6.093 3.495 6.105 7.58.013 4.731-3.354 10.34-12.132 15.226-8.8-4.842-12.2-10.43-12.21-15.163-.01-4.087 2.464-7.603 6.066-7.613a7.521 7.521 0 016.093 3.406z"
          fill="#F53F5F"
        />
        <Path
          d="M14.596 19.035a5.918 5.918 0 014.784-2.704c2.835-.006 4.795 2.751 4.805 5.967.01 3.723-2.642 8.135-9.549 11.982-6.922-3.815-9.6-8.209-9.611-11.932-.005-3.22 1.938-5.983 4.774-5.991a5.919 5.919 0 014.797 2.678z"
          fill="#F53F5F"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.788 17.357c-2.052.015-3.767 2.07-3.763 4.99.009 3.127 2.216 7.128 8.608 10.785 6.378-3.686 8.56-7.704 8.552-10.831M9.788 17.357a4.919 4.919 0 013.97 2.225l.843 1.288.835-1.292a4.919 4.919 0 013.96-2.247c2.052.006 3.78 2.056 3.789 4.97m-19.16.049c-.006-3.516 2.158-6.983 5.772-6.993H9.826a6.92 6.92 0 014.766 2.083 6.92 6.92 0 014.756-2.108h.03c3.613-.008 5.796 3.452 5.807 6.963.011 4.24-3 8.925-10.062 12.858l-.484.27-.486-.267c-7.077-3.9-10.116-8.564-10.128-12.805v-.001z"
          fill="#fff"
        />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" d="M0 0h39.398v37.363H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
