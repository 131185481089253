import apiService from '../services/apiService';

const chatApi = {
  async sendMsg(userId, body) {
    return apiService.post(`messaging/send/${userId}`, body);
  },

  async getChatHistory(roomId, queryParams = '') {
    return apiService.get(`messaging/${roomId}${queryParams}`);
  },
  async getRoomsList() {
    return apiService.get(`messaging/list`);
  },
  async getRoomIdByUserId(userId) {
    return apiService.get(`messaging/get-roomid/${userId}`);
  },
  async setTypingStatus(roomId) {
    return apiService.post(`messaging/typing-status/${roomId}`);
  },
  async muteChat(roomId) {
    return apiService.post(`messaging/mute-chat/${roomId}`);
  },
  async unmuteChat(roomId) {
    return apiService.post(`messaging/un-mute-chat/${roomId}`);
  },
  async deleteChat(roomId) {
    return apiService.delete(`messaging/${roomId}`);
  },
};

export default chatApi;
