import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M26.321 28.812l-2.827-3.245a1.444 1.444 0 010-2.032 1.315 1.315 0 011.858 0l3.406 2.749h.06a1.8 1.8 0 010 2.522 1.755 1.755 0 01-2.5 0l.003.006zM2.667 14.227a11.5 11.5 0 0111.437-11.56 11.374 11.374 0 018.087 3.386 11.621 11.621 0 013.349 8.173 11.5 11.5 0 01-11.436 11.562A11.5 11.5 0 012.667 14.227z"
        fill="#6C6CFF"
      />
    </Svg>
  );
}

export default SvgComponent;
