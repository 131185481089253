import apiService from '../services/apiService';

const subscriptionApi = {
  async getPlansList() {
    return apiService.get('plans');
  },
  async checkout(plan_id) {
    return apiService.post('payment/checkout', { plan_id });
  },
};

export default subscriptionApi;
