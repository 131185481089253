import * as React from 'react';
import Svg, { Defs, LinearGradient, Stop, Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={62.098} height={62.098} viewBox="0 0 62.098 62.098" {...props}>
      <Defs>
        <LinearGradient id="prefix__a" x1={0.567} x2={0.011} y1={0.22} y2={0.757} gradientUnits="objectBoundingBox">
          <Stop offset={0} stopColor="#fff" />
          <Stop offset={1} stopColor="#fff" stopOpacity={0} />
        </LinearGradient>
      </Defs>
      <Path
        fill="url(#prefix__a)"
        d="M33.5 38.027H8.752A8.721 8.721 0 010 29.357V23.7a1.579 1.579 0 011.584-1.571 3.136 3.136 0 003.149-3.116 2.98 2.98 0 00-3.149-2.934A1.581 1.581 0 010 14.511V8.672A8.721 8.721 0 018.754 0H33.5a8.722 8.722 0 018.75 8.672v5.657a1.574 1.574 0 01-1.582 1.571 3.116 3.116 0 100 6.233 1.579 1.579 0 011.584 1.567v5.655a8.72 8.72 0 01-8.752 8.672zM21.126 23.809l3.074 1.6a1.587 1.587 0 00.738.186 1.565 1.565 0 001.534-1.814l-.589-3.4 2.491-2.4a1.52 1.52 0 00.4-1.591 1.539 1.539 0 00-1.259-1.048l-3.441-.5-1.54-3.09a1.57 1.57 0 00-2.8 0l-1.54 3.092-3.434.5a1.554 1.554 0 00-1.268 1.05 1.528 1.528 0 00.4 1.591l2.491 2.4-.588 3.4A1.536 1.536 0 0016.4 25.3a1.566 1.566 0 001.641.116l3.079-1.6z"
        transform="rotate(-14.98 71.746 -12.678)"
      />
    </Svg>
  );
}

export default SvgComponent;
