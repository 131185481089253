import { Actionsheet, Spinner } from 'native-base';
import React from 'react';
import i18n from 'i18n-js';
import { Alert, View } from 'react-native';
import styled from 'styled-components/native';
import MYButton from '../../ui/MYButton';

import IconLogout from '../../svg/IconLogout2';
import { useDispatch } from 'react-redux';
import { doLogOut } from '../../../redux/slices/UserSlice';

export default function ActionSheetLogOut({ userId, onClose }) {
  const dispatch = useDispatch();

  function handleSubmit(reasonStr) {
    dispatch(doLogOut());
  }

  return (
    <Actionsheet.Content>
      <Container>
        <View>
          <Icon />
          <ReportTitle>{i18n.t('settings.actionsheet_logout_title')}</ReportTitle>
        </View>

        <MYButton color="red" style={{ width: '100%', marginTop: 20 }} onPress={handleSubmit}>
          {i18n.t('settings.actionsheet_btn_logout')}
        </MYButton>
        <MYButton color="grayOutline" style={{ width: '100%', marginTop: 20 }} onPress={onClose}>
          {i18n.t('userprofile.actionsheet_btn_cancel')}
        </MYButton>
      </Container>
    </Actionsheet.Content>
  );
}

const Container = styled.View`
  padding: 16px 25px 20px;
  width: 100%;
`;
const ReportTitle = styled.Text`
  font-family: Gilroy-SemiBold;
  font-size: 16px;
  /* color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dusk)}; */
  color: ${(p) => p.theme.myColors.coral};
  margin-top: 16px;
  text-align: center;
`;
const Icon = styled(IconLogout)`
  margin: 0 auto;
`;
