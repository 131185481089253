import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" color="#000" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.745 13a1 1 0 11.013 2 1 1 0 01-.013-2zm.003.5l-.004-.5h.003l.002.5h-.001zm.503.5h.5v.003l-.5-.002V14zm-1 0v-.003V14zm.505.5h-.003.003zM9.75 4.5a.75.75 0 01.75.75v5a.75.75 0 01-1.5 0v-5a.75.75 0 01.75-.75z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.777.805A2.75 2.75 0 016.72 0h6.058c.73 0 1.428.29 1.944.805l3.972 3.972A2.75 2.75 0 0119.5 6.72v6.058c0 .73-.29 1.428-.805 1.944l-3.972 3.972a2.75 2.75 0 01-1.944.805H6.72a2.75 2.75 0 01-1.944-.805L.805 14.723A2.75 2.75 0 010 12.78V6.72c0-.73.29-1.429.805-1.944L4.777.805zM6.72 1.5c-.331 0-.65.132-.884.366l-3.97 3.971a1.25 1.25 0 00-.367.884v6.058c0 .331.132.65.366.884l3.971 3.97c.235.235.553.367.884.367h6.058c.331 0 .65-.132.884-.366l3.97-3.971a1.25 1.25 0 00.367-.884V6.72c0-.331-.132-.65-.366-.884l-3.971-3.97a1.25 1.25 0 00-.884-.367H6.72z"
        fill="currentColor"
      />
    </Svg>
  );
}

export default SvgComponent;
