import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.055 1.055A2.75 2.75 0 013 .25h14A2.75 2.75 0 0119.75 3v12A2.75 2.75 0 0117 17.75h-4.69l-1.78 1.78a.75.75 0 01-1.06 0l-1.78-1.78H3A2.75 2.75 0 01.25 15V3c0-.73.29-1.429.805-1.945zM3 1.75A1.25 1.25 0 001.75 3v12A1.25 1.25 0 003 16.25h5a.75.75 0 01.53.22L10 17.94l1.47-1.47a.75.75 0 01.53-.22h5A1.25 1.25 0 0018.25 15V3A1.25 1.25 0 0017 1.75H3z"
        fill="#49497A"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.891 8.242v4h1.001v-4H5.891zm1.192 4.198v-.198h.01l-.002.198h-.008zm0 .034v.518l.008-.518h-.008zm-.004 1.268H5.7a1.309 1.309 0 01-1.309-1.309V8.051A1.309 1.309 0 015.7 6.742h1.395a1.32 1.32 0 011.297 1.366v4.366l-.001.046a1.302 1.302 0 01-1.312 1.222z"
        fill="#6C6CFF"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.697 5.75a.553.553 0 00-.46.246L8.96 7.912a1.25 1.25 0 00-.21.694v2.687a.957.957 0 00.957.957h3.539a.25.25 0 00.24-.181l.662-2.318a.784.784 0 00-.754-1.001H12.5c-.69 0-1.25-.56-1.25-1.25V6.303a.553.553 0 00-.553-.553zM8.99 5.164a2.053 2.053 0 013.761 1.139v.947h.644a2.285 2.285 0 012.197 2.913l-.663 2.318a1.75 1.75 0 01-1.682 1.269H9.707a2.457 2.457 0 01-2.457-2.457V8.606c0-.543.16-1.074.462-1.526l1.277-1.916z"
        fill="#6C6CFF"
      />
    </Svg>
  );
}

export default SvgComponent;
