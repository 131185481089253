import i18n from 'i18n-js';
import { useToast } from 'native-base';
import React, { useEffect } from 'react';
import { Alert, TouchableOpacity, Platform } from 'react-native';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';

import evaluationApi from '../../../apis/evaluation';
import useAsync from '../../../hooks/useAsync';
import { isDateBeforeToday } from '../../../utils/dateHelper';
import HeaderWithMoreArrow from '../../common/HeaderWithMoreArrow';
import { useTheme } from '../../template/ThemeProvider';

export default function PeopleLikedYou({ navigation, doRefresh }) {
  const { colors, isDark, setScheme } = useTheme();
  const userReduxState = useSelector((state) => state.user);
  const myGender = userReduxState?.userInfo?.genderEnumId === 1 ? 1 : 2;
  const toast = useToast();

  const subscriptionExp = userReduxState?.userInfo?.subscriptionExp;
  const isHaveActiveSubscribe = !!(subscriptionExp && !isDateBeforeToday(new Date(subscriptionExp)));

  const { execute, status, response, error } = useAsync(() => {
    return evaluationApi.getWhoLikedMe();
  }, false);

  useEffect(() => {
    execute();
  }, [doRefresh]);

  const RenderNoUserImage = () => (
    <ImageItem
      source={myGender === 1 ? require('../../../assets/images/defaultavatar2.png') : require('../../../assets/images/defaultavatar1.png')}
      blurRadius={isHaveActiveSubscribe ? 0 : 90}
    />
  );

  const RenderEmpty = () => (
    <ImageItem
      source={
        myGender === 1 ? require('../../../assets/images/defaultavatar2_deactive.png') : require('../../../assets/images/defaultavatar1_deactive.png')
      }
      blurRadius={90}
      style={{ opacity: 0.4 }}
    />
  );

  function alert(title = undefined, msg) {
    if (Platform.OS === 'web') {
      toast.show({ title: title, status: 'info', description: msg });
    } else {
      Alert.alert(title, msg, [{ text: 'ok', onPress: () => null, style: 'cancel' }]);
    }
  }

  const renderLoadingSkeletons = (
    <ItemsContainer>
      <TouchableOpacity>
        <ContentPlaceHolder />
      </TouchableOpacity>
      <TouchableOpacity>
        <ContentPlaceHolder />
      </TouchableOpacity>
      <TouchableOpacity>
        <ContentPlaceHolder />
      </TouchableOpacity>
      <TouchableOpacity>
        <ContentPlaceHolder />
      </TouchableOpacity>
      <TouchableOpacity>
        <ContentPlaceHolder />
      </TouchableOpacity>
      <TouchableOpacity>
        <ContentPlaceHolder />
      </TouchableOpacity>
      <TouchableOpacity>
        <ContentPlaceHolder />
      </TouchableOpacity>
    </ItemsContainer>
  );

  const renderEmpty = (
    <ItemsContainer>
      <RenderEmpty />
      <RenderEmpty />
      <RenderEmpty />
      <RenderEmpty />
      <RenderEmpty />
      <RenderEmpty />
      <RenderEmpty />
    </ItemsContainer>
  );

  function handleNeedSubscription() {
    alert(null, i18n.t('global.you_need_subscription_to_able_view_profile'));
  }

  return (
    <Container>
      <HeaderWithMoreArrow
        titleBold={i18n.t('discover.people_who_liked_you_people')}
        title={i18n.t('discover.people_who_liked_you_who')}
        onPressMore={() => (!isHaveActiveSubscribe ? handleNeedSubscription() : navigation.navigate('LikedYouScreen'))}
      />

      <ScrollView horizontal>
        <ItemsContainer>
          {status === 'pending' && renderLoadingSkeletons}

          {response?.data?.results?.length > 0
            ? response?.data?.results?.map((item, index) => (
                <TouchableOpacity
                  key={index}
                  onPress={() =>
                    !isHaveActiveSubscribe
                      ? handleNeedSubscription()
                      : navigation.navigate('SingleUserProfileScreen', { userData: item?.userDetails?.[0] })
                  }>
                  {item?.userDetails?.[0]?.avatar ? (
                    <ImageItem source={{ uri: item?.userDetails?.[0]?.avatar }} blurRadius={isHaveActiveSubscribe ? 0 : 90} />
                  ) : (
                    <RenderNoUserImage />
                  )}
                </TouchableOpacity>
              ))
            : status !== 'pending' && renderEmpty}
        </ItemsContainer>
      </ScrollView>
    </Container>
  );
}

const Container = styled.View`
  position: relative;
  padding-bottom: 26px;
`;

const ScrollView = styled.ScrollView``;
const ItemsContainer = styled.View`
  display: flex;
  flex-direction: row;
`;

const ImageItem = styled.Image`
  width: 85px;
  height: 85px;
  border-radius: 12px;
  margin-right: 5px;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark_grey_blue : p.theme.myColors.pale_grey2)};
`;

const ContentPlaceHolder = styled.View`
  width: 85px;
  height: 85px;
  border-radius: 12px;
  margin-right: 5px;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dusk : '#E1E9EE')};
`;
