import { Picker } from '@react-native-picker/picker';
import i18n from 'i18n-js';
import { useToast } from 'native-base';
// import DatePicker from 'react-native-date-picker';
import React, { useState, useEffect } from 'react';
import { BackHandler } from 'react-native';
import styled from 'styled-components/native';

import HeaderWithIcons from '../../components/common/HeaderWithIcons';
import IconDanger1 from '../../components/svg/IconDanger1';
import { useTheme } from '../../components/template/ThemeProvider';
import MYButton from '../../components/ui/MYButton';
import Myinput from '../../components/ui/MYInput';
import calculateAgeFromDate from '../../utils/calculateAgeFromDate';

const currentYear = new Date().getFullYear();
const calcYearsRange = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const daysList = [...Array(31).keys()];
const yearsList = calcYearsRange(currentYear - 18, currentYear - 70, -1);

export default function SelectAge({ route, navigation }) {
  const { colors, isDark, setScheme } = useTheme();
  const toast = useToast();
  const { email, gender, name, access_token } = route.params || {};

  const [loadingBtn, setloadingBtn] = useState(false);
  const [selectedYear, setSelectedYear] = useState(currentYear - 18);
  const [selectedMonth, setSelectedMonth] = useState(1);
  const [selectedDay, setSelectedDay] = useState(1);

  useEffect(() => {
    const backAction = () => {
      navigation.goBack();
      return true;
    };

    const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

    return () => backHandler.remove();
  }, []);

  function handlePressNext() {
    if (!selectedYear || !selectedMonth || !selectedDay) {
      // toast
      // toast.show({
      //   description: 'please enter your email',
      // });
      return;
    }

    const birthday = `${selectedYear}-${selectedMonth <= 9 ? '0' + selectedMonth : selectedMonth}-${
      selectedDay <= 9 ? '0' + selectedDay : selectedDay
    }`;

    if (calculateAgeFromDate(birthday) < 18) {
      toast.show({
        description: 'age must not under 18',
      });
      return;
    }

    navigation.navigate('Auth_SelectCountry', {
      email,
      gender,
      access_token,
      name,
      birthday,
    });
  }

  return (
    <Container>
      <HeaderWithIcons title={i18n.t('auth.signup_header_title')} onPress={() => navigation.goBack()} />

      <SelectContainer>
        <Title>{i18n.t('auth.your_brithday')}</Title>
        <SubTitleBox>
          <IconDanger1 />
          <SubTitle>{i18n.t('auth.you_should_be_over_18_years_old')}</SubTitle>
        </SubTitleBox>

        <InputsBox>
          <Picker style={{ flex: 1, padding: 10 }} selectedValue={selectedDay} onValueChange={(itemValue, itemIndex) => setSelectedDay(itemValue)}>
            {daysList.map((i, index) => (
              <Picker.Item
                key={index}
                label={`${i + 1}`}
                value={i + 1}
                color={isDark ? colors?.white : colors.slate_blue}
                fontFamily="Gilroy-Medium"
              />
            ))}
          </Picker>
          <Picker style={{ flex: 1 }} selectedValue={selectedMonth} onValueChange={(itemValue, itemIndex) => setSelectedMonth(itemValue)}>
            {monthList?.map((item, index) => (
              <Picker.Item key={index} label={item} value={index + 1} color={isDark ? colors?.white : colors.slate_blue} fontFamily="Gilroy-Medium" />
            ))}
          </Picker>
          <Picker style={{ flex: 1 }} selectedValue={selectedYear} onValueChange={(itemValue, itemIndex) => setSelectedYear(itemValue)}>
            {yearsList.map((i, index) => (
              <Picker.Item key={index} label={`${i}`} value={`${i}`} color={isDark ? colors?.white : colors.slate_blue} fontFamily="Gilroy-Medium" />
            ))}
          </Picker>
        </InputsBox>
      </SelectContainer>

      <ButtonsContainer>
        <MYButton color="blue" style={{ width: '100%' }} onPress={handlePressNext} loading={loadingBtn}>
          {i18n.t('auth.continue')}
        </MYButton>
      </ButtonsContainer>
    </Container>
  );
}

const Container = styled.View`
  display: flex;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
  flex: 1;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
`;

const ButtonsContainer = styled.View`
  padding: 0 34px;
  width: 100%;
  margin-bottom: 28px;
  flex-shrink: 0;
`;

const SelectContainer = styled.View`
  margin-top: 7%;
  flex: 1;
  width: 100%;
  padding: 0 35px;
`;

const Title = styled.Text`
  font-family: Gilroy-Bold;
  margin-bottom: 31px;
  font-size: 22px;
  text-align: center;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dusk)};
`;

const SubTitleBox = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 74px;
`;

const SubTitle = styled.Text`
  font-family: Gilroy-Medium;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: ${(p) => p.theme.myColors.watermelon};
  margin-left: 5px;
`;

const InputsBox = styled.View`
  display: flex;
  flex-direction: row;
`;
