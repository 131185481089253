import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 .25a.75.75 0 01.75.75v4.47a.75.75 0 01-1.5 0V1A.75.75 0 0111 .25zM.3 15.06a.75.75 0 01.75-.75h7.7a.75.75 0 010 1.5h-7.7a.75.75 0 01-.75-.75z"
        fill="#49497A"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.9 7a.75.75 0 01.66.395l3.644 6.774c.3.543.405 1.174.295 1.785l-.002.013a4.69 4.69 0 01-9.2 0l-.003-.012a2.704 2.704 0 01.325-1.837l3.62-6.724A.75.75 0 014.9 7zm0 2.333l-2.99 5.55a.747.747 0 01-.03.052c-.116.23-.155.493-.11.749a3.19 3.19 0 006.254 0c.047-.272 0-.551-.134-.792l-.005-.009L4.9 9.333z"
        fill="#49497A"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.206 6.722a18.091 18.091 0 0112.03-1.96.75.75 0 11-.272 1.476A16.59 16.59 0 005.933 8.034l-.668.371a.75.75 0 11-.73-1.31l.671-.373z"
        fill="#49497A"
      />
      <Path fillRule="evenodd" clipRule="evenodd" d="M12.5 12.81a.75.75 0 01.75-.75h7.7a.75.75 0 010 1.5h-7.7a.75.75 0 01-.75-.75z" fill="#6C6CFF" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1 4.75a.75.75 0 01.66.394l3.65 6.774c.3.544.404 1.175.294 1.787l-.002.012a4.688 4.688 0 01-9.2 0l-.002-.012a2.704 2.704 0 01.261-1.724.741.741 0 01.029-.059l3.65-6.778a.75.75 0 01.66-.394zm-2.96 7.827a.73.73 0 01-.031.065c-.134.241-.181.52-.134.792a3.188 3.188 0 006.254 0c.047-.271 0-.551-.134-.792l-.004-.008-2.99-5.552-2.96 5.495z"
        fill="#6C6CFF"
      />
    </Svg>
  );
}

export default SvgComponent;
