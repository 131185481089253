import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={22} height={22} viewBox="0 0 22 22" color="#23282D" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 6.75a4.25 4.25 0 100 8.5 4.25 4.25 0 000-8.5zm4.065.184a5.75 5.75 0 10-8.131 8.131 5.75 5.75 0 008.131-8.131zM11 .25a.75.75 0 01.75.75v2a.75.75 0 01-1.5 0V1A.75.75 0 0111 .25zM11 18.25a.75.75 0 01.75.75v2a.75.75 0 01-1.5 0v-2a.75.75 0 01.75-.75zM18.6 3.4a.75.75 0 010 1.06l-.71.71a.75.75 0 11-1.06-1.06l.71-.71a.75.75 0 011.06 0zM5.17 16.83a.75.75 0 010 1.06l-.71.71a.75.75 0 01-1.06-1.06l.71-.71a.75.75 0 011.06 0zM18.25 11a.75.75 0 01.75-.75h2a.75.75 0 010 1.5h-2a.75.75 0 01-.75-.75zM.25 11a.75.75 0 01.75-.75h2a.75.75 0 010 1.5H1A.75.75 0 01.25 11zM16.83 16.83a.75.75 0 011.06 0l.71.71a.75.75 0 11-1.06 1.06l-.71-.71a.75.75 0 010-1.06zM3.4 3.4a.75.75 0 011.06 0l.71.71a.75.75 0 11-1.06 1.06l-.71-.71a.75.75 0 010-1.06z"
        fill="currentColor"
      />
    </Svg>
  );
}

export default SvgComponent;
