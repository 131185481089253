import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.25 5a.75.75 0 01.75-.75h3a.75.75 0 010 1.5h-3a.75.75 0 01-.75-.75zM2.25 5A.75.75 0 013 4.25h11a.75.75 0 010 1.5H3A.75.75 0 012.25 5zM9.25 12a.75.75 0 01.75-.75h11a.75.75 0 010 1.5H10a.75.75 0 01-.75-.75zM2.25 12a.75.75 0 01.75-.75h3a.75.75 0 010 1.5H3a.75.75 0 01-.75-.75zM17.25 19a.75.75 0 01.75-.75h3a.75.75 0 010 1.5h-3a.75.75 0 01-.75-.75zM2.25 19a.75.75 0 01.75-.75h11a.75.75 0 010 1.5H3a.75.75 0 01-.75-.75z"
        fill="#67679B"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 3.75a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm1.943-.695a2.75 2.75 0 10-3.888 3.89 2.75 2.75 0 003.89-3.89h-.002zM8 10.75a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm1.943-.695a2.75 2.75 0 10-3.888 3.89 2.75 2.75 0 003.89-3.89h-.002zM16 17.75a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm1.943-.695a2.75 2.75 0 10-3.888 3.89 2.75 2.75 0 003.89-3.89h-.002z"
        fill="#67679B"
      />
    </Svg>
  );
}

export default SvgComponent;
