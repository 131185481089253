import i18n from 'i18n-js';
import moment from 'moment';
import { Actionsheet, useDisclose } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Alert, FlatList, Platform, RefreshControl } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';

import BrandHeader from '../../components/common/BrandHeader';
import ActionSheetDeleteChat from '../../components/pages/chat/ActionSheetDeleteChat';
import ListItem from '../../components/pages/chat/ListItem';
import EmptyChatsDark from '../../components/svg/EmptyChatsDark';
import EmptyChatsLight from '../../components/svg/EmptyChatsLight';
import { useTheme } from '../../components/template/ThemeProvider';
import { fetchMessagesList } from '../../redux/slices/MessagesSlice';

export default function MessagesHomeScreen({ navigation }) {
  const { colors, isDark, setScheme } = useTheme();
  const messagesReduxState = useSelector((state) => state.messages);
  const dispatch = useDispatch();
  const userReduxState = useSelector((state) => state.user);
  const { isOpen, onOpen, onClose } = useDisclose();
  const [selectedForDelete, setselectedForDelete] = useState(null);

  function refreshMessages() {
    dispatch(fetchMessagesList());
  }

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      refreshMessages();
    });

    return unsubscribe;
  }, [navigation]);

  return (
    <ScrollView refreshControl={<RefreshControl refreshing={messagesReduxState?.loading} onRefresh={() => refreshMessages()} />}>
      <BrandHeader />

      {messagesReduxState?.data?.length > 0 ? (
        <FlatList
          data={messagesReduxState?.data}
          // scrollEnabled={false}
          renderItem={({ item }) => {
            const user = item.userDetails?.filter((a) => a._id !== userReduxState?.userInfo?._id)?.[0];
            // const lastMessageSenderIsMe = item?.lastMessageSender === userReduxState?.userInfo?._id;
            const iscurrentDate = moment().diff(moment(item?.updatedAt), 'days');
            const unreadCounts = item?.unreadCounts?.find((a) => a._id === userReduxState?.userInfo?._id);
            const unSeenCount = item?.unreadCounts?.find((a) => a._id !== userReduxState?.userInfo?._id);

            return (
              <ListItem
                title={user?.name}
                subTitle={item.lastMessage?.length > 85 ? item.lastMessage?.substring(0, 85) + '...' : item.lastMessage}
                icon={item?.icon}
                navigation={navigation}
                userId={user?._id}
                roomId={item?._id}
                userName={user?.name}
                userImagesUrls={user?.imagesUrls}
                avatar={user?.avatar}
                unreadCount={unreadCounts?.count}
                unSeenCount={unSeenCount?.count}
                time={iscurrentDate === 0 ? moment(item?.updatedAt).format('hh:mm') : moment(item?.updatedAt).format('DD MMM, hh:mm')}
                doRefresh={() => {
                  refreshMessages();
                }}
                muteds={item?.muteds}
                blocks={item?.blocks}
                myId={userReduxState?.userInfo?._id}
                userData={user}
                openDeleteActionSheet={() => {
                  setselectedForDelete(item?._id);
                  setTimeout(() => {
                    onOpen();
                  }, 350);
                }}
              />
            );
          }}
          keyExtractor={(item, index) => index}
          ItemSeparatorComponent={({ highlighted }) => <MenuItemSeperator />}
        />
      ) : (
        !messagesReduxState?.loading && (
          <ImageContainer>
            {isDark ? <EmptyChatsDark /> : <EmptyChatsLight />}
            <NoChatsText>{i18n.t('chat.no_conversations_yet')}</NoChatsText>
          </ImageContainer>
        )
      )}

      <Actionsheet isOpen={isOpen} onClose={onClose}>
        <ActionSheetDeleteChat
          roomId={selectedForDelete}
          onClose={() => {
            onClose();
            setselectedForDelete(null);
            refreshMessages();
          }}
        />
      </Actionsheet>
    </ScrollView>
  );
}

const ScrollView = styled.ScrollView`
  display: flex;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
  flex: 1;
`;

const MenuItemSeperator = styled.View`
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey2)};
  height: 1px;
  margin-left: 20px;
`;

const ImageContainer = styled.View`
  position: relative;
  width: 100%;
  height: ${Platform.OS === 'web' ? '52px' : 'auto'};
  flex-shrink: 1;
  min-height: 40px;
  padding: 0 40px;
  align-items: center;
  margin-top: 80px;

  /* padding: 25px 0; */
  /* height: 40%; */
`;
const NoChatsText = styled.Text`
  font-family: Gilroy-Medium;
  text-align: center;
  margin-top: 40px;
  font-size: 16px;
  color: ${(p) => p.theme.myColors.bluey_grey};
`;
