import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { useSelector } from 'react-redux';

import { ModalSelectCity } from '../components/common/ModalSelectCity';
import ForgotPassword from '../screens/auth/ForgotPassword';
import ForgotPasswordSetNew from '../screens/auth/ForgotPasswordSetNew';
import LoginScreen from '../screens/auth/LoginScreen';
import SelectAge from '../screens/auth/SelectAge';
import SelectCountry from '../screens/auth/SelectCountry';
import SelectGenderScreen from '../screens/auth/SelectGenderScreen';
import SelectName from '../screens/auth/SelectName';
import SetAvatar from '../screens/auth/SetAvatar';
import SetPasswordScreen from '../screens/auth/SetPasswordScreen';
import SingupScreen from '../screens/auth/SingupScreen';
import Verification from '../screens/auth/Verification';
import WelcomeScreen from '../screens/auth/WelcomeScreen';
import LikedYouScreen from '../screens/discover/LikedYouScreen';
import MatchesScreen from '../screens/discover/MatchesScreen';
import YourLikesScreen from '../screens/discover/YourLikesScreen';
import ChatScreen from '../screens/messages/ChatScreen';
import BlockedUsersScreen from '../screens/myprofile/BlockedUsersScreen';
import ChangePasswordScreen from '../screens/myprofile/ChangePasswordScreen';
import EditMyProfileScreen from '../screens/myprofile/EditMyProfileScreen';
import PaymentCallbackScreen from '../screens/myprofile/PaymentCallbackScreen';
import PaymentScreen from '../screens/myprofile/PaymentScreen';
import SettingsScreen from '../screens/myprofile/SettingsScreen';
import SubscriptionHomeScreen from '../screens/myprofile/SubscriptionHomeScreen';
import SingleUserProfileScreen from '../screens/userProfile/SingleUserProfileScreen';
import SwipableCardsScreen from '../screens/userProfile/SwipableCardsScreen';
import UserProfileGalleryScreen from '../screens/userProfile/UserProfileGalleryScreen';
import BottomTabNavigator from './BottomTabNavigator';
// import LinkingConfiguration from "./LinkingConfiguration";

const Stack = createStackNavigator();

export default function Navigation({ colorScheme, authtoken = false }) {
  const userReduxState = useSelector((state) => state.user);
  const isSignedIn = userReduxState?.isSignedIn || false;

  return (
    <NavigationContainer
    // linking={LinkingConfiguration}
    // theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}
    >
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        {isSignedIn ? (
          <>
            <Stack.Group>
              <Stack.Screen name="Root" component={BottomTabNavigator} />
              <Stack.Screen name="Settings" component={SettingsScreen} />
              <Stack.Screen name="EditMyProfile" component={EditMyProfileScreen} />
              <Stack.Screen name="ChangePassword" component={ChangePasswordScreen} />
              <Stack.Screen name="SubscriptionHomeScreen" component={SubscriptionHomeScreen} />
              <Stack.Screen name="PaymentScreen" component={PaymentScreen} />
              <Stack.Screen name="PaymentCallbackScreen" component={PaymentCallbackScreen} />
              <Stack.Screen name="SwipableCardsScreen" component={SwipableCardsScreen} />
              <Stack.Screen name="SingleUserProfileScreen" component={SingleUserProfileScreen} />
              <Stack.Screen name="ChatScreen" component={ChatScreen} />
              <Stack.Screen name="UserProfileGalleryScreen" component={UserProfileGalleryScreen} />
              <Stack.Screen name="BlockedUsersScreen" component={BlockedUsersScreen} />
              <Stack.Screen name="YourLikesScreen" component={YourLikesScreen} />
              <Stack.Screen name="LikedYouScreen" component={LikedYouScreen} />
              <Stack.Screen name="MatchesScreen" component={MatchesScreen} />
            </Stack.Group>

            <Stack.Group screenOptions={{ presentation: 'modal' }}>
              <Stack.Screen name="ModalSelectCity" component={ModalSelectCity} />
            </Stack.Group>
          </>
        ) : (
          <>
            <Stack.Group>
              <Stack.Screen name="Welcome" component={WelcomeScreen} />
              <Stack.Screen name="Auth_Login" component={LoginScreen} />
              <Stack.Screen name="Auth_Signup" component={SingupScreen} />
              <Stack.Screen name="Auth_Verification" component={Verification} />
              <Stack.Screen name="Auth_SetPassword" component={SetPasswordScreen} />
              <Stack.Screen name="Auth_SelectGender" component={SelectGenderScreen} />
              <Stack.Screen name="Auth_SelectName" component={SelectName} />
              <Stack.Screen name="Auth_SelectAge" component={SelectAge} />
              <Stack.Screen name="Auth_SelectCountry" component={SelectCountry} />
              <Stack.Screen name="Auth_SetAvatar" component={SetAvatar} />
              <Stack.Screen name="Auth_ForgotPassword" component={ForgotPassword} />
              <Stack.Screen name="Auth_ForgotPasswordSetNew" component={ForgotPasswordSetNew} />
            </Stack.Group>

            <Stack.Group screenOptions={{ presentation: 'modal' }}>
              <Stack.Screen name="ModalSelectCity" component={ModalSelectCity} />
            </Stack.Group>
          </>
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
}
