import i18n from 'i18n-js';
import { useToast } from 'native-base';
import React, { useEffect } from 'react';
import { Alert, TouchableOpacity, Platform } from 'react-native';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';

import evaluationApi from '../../../apis/evaluation';
import useAsync from '../../../hooks/useAsync';
import { isDateBeforeToday } from '../../../utils/dateHelper';
import HeaderWithMoreArrow from '../../common/HeaderWithMoreArrow';
import IconTwoLove from '../../svg/IconTwoLove';
import IconTwoLoveDark from '../../svg/IconTwoLoveDark';
import { useTheme } from '../../template/ThemeProvider';

export default function YoutMatches({ navigation, doRefresh }) {
  const { colors, isDark, setScheme } = useTheme();
  const userReduxState = useSelector((state) => state.user);
  const myGender = userReduxState?.userInfo?.genderEnumId === 1 ? 1 : 2;
  const myUserId = userReduxState?.userInfo?._id;
  const toast = useToast();

  const subscriptionExp = userReduxState?.userInfo?.subscriptionExp;
  const isHaveActiveSubscribe = !!(subscriptionExp && !isDateBeforeToday(new Date(subscriptionExp)));

  const { execute, status, response, error } = useAsync(() => {
    return evaluationApi.getMatchesList();
  }, false);

  useEffect(() => {
    execute();
  }, [doRefresh]);

  const RenderNoUserImage = () => (
    <ImageItem
      source={myGender === 1 ? require('../../../assets/images/defaultavatar2.png') : require('../../../assets/images/defaultavatar1.png')}
      blurRadius={isHaveActiveSubscribe ? 0 : 0}
    />
  );

  const RenderEmpty = () => (
    <ImageItem
      source={
        myGender === 1 ? require('../../../assets/images/defaultavatar2_deactive.png') : require('../../../assets/images/defaultavatar1_deactive.png')
      }
      blurRadius={90}
      style={{ opacity: 0.4 }}
    />
  );

  const renderLoadingSkeletons = (
    <ItemsContainer>
      <TouchableOpacity>
        <ContentPlaceHolder />
      </TouchableOpacity>
      <TouchableOpacity>
        <ContentPlaceHolder />
      </TouchableOpacity>
      <TouchableOpacity>
        <ContentPlaceHolder />
      </TouchableOpacity>
      <TouchableOpacity>
        <ContentPlaceHolder />
      </TouchableOpacity>
      <TouchableOpacity>
        <ContentPlaceHolder />
      </TouchableOpacity>
      <TouchableOpacity>
        <ContentPlaceHolder />
      </TouchableOpacity>
      <TouchableOpacity>
        <ContentPlaceHolder />
      </TouchableOpacity>
    </ItemsContainer>
  );

  const renderEmpty = (
    <ItemsContainer>
      <RenderEmpty />
      <RenderEmpty />
      <RenderEmpty />
      <RenderEmpty />
      <RenderEmpty />
      <RenderEmpty />
      <RenderEmpty />
    </ItemsContainer>
  );

  function alert(title = undefined, msg) {
    if (Platform.OS === 'web') {
      toast.show({ title: title, status: 'info', description: msg });
    } else {
      Alert.alert(title, msg, [{ text: 'ok', onPress: () => null, style: 'cancel' }]);
    }
  }

  function handleNeedSubscription() {
    alert(null, i18n.t('global.you_need_subscription_to_able_view_profile'));
  }

  return (
    <Container>
      <HeaderWithMoreArrow
        titleBold={i18n.t('discover.your_matches')}
        subTitle={i18n.t('discover.you_liked_each_other')}
        leftIcon={isDark ? <LeftIconDark /> : <LeftIcon />}
        onPressMore={() => (!isHaveActiveSubscribe ? handleNeedSubscription() : navigation.navigate('MatchesScreen'))}
      />

      <ScrollView horizontal>
        <ItemsContainer>
          {status === 'pending' && renderLoadingSkeletons}

          {response?.data?.results?.length > 0
            ? response?.data?.results?.map((item, index) => {
                const user = item?.userDetails?.filter((a) => a?._id !== myUserId)?.[0];
                return (
                  <TouchableOpacity
                    key={index}
                    onPress={() =>
                      !isHaveActiveSubscribe
                        ? handleNeedSubscription()
                        : navigation.navigate('SingleUserProfileScreen', { userData: user, isLiked: true })
                    }>
                    {user?.avatar ? <ImageItem source={{ uri: user?.avatar }} blurRadius={isHaveActiveSubscribe ? 0 : 90} /> : <RenderNoUserImage />}
                  </TouchableOpacity>
                );
              })
            : status !== 'pending' && renderEmpty}
        </ItemsContainer>
      </ScrollView>
    </Container>
  );
}

const Container = styled.View`
  position: relative;
  padding-bottom: 26px;
`;

const ScrollView = styled.ScrollView``;
const ItemsContainer = styled.View`
  display: flex;
  flex-direction: row;
`;

const ImageItem = styled.Image`
  width: 85px;
  height: 85px;
  border-radius: 12px;
  margin-right: 5px;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark_grey_blue : p.theme.myColors.pale_grey2)};
`;
const LeftIcon = styled(IconTwoLove)`
  width: 33px;
  height: 33px;
  margin-right: 11px;
`;
const LeftIconDark = styled(IconTwoLoveDark)`
  width: 33px;
  height: 33px;
  margin-right: 11px;
`;

const ContentPlaceHolder = styled.View`
  width: 85px;
  height: 85px;
  border-radius: 12px;
  margin-right: 5px;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dusk : '#E1E9EE')};
`;
