import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={32} height={32} viewBox="0 0 32 32" color="#C2C2E7" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.466 10.497a.75.75 0 01.186.754l-2.123 6.786a.75.75 0 01-.492.492l-6.786 2.123a.75.75 0 01-.94-.94l2.124-6.785a.75.75 0 01.492-.492l6.785-2.124a.75.75 0 01.754.186zm-6.717 3.252l-1.578 5.043 5.043-1.578 1.578-5.043-5.043 1.579z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.482 3.417c-6.663 0-12.065 5.402-12.065 12.065 0 6.663 5.402 12.065 12.065 12.065 6.663 0 12.065-5.402 12.065-12.065 0-6.663-5.402-12.065-12.065-12.065zM1.917 15.482c0-7.492 6.073-13.565 13.565-13.565 7.492 0 13.565 6.073 13.565 13.565 0 7.492-6.073 13.565-13.565 13.565-7.492 0-13.565-6.073-13.565-13.565z"
        fill="currentColor"
      />
    </Svg>
  );
}

export default SvgComponent;
