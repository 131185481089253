import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.447 16.294A3.706 3.706 0 005.741 20a.741.741 0 01-1.482 0 5.188 5.188 0 015.188-5.188h5.106A5.188 5.188 0 0119.741 20h-1.482a3.706 3.706 0 00-3.706-3.706H9.447zM12 4.75a3.5 3.5 0 100 7 3.5 3.5 0 000-7zm3.535-.035a5 5 0 10-7.07 7.071 5 5 0 007.07-7.071z"
        fill="currentColor"
      />
    </Svg>
  );
}

export default SvgComponent;
