import 'react-native-gesture-handler';
import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate as ReduxPersistGate } from 'redux-persist/integration/react';

import { ThemeProvider } from './components/template/ThemeProvider';
import general from './constants/general';
import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';
import { persistor, store } from './redux/store';

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <SafeAreaProvider>
        <ReduxProvider store={store}>
          <ReduxPersistGate loading={null} persistor={persistor}>
            <ThemeProvider colorScheme={colorScheme}>
              <Navigation />
              <StatusBar />
            </ThemeProvider>
          </ReduxPersistGate>
        </ReduxProvider>
      </SafeAreaProvider>
    );
  }
}
