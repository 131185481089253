import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={22} height={28} viewBox="0 0 22 28" color="#C2C2E7" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M9.233 27.285h-.379l-.192-.007a2.95 2.95 0 01-.538-.062 20.532 20.532 0 01-3.11-.451l-.341-.072c-2.064-.461-3.362-1.292-3.966-2.54a3.915 3.915 0 01.009-3.354c.623-1.25 2.1-2.148 4.272-2.6 1.028-.22 2.07-.368 3.119-.442a33.254 33.254 0 015.786 0l.614.052c.84.084 1.674.213 2.5.389 2.3.474 3.665 1.306 4.286 2.616a3.883 3.883 0 010 3.338c-.618 1.3-2.016 2.155-4.271 2.6l-.624.13a20.96 20.96 0 01-2.507.326c-.954.082-1.922.122-2.878.122a29.147 29.147 0 01-1.78-.045zm-.978-7.6a20.7 20.7 0 00-2.875.407c-1.51.311-2.608.893-2.94 1.558a1.89 1.89 0 00-.186.832c-.002.289.062.575.185.836.34.7 1.367 1.24 2.97 1.553l.47.1c.826.161 1.66.272 2.5.332.126.025.254.04.383.044l.524.008a32.094 32.094 0 004.457-.069c.97-.067 1.933-.207 2.882-.42l.3-.064c1.415-.32 2.324-.832 2.63-1.477a1.95 1.95 0 000-1.679c-.326-.688-1.344-1.226-2.945-1.556-.946-.2-1.904-.334-2.869-.4h-.017a31.2 31.2 0 00-2.717-.119c-.903 0-1.837.034-2.75.115l-.002-.001zM3.922 7.781a7.084 7.084 0 117.084 7.114 7.106 7.106 0 01-7.084-7.114zm1.922 0a5.161 5.161 0 105.161-5.183 5.177 5.177 0 00-5.16 5.183h-.001z"
        fill="currentColor"
      />
    </Svg>
  );
}

export default SvgComponent;
