import * as React from 'react';
import Svg, { G, Circle, Path } from 'react-native-svg';

const SvgComponent = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={42} height={42} {...props}>
    <G data-name="Group 12240" transform="translate(-742 -1581)">
      <Circle
        data-name="Ellipse 13272"
        cx={21}
        cy={21}
        r={21}
        transform="translate(742 1581)"
        style={{
          fill: '#221f3b',
        }}
      />
      <Circle
        data-name="Ellipse 13273"
        cx={18}
        cy={18}
        r={18}
        transform="translate(745 1584)"
        style={{
          fill: '#67679b',
        }}
      />
      <G data-name="Iconly/Bold/Camera">
        <Path
          data-name="Camera"
          d="M13.033 15h-9.4A3.635 3.635 0 0 1 0 11.371V6.426A3.635 3.635 0 0 1 3.633 2.8a.165.165 0 0 0 .159-.1l.049-.1.069-.146c.218-.459.464-.977.614-1.278A2.027 2.027 0 0 1 6.367 0h3.925a2.034 2.034 0 0 1 1.85 1.174c.131.262.33.683.523 1.09l.118.25.084.183a.188.188 0 0 0 .167.1 3.635 3.635 0 0 1 3.633 3.629v4.945A3.635 3.635 0 0 1 13.033 15zm-4.7-9.665a3.268 3.268 0 0 0-2.325.965 3.223 3.223 0 0 0-.95 2.305 3.27 3.27 0 0 0 3.275 3.272 3.246 3.246 0 0 0 2.308-.957 3.263 3.263 0 0 0 .008-4.628 3.254 3.254 0 0 0-2.316-.957zm4.675-.2a.753.753 0 1 0 .759.758.755.755 0 0 0-.759-.757zM8.333 10.63a2.019 2.019 0 0 1-2.025-2.023V8.6a1.947 1.947 0 0 1 .583-1.415 2.025 2.025 0 1 1 1.442 3.445z"
          style={{
            fill: '#fff',
          }}
          transform="translate(754.667 1594.404)"
        />
      </G>
    </G>
  </Svg>
);

export default SvgComponent;
