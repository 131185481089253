import * as React from 'react';
import Svg, { Defs, LinearGradient, Stop, Path } from 'react-native-svg';

const SvgComponent = (props) => (
  <Svg data-name="Heart, Favorite.1" xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <Defs>
      <LinearGradient id="a" x1={0.688} y1={0.296} x2={0} y2={1.096} gradientUnits="objectBoundingBox">
        <Stop offset={0} stopColor="#fff" />
        <Stop offset={1} stopColor="#fff" stopOpacity={0} />
      </LinearGradient>
    </Defs>
    <Path
      data-name="Path 5304"
      d="M0 0h24v24H0z"
      style={{
        fill: 'none',
      }}
    />
    <Path
      data-name="Path 5305"
      d="M15.7 4C18.87 4 21 6.98 21 9.76 21 15.39 12.16 20 12 20S3 15.39 3 9.76C3 6.98 5.13 4 8.3 4A4.772 4.772 0 0 1 12 5.71 4.772 4.772 0 0 1 15.7 4z"
      style={{
        fill: 'url(#a)',
      }}
    />
  </Svg>
);

export default SvgComponent;
