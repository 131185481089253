import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  visible: false,
  renderContent: null,
  width: null,
  size: null,
  onClose: null,
};

export const slice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    open: (state, action) => {
      state.visible = true;
      state.renderContent = action.payload?.content;
      state.width = action.payload?.width || null;
      state.size = action.payload?.size || null;
      state.onClose = action.payload?.onClose || null;
    },
    close: (state) => {
      state.visible = false;
      state.renderContent = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { open, close } = slice.actions;

export default slice.reducer;
