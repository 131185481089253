import React from 'react';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';

import ArrowLeft from '../../svg/ArrowLeft';
import { useTheme } from '../../template/ThemeProvider';

export default function HeaderWithIcons({ title, onPress, leftNode = null, rightNode = null }) {
  const { isDark, colors } = useTheme();

  return (
    <Container>
      <TouchableOpacity onPress={onPress}>
        <LeftNode>
          {leftNode || (
            <LeftIconBox>
              <ArrowLeft fill={isDark ? colors.bluey_grey : colors.dark} />
            </LeftIconBox>
          )}
        </LeftNode>
      </TouchableOpacity>

      <CenterText>{title}</CenterText>
      <RightNode>{rightNode}</RightNode>
    </Container>
  );
}

const Container = styled.View`
  margin-top: 45px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
`;

const RightNode = styled.View`
  width: 52px;
  height: 52px;
  ${(p) => !p.children && `border: none`}
`;

const LeftNode = styled.View`
  width: 52px;
  height: 52px;
  ${(p) => !p.children && `border: none`}
`;

const LeftIconBox = styled.View`
  width: 52px;
  height: 52px;
  border: 1px solid;
  border-radius: 200px;
  border-color: ${(p) => (p.theme.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey)};
  padding: 17px 0 0 16px;
  ${(p) => !p.children && `border: none`}
`;

const CenterText = styled.Text`
  font-size: 16px;
  font-weight: 500;
  color: ${(p) => (p.theme.isDark ? p.theme.myColors.slate_blue : p.theme.myColors.bluey_grey)};
  text-align: center;
  flex: 1;
  font-family: Gilroy-Medium;
`;
