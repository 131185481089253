import * as React from 'react';
import Svg, { G, Path, Defs, ClipPath } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={20} height={21} viewBox="0 0 20 21" color="#6C6CFF" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath="url(#prefix__clip0)" fillRule="evenodd" clipRule="evenodd" fill="currentColor">
        <Path d="M13.25 9.5a4.75 4.75 0 100 9.5 4.75 4.75 0 000-9.5zm-4.42.33a6.25 6.25 0 118.84 8.84 6.25 6.25 0 01-8.84-8.84z" />
        <Path d="M6.25 9.5a4.75 4.75 0 100 9.5 4.75 4.75 0 000-9.5zm-4.42.33a6.25 6.25 0 118.84 8.84 6.25 6.25 0 01-8.84-8.84zM8.437 1.5a.937.937 0 00-.937.933.858.858 0 00.28.623l.008.007 1.961 1.831 1.971-1.84a.849.849 0 00.209-.976.937.937 0 00-.866-.578H8.437zm4.313.937h.75A2.436 2.436 0 0011.063 0H8.437A2.437 2.437 0 006 2.437h.75L6 2.444a2.358 2.358 0 00.767 1.718l2.208 2.061a1.137 1.137 0 001.549 0l2.208-2.061a2.349 2.349 0 00.768-1.719l-.75-.006z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" d="M0 0h19.5v20.5H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
