import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={24} height={26} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.143 1.567a5.311 5.311 0 019.03 3.725V7.45a.75.75 0 01-1.5 0V5.306a3.811 3.811 0 00-7.621-.018V7.45a.75.75 0 11-1.5 0V5.268a5.311 5.311 0 011.59-3.701z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.042 8.127A3.042 3.042 0 002 11.169v4.289A3.042 3.042 0 005.042 18.5h7.641a3.041 3.041 0 003.042-3.042v-4.289a3.041 3.041 0 00-3.042-3.042H5.042zm-3.212-.17a4.542 4.542 0 013.212-1.33h7.641a4.541 4.541 0 014.542 4.542v4.289A4.542 4.542 0 0112.683 20H5.042A4.542 4.542 0 01.5 15.458v-4.289c0-1.205.479-2.36 1.33-3.212z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.863 11.453a.75.75 0 01.75.75v2.221a.75.75 0 01-1.5 0v-2.221a.75.75 0 01.75-.75z"
        fill="currentColor"
      />
    </Svg>
  );
}

export default SvgComponent;
