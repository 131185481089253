import * as React from 'react';
import Svg, { Defs, G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: style */

const SvgComponent = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" color="#000" width={24} height={24} {...props}>
    <Defs></Defs>
    <G id="Group_302">
      <Path id="Path_3471" d="M7 13v4" stroke="currentColor" fill="none" />
      <Path id="Path_3472" d="M21 13v4" stroke="currentColor" fill="none" />
      <Path id="Path_3473" d="M16 4c0 3 2 3 2 6" stroke="currentColor" fill="none" />
      <Path id="Path_3474" d="m9 10 4 10" stroke="currentColor" fill="none" />
      <Path id="Path_3475" d="M15 17h3v-4h-5" stroke="currentColor" fill="none" />
      <Path id="Path_3476" d="M19 3c0 3 2 3 2 6" stroke="currentColor" fill="none" />
      <Path id="Path_3477" d="M11.8 17H3v-4h7.2" stroke="currentColor" fill="none" />
    </G>
    <Path id="Path_3478" fill="none" d="M0 0h24v24H0z" />
  </Svg>
);

export default SvgComponent;
