import i18n from 'i18n-js';
import { Actionsheet, Menu, Pressable, useDisclose, useToast } from 'native-base';
import React, { useState } from 'react';
import { Alert, TouchableOpacity, Platform } from 'react-native';
import styled from 'styled-components/native';

import usersApi from '../../../apis/users';
import handleErrorResponse from '../../../services/handleErrorResponse';
import _ArrowLeft from '../../svg/ArrowLeft';
import IconMenu from '../../svg/IconMenu';
import { useTheme } from '../../template/ThemeProvider';
import ActionSheetDeleteChat from './ActionSheetDeleteChat';

export default function ChatHeader({ title, subTitle, imagesUrls, avatar, navigation, roomId, isBlocked, userId, userData }) {
  const [_isBlocked, setisBlocked] = useState(!!isBlocked);
  const { colors, isDark } = useTheme();
  const { isOpen, onOpen, onClose } = useDisclose();
  const toast = useToast();

  function alert(title = undefined, msg) {
    if (Platform.OS === 'web') {
      toast.show({ title: title, status: 'info', description: msg });
    } else {
      Alert.alert(title, msg, [{ text: 'ok', onPress: () => null, style: 'cancel' }]);
    }
  }

  function handleBlock() {
    usersApi
      .blockUser({
        userId,
      })
      .then(async (res) => {
        // doRefresh();
        setisBlocked(true);
      })
      .catch((error) => {
        if (error.response) {
          alert(null, handleErrorResponse(error.response.data?.message));
        }
      });
  }

  function handleUnBlock() {
    usersApi
      .unblockUser({
        userId,
      })
      .then(async (res) => {
        // doRefresh();
        setisBlocked(false);
      })
      .catch((error) => {
        if (error.response) {
          alert(null, handleErrorResponse(error.response.data?.message));
        }
      });
  }

  return (
    <>
      <ChatItem>
        <TouchableOpacity onPress={() => navigation.goBack()}>
          <ArrowLeft>
            <_ArrowLeft fill={isDark ? colors.white : colors.dark} />
          </ArrowLeft>
        </TouchableOpacity>
        <AvatarBox>
          <Avatar source={avatar ? { uri: avatar } : require('../../../assets/images/defaultavatar1.png')} />
        </AvatarBox>
        <ChatTexts>
          <ChatTitle>{title}</ChatTitle>
          <ChatSubTitle>{subTitle}</ChatSubTitle>
        </ChatTexts>
        <Menu
          // w="190"
          trigger={(triggerProps) => {
            return (
              <Pressable accessibilityLabel="More options menu" {...triggerProps}>
                <MenuIcon color={isDark ? colors.white : colors.dark} />
              </Pressable>
            );
          }}>
          <Menu.Item onPress={() => navigation.navigate('SingleUserProfileScreen', { userData })}>{i18n.t('chat.menu_show_profile')}</Menu.Item>
          <Menu.Item onPress={onOpen}>{i18n.t('chat.menu_delete_all_chats')}</Menu.Item>
          <Menu.Item onPress={_isBlocked ? handleUnBlock : handleBlock}>
            {_isBlocked ? i18n.t('userprofile.actionsheet_blocked_btn_unblock') : i18n.t('chat.menu_block_the_user')}
          </Menu.Item>
          {/* <Menu.Item>{i18n.t('chat.menu_report_the_user')}</Menu.Item> */}
        </Menu>
      </ChatItem>
      <Line />

      <Actionsheet isOpen={isOpen} onClose={onClose}>
        <ActionSheetDeleteChat
          roomId={roomId}
          onClose={() => {
            onClose();
            navigation.goBack();
          }}
        />
      </Actionsheet>
    </>
  );
}

const ChatItem = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 35px;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
  margin-top: 30px;
`;
const Line = styled.View`
  height: 1px;
  width: 100%;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey2)};
`;
const AvatarBox = styled.View`
  border-radius: 1000px;
  padding: 2px;
  border: 1px solid;
  border-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey)};
  margin-left: 15px;
`;
const Avatar = styled.Image`
  border-radius: 1000px;
  width: 52px;
  height: ${Platform.OS === 'web' ? '52px' : 'auto'};
  aspect-ratio: 1;
`;
const ChatTexts = styled.View`
  flex: 1;
  margin-left: 11px;
`;
const ChatTitle = styled.Text`
  font-family: Gilroy-SemiBold;
  font-size: 14px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dark2)};
  text-align: left;
`;
const ChatSubTitle = styled.Text`
  font-family: Gilroy-Light;
  font-size: 12px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.light_blue_grey : p.theme.myColors.slate_blue)};
  text-align: left;
`;
const MenuIcon = styled(IconMenu)`
  /* background-color: red; */
  padding: 10px 20px;
`;
const ArrowLeft = styled.View`
  /* background-color: red; */
  padding: 15px;
`;
