import AsyncStorage from '@react-native-async-storage/async-storage';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';

import ActionSheetSlice from './slices/ActionSheetSlice';
import ModalSlice from './slices/ModalSlice';
import UserSlice from './slices/UserSlice';
import StartupSlice from './slices/StartupSlice';
import MessagesSlice from './slices/MessagesSlice';

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  version: 1,
  whitelist: ['user', 'startup', 'messages'], // only this list will be persisted
  // stateReconciler: autoMergeLevel1,
};

const reducers = combineReducers({
  modal: ModalSlice,
  actionSheet: ActionSheetSlice,
  user: UserSlice,
  startup: StartupSlice,
  messages: MessagesSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
    }),
});
export const persistor = persistStore(store);
