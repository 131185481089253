import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={22} height={28} viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M.333 22.767c0-3.629 4.914-4.535 10.667-4.535 5.785 0 10.667.94 10.667 4.566S16.753 27.333 11 27.333C5.216 27.334.333 26.394.333 22.767zM3.941 7.722a7.058 7.058 0 117.058 7.056 7.032 7.032 0 01-7.058-7.056z"
        fill="#6C6CFF"
      />
    </Svg>
  );
}

export default SvgComponent;
