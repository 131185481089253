import * as React from 'react';
import Svg, { Defs, G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: style */

const SvgComponent = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" color="#000" width={24} height={24} {...props}>
    <Defs></Defs>
    <G id="Group_838">
      <Path id="Path_10125" d="M5 5.25h4" stroke="currentColor" fill="none" />
      <Path
        id="Path_10126"
        d="M18 17a3.5 3.5 0 0 1-3.459-4.023l.947-6.13A1 1 0 0 1 16.479 6h3.262a1 1 0 0 1 1 .882l.739 6.215A3.494 3.494 0 0 1 18 17z"
        stroke="currentColor"
        fill="none"
      />
      <Path id="Path_10127" d="m3.739 10.962 1.141.713a4 4 0 0 0 4.24 0l1.141-.713" stroke="currentColor" fill="none" />
      <Path
        id="Path_10128"
        d="M6.5 2h1a1 1 0 0 1 1 1v2.085A10.517 10.517 0 0 0 10 10.5a10.522 10.522 0 0 1 1.5 5.415V20a2 2 0 0 1-2 2h-5a2 2 0 0 1-2-2v-4.085A10.517 10.517 0 0 1 4 10.5a10.522 10.522 0 0 0 1.5-5.415V3a1 1 0 0 1 1-1z"
        stroke="currentColor"
        fill="none"
      />
      <Path
        id="Path_10129"
        d="M7.986 15A1.448 1.448 0 0 1 9.4 16.439C9.4 17.847 7.043 19 7 19s-2.4-1.153-2.4-2.561A1.448 1.448 0 0 1 6.014 15a1.3 1.3 0 0 1 .986.428A1.3 1.3 0 0 1 7.986 15z"
        stroke="currentColor"
        fill="none"
      />
      <Path id="Path_10130" d="M18 17v5" stroke="currentColor" fill="none" />
      <Path id="Path_10131" d="M16 22h4" stroke="currentColor" fill="none" />
      <Path id="Path_10132" d="M21.11 10H15" stroke="currentColor" fill="none" />
    </G>
    <Path id="Path_10133" fill="none" d="M0 0h24v24H0z" />
  </Svg>
);

export default SvgComponent;
