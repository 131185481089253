import * as React from 'react';
import Svg, { Defs, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: style */

const SvgComponent = (props) => (
  <Svg id="Warning" xmlns="http://www.w3.org/2000/svg" color="#000" width={24} height={24} {...props}>
    <Defs></Defs>
    <Path
      id="Path_7195"
      data-name="Path 7195"
      d="M0 0h24v24H0z"
      style={{
        fill: 'none',
      }}
    />
    <Path id="Path_7196" data-name="Path 7196" stroke="currentColor" d="M12 16a.25.25 0 1 0 .251.25A.249.249 0 0 0 12 16" />
    <Path
      id="Path_7197"
      data-name="Path 7197"
      stroke="currentColor"
      d="m7.414 20.414-3.828-3.828A2 2 0 0 1 3 15.172V8.828a2 2 0 0 1 .586-1.414l3.828-3.828A2 2 0 0 1 8.828 3h6.343a2 2 0 0 1 1.414.586l3.828 3.828A2 2 0 0 1 21 8.828v6.343a2 2 0 0 1-.586 1.414L16 21H8.828a2 2 0 0 1-1.414-.586z"
    />
    <Path id="Path_7198" data-name="Path 7198" stroke="currentColor" d="M12 12.5v-5" />
  </Svg>
);

export default SvgComponent;
