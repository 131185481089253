import i18n from 'i18n-js';
import React from 'react';
import { FlatList, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';

import IconSend from '../../svg/IconSend';
import { useTheme } from '../../template/ThemeProvider';
import Myinput from '../../ui/MYInput';

export default function Seperator({ title }) {
  const { colors, isDark } = useTheme();

  return (
    <Container>
      <Line />
      <Txt>{title}</Txt>
      <Line />
    </Container>
  );
}

const Container = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 28px 35px;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
`;
const Txt = styled.Text`
  font-family: Gilroy-Medium;
  padding: 0 14px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.bluey_grey : p.theme.myColors.light_blue_grey)};
  font-size: 12px;
`;
const Line = styled.View`
  height: 1px;
  flex: 1;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey)};
`;
