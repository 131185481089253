import * as React from 'react';
import Svg, { Path, G } from 'react-native-svg';

const SvgComponent = (props) => (
  <Svg data-name="Ticket Star" xmlns="http://www.w3.org/2000/svg" width={73.333} height={66} {...props}>
    <Path
      data-name="Fill 1"
      d="M70.583 38.413a5.412 5.412 0 1 1 0-10.823 2.763 2.763 0 0 0 1.943-.8 2.715 2.715 0 0 0 .807-1.929v-9.817A15.138 15.138 0 0 0 58.139 0H15.195A15.138 15.138 0 0 0 0 15.049v10.132a2.715 2.715 0 0 0 .807 1.929 2.763 2.763 0 0 0 1.943.8A5.171 5.171 0 0 1 8.21 33a5.441 5.441 0 0 1-5.46 5.41A2.739 2.739 0 0 0 0 41.138v9.81A15.139 15.139 0 0 0 15.191 66h42.951a15.139 15.139 0 0 0 15.191-15.052v-9.81a2.739 2.739 0 0 0-2.75-2.725"
      style={{
        fill: '#ff5d3b',
        opacity: 0.11,
      }}
    />
    <G data-name="Login, Enter">
      <Path
        data-name="Path 5710"
        d="M0 0h33v33H0z"
        style={{
          fill: 'none',
        }}
        transform="translate(20 15.596)"
      />
      <Path data-name="Path 5711" fill="none" stroke="#ff5d3b" strokeWidth={2} d="M24.125 32.096h16.5" />
      <Path
        data-name="Path 5712"
        fill="none"
        stroke="#ff5d3b"
        strokeWidth={2}
        d="M27.348 40.346a12.353 12.353 0 1 0 9.156-20.625 12.258 12.258 0 0 0-9.156 4.125"
      />
      <Path data-name="Path 5713" fill="none" stroke="#ff5d3b" strokeWidth={2} d="m28.25 27.971-4.125 4.125 4.125 4.125" />
    </G>
  </Svg>
);

export default SvgComponent;
