import * as React from 'react';
import Svg, { G, Circle, Path } from 'react-native-svg';

const SvgComponent = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={42} height={42} {...props}>
    <G transform="translate(-742 -1581)">
      <Circle cx={21} cy={21} r={21} fill="#fff" transform="translate(742 1581)" />
      <Circle cx={18} cy={18} r={18} fill="#23282d" transform="translate(745 1584)" />
      <Path
        d="M767.7 1609.404h-9.4a3.635 3.635 0 0 1-3.633-3.629v-4.945a3.635 3.635 0 0 1 3.633-3.626.165.165 0 0 0 .159-.1l.049-.1.069-.146c.218-.459.464-.977.614-1.278a2.027 2.027 0 0 1 1.843-1.176h3.925a2.034 2.034 0 0 1 1.85 1.174c.131.262.33.683.523 1.09l.118.25.084.183a.188.188 0 0 0 .167.1 3.635 3.635 0 0 1 3.633 3.629v4.945a3.635 3.635 0 0 1-3.634 3.629zm-4.7-9.665a3.268 3.268 0 0 0-2.325.965 3.223 3.223 0 0 0-.95 2.305 3.27 3.27 0 0 0 3.275 3.272 3.246 3.246 0 0 0 2.308-.957 3.263 3.263 0 0 0 .008-4.628 3.254 3.254 0 0 0-2.316-.957zm4.675-.2a.753.753 0 1 0 .759.758.755.755 0 0 0-.759-.757zm-4.675 5.495a2.019 2.019 0 0 1-2.025-2.023v-.007a1.947 1.947 0 0 1 .583-1.415 2.025 2.025 0 1 1 1.442 3.445z"
        fill="#fff"
      />
    </G>
  </Svg>
);

export default SvgComponent;
