import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={24} height={24} viewBox="0 0 34 31" color="#000" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M7.233 10.748a1.207 1.207 0 011.706-.192l6.96 5.6a2.374 2.374 0 002.937 0l6.899-5.597a1.208 1.208 0 011.707.186 1.23 1.23 0 01-.184 1.72l-6.918 5.612a4.787 4.787 0 01-5.945 0l-.007-.006-6.964-5.603a1.23 1.23 0 01-.191-1.72z"
        fill="currentColor"
      />
      <Path
        d="M24.281 2.447c4.023 0 7.285 3.288 7.285 7.342v11.422c0 4.055-3.262 7.342-7.285 7.342H9.712c-4.023 0-7.284-3.287-7.284-7.342V9.789c0-4.054 3.261-7.342 7.284-7.342h14.57zM9.712 0C4.348 0 0 4.383 0 9.79v11.42C0 26.618 4.349 31 9.712 31h14.57c5.363 0 9.712-4.383 9.712-9.79V9.79c0-5.407-4.349-9.79-9.713-9.79H9.712z"
        fill="currentColor"
      />
    </Svg>
  );
}

export default SvgComponent;
