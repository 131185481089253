import i18n from 'i18n-js';
import React, { useState, useEffect } from 'react';
import { View, Text, Platform } from 'react-native';
import styled from 'styled-components/native';

import HeaderWithIcons from '../../components/common/HeaderWithIcons';
import MYButton from '../../components/ui/MYButton';

export default function PaymentCallbackScreen({ route, navigation }) {
  const { result, message } = route.params;

  return (
    <Container>
      <HeaderWithIcons onPress={() => navigation.goBack()} title={i18n.t('payment.header_title')} LeftNode={<></>} />

      <ImageContainer>
        {result === 'success' ? (
          <GalleryImage source={require('../../assets/images/paysuccess.png')} />
        ) : (
          <GalleryImage source={require('../../assets/images/payerror.png')} />
        )}
        <Message>{message}</Message>
      </ImageContainer>

      {result === 'success' ? (
        <ButtonsContainer>
          <MYButton color="blue" style={{ width: '100%' }} onPress={() => navigation.navigate('Search')}>
            {i18n.t('payment.btn_go_to_search')}
          </MYButton>
        </ButtonsContainer>
      ) : (
        <ButtonsContainer>
          <MYButton color="red" style={{ width: '100%', marginBottom: 10 }} onPress={() => navigation.navigate('SubscriptionHomeScreen')}>
            {i18n.t('payment.btn_try_again')}
          </MYButton>
          <MYButton color="blue" style={{ width: '100%' }} onPress={() => navigation.navigate('Search')}>
            {i18n.t('payment.btn_go_to_search')}
          </MYButton>
        </ButtonsContainer>
      )}
    </Container>
  );
}

const Container = styled.View`
  display: flex;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
  flex: 1;
  align-items: center;
  overflow: hidden;
`;

const ImageContainer = styled.View`
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  /* height: auto; */
  /* flex-shrink: 1; */
  /* min-height: 40px; */
  /* padding: 0 40px; */
  /* padding: 25px 0; */
  /* height: 40%; */
`;

const GalleryImage = styled.Image`
  width: 30%;
  height: ${Platform.OS === 'web' ? '152px' : 'auto'};
  aspect-ratio: 1;
`;

const ButtonsContainer = styled.View`
  padding: 0 34px;
  width: 100%;
  margin-bottom: 28px;
  flex-shrink: 0;
  margin-top: auto;
`;

const Message = styled.Text`
  font-family: Gilroy-Light;
  margin-top: 40px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dark)};
`;
