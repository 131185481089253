import apiService from '../services/apiService';

const evaluationApi = {
  async likeUser(userId) {
    return apiService.post(`evaluations/like/${userId}`);
  },
  async removelikeUser(userId) {
    return apiService.post(`evaluations/remove-like/${userId}`);
  },
  async dislikeUser(userId) {
    return apiService.post(`evaluations/dislike/${userId}`);
  },
  async getWhoILiked(queryParams = null) {
    return apiService.get(`evaluations/iliked${queryParams ? `?${queryParams}` : ''}`);
  },
  async isILikedThisUser(userId) {
    return apiService.get(`evaluations/iliked/${userId}`);
  },
  async getWhoLikedMe(queryParams = null) {
    return apiService.get(`evaluations/likedme${queryParams ? `?${queryParams}` : ''}`);
  },
  async getMatchesList(queryParams = null) {
    return apiService.get(`matches${queryParams ? `?${queryParams}` : ''}`);
  },
};

export default evaluationApi;
