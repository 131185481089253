import i18n from 'i18n-js';
import { Spinner } from 'native-base';
import React, { useEffect } from 'react';
import { Text, TouchableOpacity, Platform } from 'react-native';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';

import evaluationApi from '../../../apis/evaluation';
import useAsync from '../../../hooks/useAsync';
import HeaderWithMoreArrow from '../../common/HeaderWithMoreArrow';
import { useTheme } from '../../template/ThemeProvider';

export default function YourLikes({ navigation, doRefresh }) {
  const { colors, isDark, setScheme } = useTheme();
  const userReduxState = useSelector((state) => state.user);
  const myGender = userReduxState?.userInfo?.genderEnumId === 1 ? 1 : 2;

  const { execute, status, response, error } = useAsync(() => {
    return evaluationApi.getWhoILiked();
  }, false);

  useEffect(() => {
    execute();
  }, [doRefresh]);

  const RenderNoUserImage = () => (
    <SmallImage
      source={myGender === 1 ? require('../../../assets/images/defaultavatar2.png') : require('../../../assets/images/defaultavatar1.png')}
    />
  );

  const RenderEmpty = () => (
    <SmallImage
      source={
        myGender === 1 ? require('../../../assets/images/defaultavatar2_deactive.png') : require('../../../assets/images/defaultavatar1_deactive.png')
      }
      blurRadius={90}
      style={{ opacity: 0.4 }}
    />
  );

  const renderLoadingSkeletons = (
    <ImagexContainerRoot>
      <BigImageContainer>
        <ContentPlaceHolder />
      </BigImageContainer>

      <MoreImagesContainer>
        <SmallImageView>
          <EmptyContentPlaceHolder />
        </SmallImageView>
        <SmallImageView>
          <EmptyContentPlaceHolder />
        </SmallImageView>
        <SmallImageView>
          <EmptyContentPlaceHolder />
        </SmallImageView>
        <SmallImageView>
          <EmptyContentPlaceHolder />
        </SmallImageView>
      </MoreImagesContainer>
    </ImagexContainerRoot>
  );

  const renderEmpty = (
    <ImagexContainerRoot>
      <BigImageContainer>
        <RenderEmpty />
      </BigImageContainer>

      <MoreImagesContainer>
        <SmallImageView>
          <RenderEmpty />
        </SmallImageView>
        <SmallImageView>
          <RenderEmpty />
        </SmallImageView>
        <SmallImageView>
          <RenderEmpty />
        </SmallImageView>
        <SmallImageView>
          <RenderEmpty />
        </SmallImageView>
      </MoreImagesContainer>
    </ImagexContainerRoot>
  );

  return (
    <Container>
      <HeaderWithMoreArrow
        titleBold={i18n.t('discover.your_likes_your')}
        title={i18n.t('discover.your_likes_likes')}
        onPressMore={() => navigation.navigate('YourLikesScreen')}
      />
      {status === 'pending' && renderLoadingSkeletons}
      {response?.data?.results?.length > 0 ? (
        <ImagexContainerRoot>
          <BigImageContainer>
            {!response?.data?.results?.[0]?.userDetails?.[0] ? (
              <RenderNoUserImage />
            ) : (
              <TouchableOpacity
                style={{ height: '100%', width: '100%' }}
                onPress={() =>
                  navigation.navigate('SingleUserProfileScreen', { userData: response?.data?.results?.[0]?.userDetails?.[0], isLiked: true })
                }>
                {response?.data?.results?.[0]?.userDetails?.[0]?.avatar ? (
                  <SmallImage source={{ uri: response?.data?.results?.[0]?.userDetails?.[0]?.avatar }} />
                ) : (
                  <RenderNoUserImage />
                )}
              </TouchableOpacity>
            )}
          </BigImageContainer>

          <MoreImagesContainer>
            <SmallImageView>
              {!response?.data?.results?.[1]?.userDetails?.[0] ? (
                <RenderNoUserImage />
              ) : (
                <TouchableOpacity
                  style={{ height: '100%', width: '100%' }}
                  onPress={() =>
                    navigation.navigate('SingleUserProfileScreen', { userData: response?.data?.results?.[1]?.userDetails?.[0], isLiked: true })
                  }>
                  {response?.data?.results?.[1]?.userDetails?.[0]?.avatar ? (
                    <SmallImage source={{ uri: response?.data?.results?.[1]?.userDetails?.[0]?.avatar }} />
                  ) : (
                    <RenderNoUserImage />
                  )}
                </TouchableOpacity>
              )}
            </SmallImageView>
            <SmallImageView>
              {!response?.data?.results?.[2]?.userDetails?.[0] ? (
                <RenderNoUserImage />
              ) : (
                <TouchableOpacity
                  style={{ height: '100%', width: '100%' }}
                  onPress={() =>
                    navigation.navigate('SingleUserProfileScreen', { userData: response?.data?.results?.[2]?.userDetails?.[0], isLiked: true })
                  }>
                  {response?.data?.results?.[2]?.userDetails?.[0]?.avatar ? (
                    <SmallImage source={{ uri: response?.data?.results?.[2]?.userDetails?.[0]?.avatar }} />
                  ) : (
                    <RenderNoUserImage />
                  )}
                </TouchableOpacity>
              )}
            </SmallImageView>
            <SmallImageView>
              {!response?.data?.results?.[3]?.userDetails?.[0] ? (
                <RenderNoUserImage />
              ) : (
                <TouchableOpacity
                  style={{ height: '100%', width: '100%' }}
                  onPress={() =>
                    navigation.navigate('SingleUserProfileScreen', { userData: response?.data?.results?.[3]?.userDetails?.[0], isLiked: true })
                  }>
                  {response?.data?.results?.[3]?.userDetails?.[0]?.avatar ? (
                    <SmallImage source={{ uri: response?.data?.results?.[3]?.userDetails?.[0]?.avatar }} />
                  ) : (
                    <RenderNoUserImage />
                  )}
                </TouchableOpacity>
              )}
            </SmallImageView>
            <SmallImageView>
              {!response?.data?.results?.[4]?.userDetails?.[0] ? (
                <RenderNoUserImage />
              ) : (
                <TouchableOpacity
                  style={{ height: '100%', width: '100%' }}
                  onPress={() =>
                    navigation.navigate('SingleUserProfileScreen', { userData: response?.data?.results?.[4]?.userDetails?.[0], isLiked: true })
                  }>
                  {response?.data?.results?.[4]?.userDetails?.[0]?.avatar ? (
                    <SmallImage source={{ uri: response?.data?.results?.[4]?.userDetails?.[0]?.avatar }} />
                  ) : (
                    <RenderNoUserImage />
                  )}
                </TouchableOpacity>
              )}
            </SmallImageView>
          </MoreImagesContainer>
        </ImagexContainerRoot>
      ) : (
        status !== 'pending' && renderEmpty
      )}
    </Container>
  );
}

const Container = styled.View`
  position: relative;
  padding-bottom: 26px;
`;

const ImagexContainerRoot = styled.View`
  display: flex;
  flex-direction: row;
`;

const BigImageContainer = styled.View`
  flex: 1;
  margin-right: 5px;
  position: relative;
  aspect-ratio: 1;
  height: ${Platform.OS === 'web' ? '85px' : 'auto'};
  padding-bottom: 5px;
`;

const MoreImagesContainer = styled.View`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
`;

const SmallImageView = styled.View`
  flex: 50%;
  aspect-ratio: 1;
  width: 100%;
  height: 100%;
  padding: 0 5px 5px 0;
`;
const SmallImage = styled.Image`
  width: 100%;
  height: 100%;
  border-radius: 12px;
`;

const ContentPlaceHolder = styled.View`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dusk : '#E1E9EE')};
`;
const EmptyContentPlaceHolder = styled.View`
  width: 100%;
  height: 100%;
  border-radius: 12px;
`;
