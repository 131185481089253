import * as React from 'react';
import Svg, { Defs, G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: style */

const SvgComponent = (props) => (
  <Svg id="News" xmlns="http://www.w3.org/2000/svg" color="#000" width={24} height={24} {...props}>
    <Defs></Defs>
    <G id="Group_475" data-name="Group 475">
      <Path id="Path_5908" data-name="Path 5908" stroke="currentColor" d="M19 21h0a2 2 0 0 0 2-2v-6a1 1 0 0 0-1-1h-3v7a2 2 0 0 0 2 2z" />
      <Path id="Path_5909" data-name="Path 5909" stroke="currentColor" d="M7 8h6" />
      <Path id="Path_5910" data-name="Path 5910" stroke="currentColor" d="M7 11.87h6" />
      <Path id="Path_5911" data-name="Path 5911" stroke="currentColor" d="M7 16.06h3" />
      <Path id="Path_5912" data-name="Path 5912" stroke="currentColor" d="M17 12V5a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v14a2 2 0 0 0 2 2h14" />
    </G>
    <Path
      id="Path_5913"
      data-name="Path 5913"
      d="M0 0h24v24H0z"
      style={{
        fill: 'none',
      }}
    />
  </Svg>
);

export default SvgComponent;
