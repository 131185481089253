import React, { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import country from 'country-list-js';
import { Button, View, Text, FlatList } from 'react-native';
import styled from 'styled-components/native';
import CountryPicker, { DEFAULT_THEME, DARK_THEME } from 'react-native-country-picker-modal';
import { useTheme } from '../template/ThemeProvider';
import useAsync from '../../hooks/useAsync';
import countryStateCityApi from '../../apis/country-state-city';
import { Spinner } from 'native-base';
import { TouchableOpacity } from 'react-native-gesture-handler';
import Myinput from '../ui/MYInput';
import HeaderWithIcons from './HeaderWithIcons';

export function ModalSelectCity({ navigation, route, onSubmitCity }) {
  const { onGoBack, initialCountry } = route?.params || {};
  const { colors, isDark, setScheme } = useTheme();

  const [countryCode, setCountryCode] = useState(initialCountry || null);
  const [searchStr, setsearchStr] = useState('');

  const { execute, status, response, error } = useAsync(() => {
    return countryStateCityApi.getCitiesOfCountry(countryCode);
  }, false);

  const filteredResult = response?.data?.length > 0 && response?.data?.filter((a) => a?.name?.toLowerCase().includes(searchStr?.toLowerCase()));
  const CountryFullName = country.findByIso2(countryCode?.toUpperCase())?.name;

  useEffect(() => {
    if (countryCode) {
      execute();
    }
  }, [countryCode]);

  function handleSubmitCity(obj) {
    if (onGoBack) {
      navigation.goBack();

      onGoBack({
        countryCode: obj?.countryCode,
        latitude: obj?.latitude,
        longitude: obj?.longitude,
        name: obj?.name,
        stateCode: obj?.stateCode,
      });
    }

    if (onSubmitCity) {
      onSubmitCity({
        countryCode: obj?.countryCode,
        latitude: obj?.latitude,
        longitude: obj?.longitude,
        name: obj?.name,
        stateCode: obj?.stateCode,
      });
    }
  }

  return (
    <Container>
      <HeaderWithIcons title={i18n.t('auth.your_country')} onPress={() => navigation.goBack()} />
      <Container2>
        <CountryBox>
          <CountryPicker
            theme={isDark ? DARK_THEME : DEFAULT_THEME}
            countryCode={countryCode || null}
            withFilter
            withFlag
            withCountryNameButton
            withAlphaFilter
            // withCallingCode
            withEmoji
            onSelect={(country) => {
              setCountryCode(country.cca2);
              setsearchStr('');
            }}
            // visible
          />
        </CountryBox>
        {countryCode && (
          <InputsBox>
            <Myinput
              variant="unstyled"
              placeholder={i18n.t('edit_my_profile.city')} // mx={4}
              onChangeText={(txt) => setsearchStr(txt)}
              value={searchStr || ''}
            />
          </InputsBox>
        )}

        {status === 'pending' && <Spinner size="lg" style={{ marginTop: 50 }} />}

        {status !== 'pending' && filteredResult?.length > 0 && (
          <FlatList
            data={filteredResult}
            renderItem={({ item }) => (
              <TouchableOpacity onPress={() => handleSubmitCity(item)}>
                <Item>
                  <Title>{item.name}</Title>
                  <SubTitle>
                    {item.stateCode}, {CountryFullName}
                  </SubTitle>
                </Item>
              </TouchableOpacity>
            )}
            ItemSeparatorComponent={({ highlighted }) => <MenuItemSeperator />}
          />
        )}
      </Container2>
    </Container>
  );
}

const Container = styled.View`
  display: flex;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
  flex: 1;
  /* align-items: center; */
  /* justify-content: space-between; */
  /* overflow: hidden; */
  width: 100%;
  /* padding: 0 20px 15px 20px; */
  /* margin: 10px 35px; */
`;
const Container2 = styled.View`
  display: flex;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
  flex: 1;
  /* align-items: center; */
  /* justify-content: space-between; */
  /* overflow: hidden; */
  width: 100%;
  padding: 0 20px 15px 20px;
  margin-top: 16px;
`;

const MenuItemSeperator = styled.View`
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey2)};
  height: 1px;
  /* margin-left: 30px; */
`;

const Item = styled.View`
  padding: 8px 16px;
`;
const Title = styled.Text`
  color: ${(p) => (p.theme.isDark ? p.theme.myColors.pale_grey : p.theme.myColors.dark2)};
  font-family: Gilroy-Medium;
`;
const SubTitle = styled.Text`
  color: ${(p) => (p.theme.isDark ? p.theme.myColors.slate_blue : p.theme.myColors.bluey_grey)};
  font-family: Gilroy-Light;
  font-size: 12px;
`;
const InputsBox = styled.View`
  border-radius: 8px;
  border: solid 1px;
  border-color: ${(p) => (p.theme.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey)};
  padding: 8px 16px;
  margin: 10px 0;
  position: relative;
`;
const CountryBox = styled.View`
  border-radius: 8px;
  border: solid 1px;
  border-color: ${(p) => (p.theme.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey)};
  padding: 14px 16px;
  margin: 10px 0;
  position: relative;
`;
