import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = (props) => (
  <Svg width={74} height={66} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      opacity={0.11}
      d="M70.583 38.413a5.413 5.413 0 1 1 0-10.823 2.763 2.763 0 0 0 2.54-1.684c.138-.33.21-.686.21-1.045v-9.817A15.138 15.138 0 0 0 58.139 0H15.195A15.138 15.138 0 0 0 0 15.049v10.132a2.715 2.715 0 0 0 .807 1.929c.517.512 1.215.8 1.943.8A5.169 5.169 0 0 1 8.21 33a5.44 5.44 0 0 1-5.46 5.41A2.736 2.736 0 0 0 0 41.138v9.81A15.14 15.14 0 0 0 15.191 66h42.951a15.139 15.139 0 0 0 15.191-15.052v-9.81a2.739 2.739 0 0 0-2.75-2.725"
      fill="#FF5D3B"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.844 24.625c0-.57.461-1.031 1.031-1.031h19.25a1.031 1.031 0 0 1 0 2.062h-19.25c-.57 0-1.031-.461-1.031-1.031Z"
      fill="#FF5D3B"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.25 23.594c.57 0 1.031.461 1.031 1.031V39.75A1.718 1.718 0 0 0 31 41.469h11a1.718 1.718 0 0 0 1.719-1.719V24.625a1.031 1.031 0 1 1 2.062 0V39.75A3.78 3.78 0 0 1 42 43.531H31a3.78 3.78 0 0 1-3.781-3.781V24.625c0-.57.461-1.031 1.031-1.031ZM31.344 20.156c0-.57.461-1.031 1.031-1.031h8.25a1.031 1.031 0 0 1 0 2.063h-8.25c-.57 0-1.031-.462-1.031-1.032Z"
      fill="#FF5D3B"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.75 29.094c.57 0 1.031.461 1.031 1.031V37a1.031 1.031 0 0 1-2.062 0v-6.875c0-.57.462-1.031 1.031-1.031ZM39.25 29.094c.57 0 1.031.461 1.031 1.031V37a1.031 1.031 0 0 1-2.062 0v-6.875c0-.57.462-1.031 1.031-1.031Z"
      fill="#FF5D3B"
    />
  </Svg>
);

export default SvgComponent;
