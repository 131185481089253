export const _martialStatusOptions = (i18n) => [
  {
    label: '-',
    value: null,
  },
  {
    label: i18n.t('edit_my_profile.martialstatusoptions.single'),
    value: 'single',
  },
  {
    label: i18n.t('edit_my_profile.martialstatusoptions.divorced'),
    value: 'divorced',
  },
  {
    label: i18n.t('edit_my_profile.martialstatusoptions.widow'),
    value: 'widow',
  },
];

export const _jobStatusEnumIdOptions = (i18n) => [
  {
    label: '-',
    value: null,
  },
  {
    label: i18n.t('edit_my_profile.jobStatusEnumIdOptions.studding'),
    value: 'studding',
  },
  {
    label: i18n.t('edit_my_profile.jobStatusEnumIdOptions.working'),
    value: 'working',
  },
  {
    label: i18n.t('edit_my_profile.jobStatusEnumIdOptions.looking_for_job'),
    value: 'looking_for_job',
  },
  {
    label: i18n.t('edit_my_profile.jobStatusEnumIdOptions.retired'),
    value: 'retired',
  },
  {
    label: i18n.t('edit_my_profile.jobStatusEnumIdOptions.self_employed'),
    value: 'self_employed',
  },
];

export const _educationEnumIdOptions = (i18n) => [
  {
    label: '-',
    value: null,
  },
  {
    label: i18n.t('edit_my_profile.educationEnumIdOptions.high_school'),
    value: 'high_school',
  },
  {
    label: i18n.t('edit_my_profile.educationEnumIdOptions.2_years_college'),
    value: '2_years_college',
  },
  {
    label: i18n.t('edit_my_profile.educationEnumIdOptions.bachelor'),
    value: 'bachelor',
  },
  {
    label: i18n.t('edit_my_profile.educationEnumIdOptions.master'),
    value: 'master',
  },
  {
    label: i18n.t('edit_my_profile.educationEnumIdOptions.phd'),
    value: 'phd',
  },
  {
    label: i18n.t('edit_my_profile.educationEnumIdOptions.higher_than_phd'),
    value: 'higher_than_phd',
  },
];

export const _ethnicityEnumIdOptions = (i18n) => [
  {
    label: '-',
    value: null,
  },
  {
    label: i18n.t('edit_my_profile.ethnicityEnumIdOptions.kurmanj'),
    value: 'kurmanj',
  },
  {
    label: i18n.t('edit_my_profile.ethnicityEnumIdOptions.sorani'),
    value: 'sorani',
  },
  {
    label: i18n.t('edit_my_profile.ethnicityEnumIdOptions.kalhor'),
    value: 'kalhor',
  },
  {
    label: i18n.t('edit_my_profile.ethnicityEnumIdOptions.zazaki'),
    value: 'zazaki',
  },
  {
    label: i18n.t('edit_my_profile.ethnicityEnumIdOptions.horami'),
    value: 'horami',
  },
  {
    label: i18n.t('edit_my_profile.ethnicityEnumIdOptions.other'),
    value: 'other',
  },
];

export const _languageEnumIdOptions = (i18n) => [
  {
    label: '-',
    value: null,
  },
  {
    label: i18n.t('edit_my_profile.languageEnumIdOptions.kurmanji'),
    value: 'kurmanji',
  },
  {
    label: i18n.t('edit_my_profile.languageEnumIdOptions.sorani'),
    value: 'sorani',
  },
  {
    label: i18n.t('edit_my_profile.languageEnumIdOptions.kalhori'),
    value: 'kalhori',
  },
  {
    label: i18n.t('edit_my_profile.languageEnumIdOptions.zazaki'),
    value: 'zazaki',
  },
  {
    label: i18n.t('edit_my_profile.languageEnumIdOptions.horami'),
    value: 'horami',
  },
  {
    label: i18n.t('edit_my_profile.languageEnumIdOptions.english'),
    value: 'english',
  },
  {
    label: i18n.t('edit_my_profile.languageEnumIdOptions.other'),
    value: 'other',
  },
];

export const _interestEnumIdOptions = (i18n) => [
  {
    label: '-',
    value: null,
  },
  {
    label: i18n.t('edit_my_profile.interestEnumIdOptions.art'),
    value: 'art',
  },
  {
    label: i18n.t('edit_my_profile.interestEnumIdOptions.cooking'),
    value: 'cooking',
  },
  {
    label: i18n.t('edit_my_profile.interestEnumIdOptions.dancing'),
    value: 'dancing',
  },
  {
    label: i18n.t('edit_my_profile.interestEnumIdOptions.movie'),
    value: 'movie',
  },
  {
    label: i18n.t('edit_my_profile.interestEnumIdOptions.music'),
    value: 'music',
  },
  {
    label: i18n.t('edit_my_profile.interestEnumIdOptions.shopping'),
    value: 'shopping',
  },
  {
    label: i18n.t('edit_my_profile.interestEnumIdOptions.sport'),
    value: 'sport',
  },
  {
    label: i18n.t('edit_my_profile.interestEnumIdOptions.traveling'),
    value: 'traveling',
  },
];

export const _childrensEnumIdOptions = (i18n) => [
  {
    label: '-',
    value: null,
  },
  {
    label: i18n.t('edit_my_profile.childrensEnumIdOptions.do_have'),
    value: 'do_have',
  },
  {
    label: i18n.t('edit_my_profile.childrensEnumIdOptions.dont_have'),
    value: 'dont_have',
  },
];

export const _smokeEnumIdOptions = (i18n) => [
  {
    label: '-',
    value: null,
  },
  {
    label: i18n.t('edit_my_profile.smokeEnumIdOptions.never'),
    value: 'never',
  },
  {
    label: i18n.t('edit_my_profile.smokeEnumIdOptions.sometimes'),
    value: 'sometimes',
  },
  {
    label: i18n.t('edit_my_profile.smokeEnumIdOptions.often'),
    value: 'often',
  },
];

export const _drinkEnumIdOptions = (i18n) => [
  {
    label: '-',
    value: null,
  },
  {
    label: i18n.t('edit_my_profile.drinkEnumIdOptions.never'),
    value: 'never',
  },
  {
    label: i18n.t('edit_my_profile.drinkEnumIdOptions.sometimes'),
    value: 'sometimes',
  },
  {
    label: i18n.t('edit_my_profile.drinkEnumIdOptions.often'),
    value: 'often',
  },
];

export const formRules = {
  countryCode: {
    required: false,
  },
  state: {
    required: false,
  },
  city: {
    required: false,
  },
  name: {
    required: true,
  },
  martialStatus: {
    required: false,
  },
  jobStatusEnumId: {
    required: false,
  },
  educationEnumId: {
    required: false,
  },
  ethnicityEnumId: {
    required: false,
  },
  languageEnumId: {
    required: false,
  },
  interestEnumId: {
    required: false,
  },
  childrensEnumId: {
    required: false,
  },
  smokeEnumId: {
    required: false,
  },
  drinkEnumId: {
    required: false,
  },
  height: {
    required: false,
  },
};
