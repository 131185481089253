import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={20} height={22} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.212 7.25h3.57a1.824 1.824 0 011.177 3.213l-1.773 1.519a1.825 1.825 0 01-2.363 0l-1.787-1.509-.002-.002a1.825 1.825 0 01-.399-2.301.75.75 0 01.046-.082 1.823 1.823 0 011.53-.838h.001zm3.837 1.648a.324.324 0 00-.27-.148H8.213a.323.323 0 00-.247.117.764.764 0 01-.023.038.325.325 0 00.062.423l1.787 1.509.002.001a.325.325 0 00.42.001l1.77-1.516h.002a.326.326 0 00.063-.425z"
        fill="#6C6CFF"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.15 10.118a.75.75 0 011.008-.33 4.734 4.734 0 012.592 4.21.75.75 0 11-1.5.004 3.234 3.234 0 00-1.77-2.876.75.75 0 01-.33-1.008zM8.85 10.118a.75.75 0 01-.33 1.008 3.233 3.233 0 00-1.77 2.876.75.75 0 01-1.5-.004 4.734 4.734 0 012.592-4.21.75.75 0 011.008.33z"
        fill="#6C6CFF"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.53 4.5a.75.75 0 01.75-.75h15.45a.75.75 0 010 1.5H2.28a.75.75 0 01-.75-.75zM.9 17a.75.75 0 01.75-.75h16.89a.75.75 0 010 1.5H1.65A.75.75 0 01.9 17z"
        fill="#323232"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.745 1.139A6.747 6.747 0 017.093.25h6.3c1.196 0 2.365.354 3.36 1.017a4.035 4.035 0 011.772 3.803l-.672 6.042a3.333 3.333 0 00.192 1.54l.746 1.988c.303.81.459 1.667.459 2.532a4.579 4.579 0 01-4.579 4.578H5.623A4.872 4.872 0 01.75 16.878c0-.67.12-1.335.356-1.963l.871-2.324c.17-.451.23-.936.177-1.415l-.63-5.67A4.463 4.463 0 013.745 1.14zm3.348.611c-.914 0-1.81.238-2.604.691a2.963 2.963 0 00-1.474 2.9l.63 5.67c.079.713-.011 1.435-.263 2.107l-.872 2.324a4.09 4.09 0 00-.26 1.436 3.372 3.372 0 003.372 3.372h9.05c1.7 0 3.078-1.378 3.078-3.078 0-.685-.123-1.364-.364-2.006l-.745-1.988a4.834 4.834 0 01-.279-2.232l.672-6.042a2.534 2.534 0 00-1.113-2.389 4.556 4.556 0 00-2.527-.765H7.092z"
        fill="#323232"
      />
    </Svg>
  );
}

export default SvgComponent;
