import i18n from 'i18n-js';
import React from 'react';
import { FlatList, TouchableOpacity, View } from 'react-native';
import styled from 'styled-components/native';

import _IconDoubleTick from '../../svg/IconDoubleTick';
import _IconSingleTick from '../../svg/IconSingleTick';
import { useTheme } from '../../template/ThemeProvider';

export default function MessageBubble({ text, isself, time, isDoubleTick }) {
  const { colors, isDark } = useTheme();

  return (
    <Container isself={isself}>
      {!isself && (
        <TextRecieved>
          <TextRecievedTxt>{text}</TextRecievedTxt>
        </TextRecieved>
      )}
      {isself && (
        <TextSendedContainer>
          {isDoubleTick ? <IconDoubleTick color={colors.light_blue_grey} /> : <IconSingleTick color={colors.light_blue_grey} />}
          <TextSended>
            <TextSendedTxt>{text}</TextSendedTxt>
          </TextSended>
        </TextSendedContainer>
      )}
      <TimeTxt isself={isself}>{time}</TimeTxt>
    </Container>
  );
}

const Container = styled.View`
  display: flex;
  /* align-items: center; */
  padding: 8px 20px;
  margin: ${(p) => (p?.isself ? '0 0 0 auto' : '0 auto 0 0')};
`;
const TextRecieved = styled.View`
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dusk : p.theme.myColors.pale_grey)};
  border-radius: 16px;
  padding: 10px 16px;
  border-bottom-left-radius: 0;
`;
const TextRecievedTxt = styled.Text`
  font-family: Gilroy-Medium;
  font-size: 12px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.metallic_blue)};
`;
const TextSendedContainer = styled.View`
  display: flex;
  flex-direction: row;
`;
const TextSended = styled.View`
  background-color: ${(p) => p.theme.myColors.cornflower};
  border-radius: 16px;
  padding: 10px 16px;
  border-bottom-right-radius: 0;
`;
const TextSendedTxt = styled.Text`
  font-family: Gilroy-Medium;
  font-size: 12px;
  color: ${(p) => p.theme.myColors.white};
`;
const IconDoubleTick = styled(_IconDoubleTick)`
  margin: auto 5px 5px 0;
`;
const IconSingleTick = styled(_IconSingleTick)`
  margin: auto 5px 5px 0;
`;
const TimeTxt = styled.Text`
  font-family: Gilroy-Medium;
  font-size: 11px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.bluey_grey : p.theme.myColors.bluey_grey)};
  margin: ${(p) => (!p?.isself ? '3px 4px 0 auto' : '3px auto 0 4px')};
`;
