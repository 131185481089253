import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 1.75A1.25 1.25 0 001.75 3v14A1.25 1.25 0 003 18.25h14A1.25 1.25 0 0018.25 17V3A1.25 1.25 0 0017 1.75H3zm-1.945-.695A2.75 2.75 0 013 .25h14A2.75 2.75 0 0119.75 3v14A2.75 2.75 0 0117 19.75H3A2.75 2.75 0 01.25 17V3c0-.73.29-1.429.805-1.945z"
        fill="#49497A"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.27 4.705a1.389 1.389 0 011.971.558l.002.003 1.026 2.065 2.289.33h.002a1.381 1.381 0 01.77 2.36l-1.657 1.601.391 2.266a1.386 1.386 0 01-2.012 1.452h-.002L10 14.27l-2.05 1.07H7.95a1.386 1.386 0 01-2.012-1.452v-.005l.391-2.264-1.656-1.602H4.67a1.381 1.381 0 01.77-2.36l2.292-.33 1.026-2.064c.115-.23.293-.423.512-.558zM10 6.136L8.9 8.348a.75.75 0 01-.564.408l-2.446.352 1.766 1.708a.75.75 0 01.218.666l-.418 2.424 2.197-1.146a.75.75 0 01.694 0l2.198 1.146-.418-2.42a.75.75 0 01.218-.666l1.766-1.708-2.446-.353a.75.75 0 01-.565-.408L10 6.136z"
        fill="#6C6CFF"
      />
    </Svg>
  );
}

export default SvgComponent;
