import React, { useEffect, useRef } from 'react';
import { BackHandler } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import { close } from '../../redux/slices/ActionSheetSlice';
import ActionSheet from './ActionSheet';
import { useTheme } from './ThemeProvider';

export default function ActionSheetProvider() {
  const refAcSheet = useRef();
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state.actionSheet);
  const { colors, isDark } = useTheme();

  useEffect(() => {
    let backHandler;

    const backAction = () => {
      dispatch(close());
      return true;
    };

    if (reduxState?.visible) {
      refAcSheet.current.open();
      backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);
    } else {
      refAcSheet.current.close();
      if (backHandler) backHandler.remove();
    }
    return () => {
      if (backHandler) backHandler.remove();
    };
  }, [reduxState?.visible]);

  return (
    <ActionSheet
      ref={refAcSheet}
      closeOnDragDown
      dragFromTopOnly
      onClose={() => dispatch(close())}
      height={reduxState?.height}
      customStyles={{
        wrapper: { backgroundColor: isDark ? `${colors.dark2}cc` : `${colors.dusk}80` },
        container: { backgroundColor: isDark ? colors.dark_grey_blue : colors.white },
      }}>
      {reduxState?.renderContent && typeof reduxState?.renderContent === 'function' && reduxState?.renderContent()}
    </ActionSheet>
  );
}
