import * as React from 'react';
import Svg, { Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const SvgComponent = (props) => (
  <Svg viewBox="0 0 257 238" width={65} height={65} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      d="M51.344 178.92C21.131 178.92.83 157.749.83 126.222V53.496C.83 21.98 21.131.806 51.344.806h77.28c30.21 0 50.499 21.175 50.499 52.69v72.726c0 31.516-20.301 52.683-50.506 52.683l-77.273.015Zm64.774-82.363h11.85v9.82c0 1.77.704 3.469 1.958 4.721a6.687 6.687 0 0 0 11.41-4.721V89.863a6.675 6.675 0 0 0-4.125-6.182 6.7 6.7 0 0 0-2.561-.512H83.904a23.176 23.176 0 0 0-9.77-12.88 23.217 23.217 0 0 0-30.001 4.41 23.16 23.16 0 0 0 0 30.293 23.218 23.218 0 0 0 30 4.412 23.178 23.178 0 0 0 9.77-12.881h18.832v9.82c0 1.772.705 3.471 1.959 4.724a6.69 6.69 0 0 0 11.417-4.724v-9.805l.007.019ZM51.996 89.87a9.81 9.81 0 0 1 6.058-9.08 9.836 9.836 0 0 1 10.714 2.122A9.813 9.813 0 0 1 70.9 93.613a9.834 9.834 0 0 1-9.085 6.058 9.843 9.843 0 0 1-6.935-2.875 9.823 9.823 0 0 1-2.884-6.926Z"
      fill="#9F64FF"
    />
    <Path
      d="M158.289 229.688c-23.34 4.959-42.501-8.061-47.684-32.406l-11.962-56.185c-5.182-24.344 7.015-44.031 30.355-48.99l59.695-12.683c23.336-4.958 42.49 8.068 47.673 32.413l11.962 56.184c5.183 24.345-7.017 44.024-30.349 48.982l-59.69 12.685Zm36.499-74.253 9.163-1.947 1.615 7.583a5.276 5.276 0 0 0 6.231 3.925 5.28 5.28 0 0 0 3.291-2.228 5.265 5.265 0 0 0 .802-3.89l-2.712-12.742a5.294 5.294 0 0 0-2.301-3.34 5.297 5.297 0 0 0-3.993-.727l-39.218 8.332a18.332 18.332 0 0 0-32.114-.015 18.283 18.283 0 0 0-1.866 12.625 18.3 18.3 0 0 0 6.848 10.775 18.329 18.329 0 0 0 12.232 3.682 18.325 18.325 0 0 0 11.668-5.199 18.296 18.296 0 0 0 5.428-11.553l14.547-3.091 1.614 7.583a5.282 5.282 0 0 0 10.333-2.195l-1.612-7.569.044-.009Zm-50.642 5.36a7.75 7.75 0 0 1 3.186-8.008 7.765 7.765 0 0 1 11.532 3.321 7.749 7.749 0 0 1-1.571 8.473 7.77 7.77 0 0 1-3.948 2.174 7.777 7.777 0 0 1-5.832-1.082 7.762 7.762 0 0 1-3.367-4.878Z"
      fill="url(#a)"
    />
    <Defs>
      <LinearGradient id="a" x1={235.96} y1={203.883} x2={136.769} y2={83.982} gradientUnits="userSpaceOnUse">
        <Stop stopColor="#F1EBFB" />
        <Stop offset={1} stopColor="#B07FFF" />
      </LinearGradient>
    </Defs>
  </Svg>
);

export default SvgComponent;
