function handleErrorResponse(responseMessages) {
  if (typeof responseMessages === 'string') {
    return responseMessages;
  } else {
    let errStr = '';
    responseMessages?.map((err) => {
      errStr = `${errStr} \n ${err}`;
    });

    return errStr;
  }
}

export default handleErrorResponse;
