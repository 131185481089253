import React from 'react';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';

import LogoDark from '../svg/LogoDark';
import LogoLight from '../svg/LogoLight';
import { useTheme } from '../template/ThemeProvider';

export default function BrandHeader({ leftNode = null, rightNode = null }) {
  const { isDark, colors } = useTheme();

  return (
    <Container>
      <IconBox>{leftNode}</IconBox>

      <LogoContainer>{isDark ? <LogoDark /> : <LogoLight />}</LogoContainer>

      <IconBox>{rightNode}</IconBox>
    </Container>
  );
}

const Container = styled.View`
  margin-top: 49px;
  margin-bottom: 29px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 0 30px; */
  justify-content: space-between;
  /* background-color: red; */
`;

const LogoContainer = styled.View`
  flex-shrink: 0;
  margin: 0;
`;

const IconBox = styled.View`
  width: 24px;
  height: 24px;
  ${(p) => !p.children && `border: none`}
`;
