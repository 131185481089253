import i18n from 'i18n-js';
import { useToast } from 'native-base';
import React, { useState, useEffect } from 'react';
import { BackHandler, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';

import HeaderWithIcons from '../../components/common/HeaderWithIcons';
import CharMan from '../../components/svg/CharMan';
import CharManGray from '../../components/svg/CharManGray';
import CharWoman from '../../components/svg/CharWoman';
import CharWomanGray from '../../components/svg/CharWomanGray';
import Tick1_ from '../../components/svg/Tick1';
import { useTheme } from '../../components/template/ThemeProvider';
import MYButton from '../../components/ui/MYButton';

export default function SelectGenderScreen({ route, navigation }) {
  const { colors, isDark, setScheme } = useTheme();
  const toast = useToast();
  const [loadingBtn, setloadingBtn] = useState(false);
  const [selectedGender, setSelectedGender] = useState('man');
  const { email, access_token } = route.params;

  function setGender(lang) {
    setSelectedGender(lang);
  }

  useEffect(() => {
    const backAction = () => {
      navigation.goBack();
      return true;
    };

    const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

    return () => backHandler.remove();
  }, []);

  function handlePressNext() {
    navigation.navigate('Auth_SelectName', {
      email,
      gender: selectedGender,
      access_token,
    });
  }

  return (
    <Container>
      <HeaderWithIcons title={i18n.t('auth.signup_header_title')} onPress={() => navigation.goBack()} />

      <SelectContainer>
        <Title>{i18n.t('auth.what_is_your_gender')}</Title>
        <TouchableOpacity onPress={() => setGender('man')}>
          <LanguageBox selected={selectedGender === 'man'}>
            {selectedGender === 'man' ? <CharMan /> : <CharManGray />}
            <LanguageBoxText selected={selectedGender === 'man'}>{i18n.t('auth.man')}</LanguageBoxText>
            {selectedGender === 'man' && <CheckedIcon width={22} height={22} />}
          </LanguageBox>
        </TouchableOpacity>

        <TouchableOpacity onPress={() => setGender('woman')}>
          <LanguageBox selected={selectedGender === 'woman'}>
            {selectedGender === 'woman' ? <CharWoman /> : <CharWomanGray />}
            <LanguageBoxText selected={selectedGender === 'woman'}>{i18n.t('auth.woman')}</LanguageBoxText>
            {selectedGender === 'woman' && <CheckedIcon width={22} height={22} />}
          </LanguageBox>
        </TouchableOpacity>
      </SelectContainer>

      <ButtonsContainer>
        <MYButton color="blue" style={{ width: '100%' }} onPress={handlePressNext} loading={loadingBtn}>
          {i18n.t('auth.continue')}
        </MYButton>
      </ButtonsContainer>
    </Container>
  );
}

const Container = styled.View`
  display: flex;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
  flex: 1;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
`;

const ButtonsContainer = styled.View`
  padding: 0 34px;
  width: 100%;
  margin-bottom: 28px;
  flex-shrink: 0;
`;

const SelectContainer = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 50px;
`;

const LanguageBox = styled.View`
  border: 1px solid;
  border-radius: 20px;
  padding: 7px 20px;
  border-color: ${(p) => (p.selected ? p.theme.myColors.bright_sky_blue : p.theme?.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey)};
  position: relative;
  width: 70%;
  margin: 0 auto 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
`;

const LanguageBoxText = styled.Text`
  font-family: Gilroy-Medium;
  text-align: center;
  margin-top: 12px;
  font-weight: 600;
  color: ${(p) =>
    p.selected
      ? p.theme?.isDark
        ? p.theme.myColors.white
        : p.theme.myColors.dark
      : p.theme?.isDark
      ? p.theme.myColors.light_blue_grey
      : p.theme.myColors.slate_blue};
`;

const CheckedIcon = styled(Tick1_)`
  position: absolute;
  top: 7px;
  right: -11px;
`;
const Title = styled.Text`
  font-family: Gilroy-Bold;
  margin-bottom: 59px;
  font-size: 22px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dusk)};
`;
