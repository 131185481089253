import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={28} height={28} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M14 27.334c-2.3 0-4.56-.598-6.56-1.735-.43-.32-.94-.515-1.473-.566-.173 0-.346.029-.51.084l-2.694.8a.999.999 0 01-.3.048.826.826 0 01-.667-.319.863.863 0 01-.129-.769l.894-2.992a1.394 1.394 0 00-.094-1.2 14.089 14.089 0 01-1.8-6.664A13.544 13.544 0 014.442 4.71 13.186 13.186 0 0114.027.667a13.154 13.154 0 019.493 3.992 13.411 13.411 0 012.8 4.243 13.186 13.186 0 011.014 5.077c.014 1.915-.38 3.81-1.153 5.562a12.8 12.8 0 01-3.033 4.2A13.66 13.66 0 0114 27.334zm6.12-15.009a1.708 1.708 0 10-.006 3.417 1.708 1.708 0 00.006-3.417zm-6.172 0a1.69 1.69 0 00-1.681 1.7 1.705 1.705 0 101.706-1.7h-.026.001zm-6.121 0a1.708 1.708 0 10-.006 3.417 1.708 1.708 0 00.006-3.417z"
        fill="#6C6CFF"
      />
    </Svg>
  );
}

export default SvgComponent;
