import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" color="#6C6CFF" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.747 9.045a1.25 1.25 0 00-.192-.666zm-1.5.52l-3.038 2.438 3.038 2.437V9.566zm.406 5.2zm1.094-5.72v5.916a1.25 1.25 0 01-2.032.975l-3.688-2.958.47-.586-.47.586a1.25 1.25 0 010-1.95l3.688-2.958a1.25 1.25 0 011.84.31M2.25 15.416c0 .236.067.466.192.666zm1.5-.52l3.039-2.438-3.039-2.437v4.874zm-.406-5.2zm-1.094 5.72V9.5a1.25 1.25 0 012.032-.975l3.688 2.958-.47.586.47-.586a1.25 1.25 0 010 1.95l-3.688 2.958a1.25 1.25 0 01-1.84-.31M12.003 2.253a.75.75 0 01.75.75v1a.75.75 0 01-1.5 0v-1a.75.75 0 01.75-.75zM12.003 7.253a.75.75 0 01.75.75v2a.75.75 0 01-1.5 0v-2a.75.75 0 01.75-.75zM12.003 13.253a.75.75 0 01.75.75v2a.75.75 0 01-1.5 0v-2a.75.75 0 01.75-.75zM12.003 19.253a.75.75 0 01.75.75v1a.75.75 0 01-1.5 0v-1a.75.75 0 01.75-.75z"
        fill="currentColor"
      />
    </Svg>
  );
}

export default SvgComponent;
