import { Actionsheet, Spinner, useToast } from 'native-base';
import React from 'react';
import i18n from 'i18n-js';
import { Alert, View, Platform } from 'react-native';
import styled from 'styled-components/native';
import MYButton from '../../ui/MYButton';

import IconDelete3 from '../../svg/IconDelete3';
import usersApi from '../../../apis/users';
import handleErrorResponse from '../../../services/handleErrorResponse';
import { useDispatch } from 'react-redux';
import { doLogOut } from '../../../redux/slices/UserSlice';

export default function ActionSheetDeleteAccount({ userId, onClose }) {
  const dispatch = useDispatch();
  const toast = useToast();

  function alert(title = undefined, msg) {
    if (Platform.OS === 'web') {
      toast.show({ title: title, status: "info", description: msg })
    } else {
      Alert.alert(title, msg, [{ text: 'ok', onPress: () => null, style: 'cancel' }]
      );
    }
  }

  function handleSubmit(reasonStr) {
    usersApi
      .deleteAccount()
      .then(async (res) => {
        dispatch(doLogOut());
        onClose();
      })
      .catch((error) => {
        if (error.response) {
          alert(null, handleErrorResponse(error.response.data?.message));
        }
      });
  }

  return (
    <Actionsheet.Content>
      <Container>
        <View>
          <Icon />
          <ReportTitle>{i18n.t('settings.menu_delete_account')}</ReportTitle>
          <ReportSubTitle>{i18n.t('settings.actionsheet_delete_account_description')}</ReportSubTitle>
        </View>

        <MYButton color="red" style={{ width: '100%', marginTop: 20 }} onPress={handleSubmit}>
          {i18n.t('settings.actionsheet_delete_account_btn_delete')}
        </MYButton>
        <MYButton color="grayOutline" style={{ width: '100%', marginTop: 20 }} onPress={onClose}>
          {i18n.t('userprofile.actionsheet_btn_cancel')}
        </MYButton>
      </Container>
    </Actionsheet.Content>
  );
}

const Container = styled.View`
  padding: 16px 25px 20px;
  width: 100%;
`;
const ReportTitle = styled.Text`
  font-family: Gilroy-SemiBold;
  font-size: 16px;
  /* color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dusk)}; */
  color: ${(p) => p.theme.myColors.coral};
  margin-top: 16px;
  text-align: center;
`;
const ReportSubTitle = styled.Text`
  font-family: Gilroy-Light;
  font-size: 14px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.light_blue_grey : p.theme.myColors.bluey_grey)};
  text-align: center;
  margin-top: 10px;
  margin-bottom: 40px;
`;

const Icon = styled(IconDelete3)`
  margin: 0 auto;
`;
