import { extendTheme } from 'native-base';

const theme = (colors, isDark) =>
  extendTheme({
    components: {
      Input: {
        baseStyle: {
          color: !isDark ? colors.dark : colors.white,
          textAlign: 'left',
          minHeight: 10,
        },
        defaultProps: {},
        variants: {
          unstyled: {
            _focus: { style: { boxShadow: 'none', color: !isDark ? colors.dark : colors.white } },
          },
        },
        sizes: {},
      },
      Checkbox: {
        baseStyle: {
          borderRadius: 'md',
          borderWidth: 2,
          bg: 'transparent',
          borderColor: isDark ? colors.slate_blue : colors.bluey_grey,
          _text: { color: !isDark ? colors.bluey_grey : colors.light_blue_grey, fontFamily: 'Gilroy-Medium' },
          _checked: {
            borderColor: colors.cornflower,
            bg: colors.cornflower,
          },
        },
        defaultProps: {},
        variants: {},
        sizes: {},
      },
      ActionsheetContent: {
        baseStyle: {
          bg: isDark ? colors.dark_grey_blue : colors.white,
        },
        defaultProps: {},
        variants: {},
        sizes: {},
      },
      ActionsheetItem: {
        baseStyle: {
          _light: {
            bg: isDark ? colors.dark_grey_blue : colors.white,
            _text: { color: !isDark ? colors.dark : colors.white },
          },
        },
        defaultProps: {},
        variants: {},
        sizes: {},
      },
    },
  });

export default theme;
