import apiService from '../services/apiService';

const usersApi = {
  async getSuggestionList() {
    return apiService.get('user/suggestion-cards');
  },
  async searchUsers(queryParams = null) {
    return apiService.get(`user/search${queryParams ? `?${queryParams}` : ''}`);
  },
  async fetchMyProfile() {
    return apiService.get('user/profile');
  },
  async updateProfile(body) {
    return apiService.put('user/profile', body);
  },
  async updateProfileAvatar(formData, conf) {
    return apiService.post('user/profile-avatar', formData, conf);
  },
  async updateProfileGallery(formData, conf) {
    return apiService.post('user/profile-image', formData, conf);
  },
  async deleteProfileGallery(imagestr) {
    return apiService.delete(`user/profile-image?imagestr=${imagestr}`);
  },
  async reportAUser(body) {
    return apiService.post(`report-user`, body);
  },
  async blockUser(body) {
    return apiService.post(`blocksystem/block`, body);
  },
  async unblockUser(body) {
    return apiService.post(`blocksystem/unblock`, body);
  },
  async blockedUsers() {
    return apiService.get(`blocksystem/blockeds`);
  },
  async deleteAccount() {
    return apiService.delete(`user/account`);
  },
};

export default usersApi;
