import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={19} height={16} viewBox="0 0 19 16" fill="#23282D" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M.031 7.28V7.237a.833.833 0 01.195-.315l6.6-6.67A.81.81 0 018 .251a.839.839 0 01.077 1.081L8 1.424 2.8 6.676h14.382a.83.83 0 01.111 1.653l-.111.007H2.8L8 13.587a.839.839 0 010 1.175.809.809 0 01-1.065.083l-.092-.08-6.6-6.669a.835.835 0 01-.212-.817z" />
    </Svg>
  );
}

export default SvgComponent;
