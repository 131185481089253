import * as React from 'react';
import Svg, { Path, Defs, LinearGradient, Stop } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={19} height={19} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M15.292 16.625H3.705a2.225 2.225 0 01-2.122-2.207c0-.29.056-.576.167-.843L7.544 3.51c.202-.362.504-.659.87-.855a2.259 2.259 0 013.048.862l5.754 9.987c.113.25.18.518.2.792a2.18 2.18 0 01-.578 1.6c-.4.437-.955.699-1.546.729zm-5.793-4.534a.692.692 0 000 1.382.694.694 0 00.485-1.183.686.686 0 00-.485-.199zm0-4.894a.69.69 0 00-.7.683v2.219a.7.7 0 00.7.691.69.69 0 00.684-.691V7.875a.686.686 0 00-.684-.678z"
        fill="url(#prefix__paint0_linear)"
      />
      <Defs>
        <LinearGradient id="prefix__paint0_linear" x1={6.841} y1={6.522} x2={16.248} y2={17.618} gradientUnits="userSpaceOnUse">
          <Stop stopColor="#F53F5F" />
          <Stop offset={1} stopColor="#F53F5F" stopOpacity={0} />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
