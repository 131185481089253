import React from 'react';
import styled from 'styled-components/native';

import IconArrowRight from '../svg/IconArrowRight';

export default function HeaderWithMoreArrow({ leftIcon, titleBold, title, subTitle, onPressMore = () => {} }) {
  return (
    <Container>
      <Header>
        {leftIcon && leftIcon}
        <TitleContainer>
          <TitleBox>
            <TitleBold>{titleBold}</TitleBold>
            <TitleLight>{title}</TitleLight>
          </TitleBox>
          {subTitle && <SubTitle>{subTitle}</SubTitle>}
        </TitleContainer>
        <MoreArrowBtn onPress={onPressMore}>
          <IconArrowRight color="#fff" />
        </MoreArrowBtn>
      </Header>
    </Container>
  );
}

const Container = styled.View`
  position: relative;
  padding-bottom: 13px;
`;

const Header = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TitleContainer = styled.View`
  flex: 1;
`;
const TitleBox = styled.View`
  display: flex;
  flex-direction: row;
`;
const SubTitle = styled.Text`
  font-family: Gilroy-Light;
  font-size: 12px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dark)};
  text-align: left;
`;
const TitleBold = styled.Text`
  font-family: Gilroy-Bold;
  margin-right: 5px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dark)};
`;
const TitleLight = styled.Text`
  font-family: Gilroy-Light;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dark)};
`;
const MoreArrowBtn = styled.TouchableOpacity`
  background: ${(p) => p.theme.myColors.coral};
  padding: 4px 0 0 6px;
  border-radius: 100px;
  width: 18px;
  height: 18px;
`;
