import * as React from 'react';
import Svg, { Path, G } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={95} height={95} viewBox="0 0 95 95" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M-589-704h889v874h-889z" />
      <G opacity={0.53}>
        <Path opacity={0.17} d="M95 95c52.467 0 95-42.533 95-95s-42.533-95-95-95S0-52.467 0 0s42.533 95 95 95z" fill="#6527D0" />
        <Path opacity={0.27} d="M95 77c42.526 0 77-34.474 77-77s-34.474-77-77-77S18-42.526 18 0s34.474 77 77 77z" fill="#5622AE" />
      </G>
    </Svg>
  );
}

export default SvgComponent;
