export default {
  dusk: '#49497a',
  dusk2: '#484664',
  bright_sky_blue: '#20caff',
  white: '#ffffff',
  dark: '#23282d',
  dark2: '#221f3b',
  dark3: '#2b274a',
  coral: '#ff5d3b',
  slate_blue: '#67679b',
  cornflower: '#6c6cff',
  cornflower2: '#6161f5',
  bluey_grey: '#9c9cc2',
  pale_grey: '#e7e7f1',
  pale_grey2: '#efeff9',
  light_blue_grey: '#c2c2e7',
  purplish_blue: '#6d28fc',
  watermelon: '#f53f5f',
  dark_grey_blue: '#332f53',
  dark_grey_blue2: '#39345f',
  dark_grey_blue3: '#3c3356',
  lipstick: '#ea3756',
  denim_blue: '#3b5998',
  bright_lavender: '#a651ff',
  pale_lilac: '#ebedff',
  metallic_blue: '#536b88',
};
