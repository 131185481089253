import * as SecureStore from 'expo-secure-store';
import { Platform } from 'react-native';

export async function saveToSecureStore(key, value) {
  if (Platform.OS === 'web') {
    const result = localStorage.setItem(key, value);

    return result || null;
  }

  await SecureStore.setItemAsync(key, value);

  return true;
}

export async function getValueFromSecureStore(key) {
  if (Platform.OS === 'web') {
    const result = localStorage.getItem(key);

    return result || null;
  }

  const result = await SecureStore.getItemAsync(key);

  if (result) {
    return result;
  }

  return null;
}

export async function deleteValueFromSecureStore(key) {
  if (Platform.OS === 'web') {
    const result = localStorage.removeItem(key);

    return result || null;
  }

  const result = await SecureStore.deleteItemAsync(key);

  if (result) {
    return result;
  }

  return null;
}
