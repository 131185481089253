import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={27} height={27} viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M13.333 26.667A13.336 13.336 0 013.9 3.907a13.335 13.335 0 1118.86 18.858 13.249 13.249 0 01-9.427 3.902zM17.9 8.158a.61.61 0 00-.183.029l-6.823 2.133a.888.888 0 00-.587.587l-2.134 6.84a.58.58 0 00.086.524.6.6 0 00.481.25c.061 0 .122-.01.18-.029l6.8-2.133a.86.86 0 00.587-.587l2.16-6.826a.606.606 0 00-.299-.724.594.594 0 00-.268-.064z"
        fill="#6C6CFF"
      />
    </Svg>
  );
}

export default SvgComponent;
