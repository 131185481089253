import i18n from 'i18n-js';
import { useToast } from 'native-base';
// import DatePicker from 'react-native-date-picker';
import React, { useState, useEffect } from 'react';
import { Alert, BackHandler, StyleSheet, Platform } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import OTPTextView from 'react-native-otp-textinput';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/native';

import authApi from '../../apis/auth';
import HeaderWithIcons from '../../components/common/HeaderWithIcons';
import IconVerification from '../../components/svg/IconVerification';
import { useTheme } from '../../components/template/ThemeProvider';
import MYButton from '../../components/ui/MYButton';
import { doLogin } from '../../redux/slices/UserSlice';
import handleErrorResponse from '../../services/handleErrorResponse';

export default function Verification({ route, navigation }) {
  const dispatch = useDispatch();
  const { colors, isDark, setScheme } = useTheme();
  const toast = useToast();
  const [loadingApi, setloadingApi] = useState(false);
  const [codeValue, setcodeValue] = useState(null);
  const { email, password } = route.params;

  function alert(title = undefined, msg) {
    if (Platform.OS === 'web') {
      toast.show({ title: title, status: 'info', description: msg });
    } else {
      Alert.alert(title, msg, [{ text: 'ok', onPress: () => null, style: 'cancel' }]);
    }
  }

  useEffect(() => {
    const backAction = () => {
      navigation.goBack();
      return true;
    };

    const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

    return () => backHandler.remove();
  }, []);

  function needToCompleteProfile(access_token) {
    // Do NOT store token. still not logged in!
    navigation.navigate('Auth_SelectGender', {
      email,
      access_token: `${access_token}`,
    });
  }

  async function handleLogin(access_token, userInfo) {
    // dispatch login
    dispatch(doLogin({ access_token: `${access_token}`, userInfo }));
  }

  function handlePressNext() {
    if (!codeValue || codeValue?.length !== 4) {
      toast.show({
        description: 'please enter code',
      });
      return;
    }

    authApi
      .emailVerify({
        username: email?.replace(' ', '').toLowerCase(),
        password,
        verificationcode: codeValue,
      })
      .then(async (res) => {
        setloadingApi(false);

        if (res?.data?.access_token) {
          if (res?.data?.isCompleteBasicProfile === false || !res?.data?.userInfo?.name) {
            return needToCompleteProfile(res?.data?.access_token);
          }

          return handleLogin(res?.data?.access_token, res?.data?.userInfo);
        } else {
          alert('Error', 'Code is wrong');
        }

        setloadingApi(false);
      })
      .catch((error) => {
        setloadingApi(false);
        // console.log(error, error.response);

        alert('Error', error.response ? handleErrorResponse(error.response.data?.message) : 'Error in saving data');
      });
  }

  function handleResendCode() {
    setloadingApi(true);

    authApi
      .resendEmailVerifyCode({
        email: email?.replace(' ', '').toLowerCase(),
      })
      .then(async (res) => {
        setloadingApi(false);

        alert('Success', res?.data?.message || 'Email Sended');
      })
      .catch((error) => {
        setloadingApi(false);
        // console.log(error, error.response);

        alert('Error', error.response ? handleErrorResponse(error.response.data?.message) : 'Error in saving data');
      });
  }

  const otpStyles = StyleSheet.create({
    containerStyle: {
      // marginBottom: 20,
      width: '100%',
    },
    textInputStyle: {
      color: isDark ? colors.white : colors.dusk,
      borderRadius: 18,
      borderWidth: 1,
      borderBottomWidth: 1,
      width: 60,
      height: 60,
      fontSize: 18,
      // backgroundColor: '#20caff87',
      // backgroundColor: isDark ? colors.bright_sky_blue : colors.white,
    },
  });

  return (
    <Container>
      <HeaderWithIcons title={i18n.t('auth.verification_header_title')} onPress={() => navigation.goBack()} />
      <InnerContainer>
        <SelectContainer>
          <TopImage>
            <IconVerification />
          </TopImage>

          <Title>{i18n.t('auth.please_enter_the_4_digital_code_we_have_just_sent_to')}</Title>
          <EmailContainer>
            <EmailText>{email}</EmailText>
          </EmailContainer>

          <OTPTextView
            handleTextChange={(v) => setcodeValue(v)}
            containerStyle={otpStyles.containerStyle}
            textInputStyle={otpStyles.textInputStyle}
            tintColor={colors.bright_sky_blue}
            offTintColor={isDark ? colors.dusk2 : colors.pale_grey}
            keyboardType="numeric"
          />
          <TouchableOpacity onPress={handleResendCode}>
            <ResendCode>{i18n.t('auth.resend_code')}</ResendCode>
          </TouchableOpacity>
        </SelectContainer>
      </InnerContainer>

      <ButtonsContainer>
        <MYButton color="blue" style={{ width: '100%' }} onPress={handlePressNext} loading={loadingApi}>
          {i18n.t('auth.continue')}
        </MYButton>
      </ButtonsContainer>
    </Container>
  );
}

const TopImage = styled.View`
  margin: 0 auto 26px;
`;

const Title = styled.Text`
  font-family: Gilroy-Medium;
  margin-bottom: 14px;
  padding: 0 35px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dusk)};
`;

const EmailContainer = styled.View`
  margin: 0 35px 44px;
  padding: 14px 10px;
  width: auto;
  background-color: ${(p) => (p.theme?.isDark ? `${p.theme.myColors.coral}26` : `${p.theme.myColors.coral}0f`)};
  border-radius: 11px;
`;
const EmailText = styled.Text`
  font-family: Gilroy-Medium;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: ${(p) => p.theme.myColors.coral};
`;

const ResendCode = styled.Text`
  font-family: Gilroy-Medium;
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  margin: 23px 0 0 0;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.slate_blue : p.theme.myColors.bluey_grey)};
`;

const Container = styled.KeyboardAvoidingView`
  display: flex;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
  flex: 1;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
`;
const InnerContainer = styled.ScrollView`
  width: 100%;
`;
const ButtonsContainer = styled.View`
  padding: 0 34px;
  width: 100%;
  margin-bottom: 28px;
  flex-shrink: 0;
`;

const SelectContainer = styled.View`
  margin-top: 7%;
  flex: 1;
  width: 100%;
  padding: 0 35px;
`;
