import i18n from 'i18n-js';
import React, { useState } from 'react';
import { FlatList, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';

import IconSend from '../../svg/IconSend';
import { useTheme } from '../../template/ThemeProvider';
import Myinput from '../../ui/MYInput';

export default function InputSendMsg({ handleSendMsg, onChange }) {
  const { colors, isDark } = useTheme();
  const [Value, setValue] = useState(null);

  return (
    <>
      <Container>
        <InputBox>
          <Myinput
            variant="unstyled"
            // InputLeftElement={<IconEmail color={isDark ? colors.bluey_grey : colors.dark} />}
            placeholder={i18n.t('chat.type_a_message')} // mx={4}
            value={Value || ''}
            onChangeText={(txt) => {
              setValue(txt);
              onChange(txt);
            }}
          />
        </InputBox>
        <TouchableOpacity
          onPress={() => {
            Value && handleSendMsg && handleSendMsg(Value);
            setValue(null);
          }}>
          <SendBtn>
            <IconSend color="#fff" />
          </SendBtn>
        </TouchableOpacity>
      </Container>
    </>
  );
}

const Container = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 28px 20px;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
`;
const InputBox = styled.View`
  border-radius: 23px;
  background-color: ${(p) => (p.theme.isDark ? p.theme.myColors.dusk : p.theme.myColors.pale_grey2)};
  /* padding: 13px 20px; */
  flex: 1;
`;
const SendBtn = styled.View`
  border-radius: 1000px;
  background-color: ${(p) => p.theme.myColors.cornflower};
  padding: 13px 0 0 13px;
  margin-left: 8px;
  width: 45px;
  height: 45px;
`;
