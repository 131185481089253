import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={4} height={16} viewBox="0 0 4 16" color="#23282D" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M.687 14.085a1.644 1.644 0 113.288.006 1.644 1.644 0 01-3.288-.006zm0-5.755a1.644 1.644 0 110 .002V8.33zm0-5.755a1.644 1.644 0 113.288 0 1.644 1.644 0 01-3.288 0z"
        fill="currentColor"
      />
    </Svg>
  );
}

export default SvgComponent;
