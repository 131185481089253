import * as React from 'react';
import Svg, { G, Path, Defs, ClipPath } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={17} height={17} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath="url(#prefix__clip0)">
        <Path d="M8.298 15.821a7.523 7.523 0 010-15.046 7.523 7.523 0 010 15.046z" fill="#20CAFF" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M8.298 1.55a6.748 6.748 0 100 13.496 6.748 6.748 0 000-13.496zm-5.868.88a8.298 8.298 0 1111.736 11.736A8.298 8.298 0 012.43 2.43z" fill="#fff" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M11.967 6.113a.775.775 0 010 1.096l-3.9 3.9a.775.775 0 01-1.096 0L4.63 8.77a.775.775 0 011.096-1.096l1.794 1.792 3.352-3.352a.775.775 0 011.096 0z" fill="#fff" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" d="M0 0h16.596v16.596H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
