import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" color="#000" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M8.027 12.666a.549.549 0 01-.26-.079 12.299 12.299 0 01-3.226-3.032 6.151 6.151 0 01-1.208-3.466 4.777 4.777 0 012.354-4.131 4.593 4.593 0 014.69.038 4.836 4.836 0 011.684 1.783c.402.736.61 1.562.605 2.4a7.01 7.01 0 01-2.133 4.512 12.497 12.497 0 01-2.239 1.88.781.781 0 01-.271.1l.004-.005zm-.033-8.1a1.561 1.561 0 00-1.119.467 1.63 1.63 0 00-.342 1.763 1.58 1.58 0 001.465 1h.011a1.55 1.55 0 001.112-.468 1.637 1.637 0 00-.512-2.637 1.552 1.552 0 00-.614-.126l-.001.001z"
        fill="currentColor"
      />
      <Path
        opacity={0.4}
        d="M7.999 14.667c1.84 0 3.333-.299 3.333-.667 0-.368-1.492-.667-3.333-.667-1.84 0-3.333.299-3.333.667 0 .368 1.492.667 3.333.667z"
        fill="currentColor"
      />
    </Svg>
  );
}

export default SvgComponent;
