import { Select, VStack } from 'native-base';
import React from 'react';

import { useTheme } from '../template/ThemeProvider';

export default function MySelect(props) {
  const { isDark = false } = useTheme();
  // const isDark = false;
  return (
    <VStack alignItems="center" space={4}>
      <Select {...props} style={{ color: isDark ? '#ffffff' : '#000' }} borderColor="transparent">
        {props?.options?.map((item) => (
          <Select.Item label={item?.label} value={item?.value} />
        ))}
      </Select>
    </VStack>
  );
}
