import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={28} height={28} viewBox="0 0 28 28" color="#C2C2E7" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M7.758 25.797l-.061-.022a.023.023 0 01-.013-.016l-.91-.516a1 1 0 00-.367-.072.62.62 0 00-.15.016c-.946.34-1.912.62-2.894.836l-.18.022h-.055a1.49 1.49 0 01-1.177-.473 1.834 1.834 0 01-.394-1.224l.026-.218c.238-.997.539-1.98.9-2.939a.706.706 0 00-.058-.54l-.245-.478A13.34 13.34 0 0114.027.667h.052a13.333 13.333 0 11-6.32 25.13h-.001zm-.228-2.251c.122.059.278.143.493.263l.563.324h.008l.383.194a11.509 11.509 0 0012.3-1.468l.294-.25a11.474 11.474 0 00-7.504-20.082h-.374a11.47 11.47 0 00-9.857 16.79l.241.47a2.592 2.592 0 01.168 2.006c-.34.906-.626 1.833-.853 2.774l.125-.491.527-.138c.38-.106.738-.214 1.093-.331l.558-.192c.235-.065.477-.099.721-.1.383.003.761.081 1.114.231zM18.467 14a1.522 1.522 0 113.045 0 1.522 1.522 0 01-3.045 0zm-5.916 0a1.522 1.522 0 113.048 0 1.522 1.522 0 01-3.05 0h.002zm-5.916 0a1.522 1.522 0 113.048 0 1.522 1.522 0 01-3.05 0h.002z"
        fill="currentColor"
      />
    </Svg>
  );
}

export default SvgComponent;
