import { Actionsheet, Spinner, useDisclose, useToast } from 'native-base';
import React, { useState } from 'react';
import i18n from 'i18n-js';
import { Alert, View, Platform } from 'react-native';
import styled from 'styled-components/native';
import MYButton from '../../ui/MYButton';

import IconBlockSingleColor from '../../svg/IconBlockSingleColor';
import IconWarning from '../../svg/IconWarning';
import IconImage from '../../svg/IconImage';
import IconPaper from '../../svg/IconPaper';
import usersApi from '../../../apis/users';
import { useTheme } from '../../template/ThemeProvider';
import handleErrorResponse from '../../../services/handleErrorResponse';

export default function ActionSheetReport({ userId, onClose }) {
  const [reportStep, setreportStep] = useState(0); // 0: select | 1: loading | 2: report | 3: blocked | 4 :unblocked
  const { isDark, colors } = useTheme();
  const toast = useToast();

  function alert(title = undefined, msg) {
    if (Platform.OS === 'web') {
      toast.show({ title: title, status: 'info', description: msg });
    } else {
      Alert.alert(title, msg, [{ text: 'ok', onPress: () => null, style: 'cancel' }]);
    }
  }

  function handleReportUser(reasonStr) {
    setreportStep(1);

    usersApi
      .reportAUser({
        userId: userId,
        reasonDesc: reasonStr,
      })
      .then(async (res) => {
        onClose();

        alert('Success!', 'your report submitted!');
      })
      .catch((error) => {
        setreportStep(2);

        if (error.response) {
          alert('Error', handleErrorResponse(error.response.data?.message));
        }
      });
  }

  function handleBlockUser() {
    setreportStep(1);

    usersApi
      .blockUser({
        userId: userId,
      })
      .then(async (res) => {
        setreportStep(3);
      })
      .catch((error) => {
        setreportStep(0);

        if (error.response) {
          alert('Error', handleErrorResponse(error.response.data?.message));
        }
      });
  }

  function handleUnBlockUser() {
    setreportStep(1);

    usersApi
      .unblockUser({
        userId: userId,
      })
      .then(async (res) => {
        onClose();

        alert('Success!', 'successfull');
      })
      .catch((error) => {
        setreportStep(0);

        if (error.response) {
          alert('Error', handleErrorResponse(error.response.data?.message));
        }
      });
  }

  return (
    <Actionsheet.Content>
      <Container>
        {reportStep === 0 && (
          <View>
            <Actionsheet.Item onPress={() => handleBlockUser()}>
              <MenuItem>
                <ActionBlockContainer>
                  <IconBlockSingleColor color={isDark ? '#fff' : '#000'} />
                </ActionBlockContainer>
                <MenuItemTxt>{i18n.t('userprofile.menu_block_the_user')}</MenuItemTxt>
              </MenuItem>
            </Actionsheet.Item>
            <Actionsheet.Item onPress={() => setreportStep(2)}>
              <MenuItem>
                <ActionBlockContainer>
                  <IconWarning color="#f53f5f" style={{ marginLeft: -3, marginTop: -2 }} />
                </ActionBlockContainer>
                <MenuItemTxt red>{i18n.t('userprofile.menu_report_the_user')}</MenuItemTxt>
              </MenuItem>
            </Actionsheet.Item>
            <MYButton color="grayOutline" style={{ width: '100%', marginTop: 20 }} onPress={onClose}>
              {i18n.t('userprofile.actionsheet_btn_cancel')}
            </MYButton>
          </View>
        )}
        {reportStep === 1 && (
          <View style={{ paddingBottom: 30 }}>
            <Spinner size="lg" style={{ marginTop: 50 }} />
          </View>
        )}
        {reportStep === 2 && (
          <View>
            <View>
              <ReportTitle>{i18n.t('userprofile.actionsheet_report_title')}</ReportTitle>
              <ReportSubTitle>{i18n.t('userprofile.actionsheet_report_description')}</ReportSubTitle>
            </View>
            <Actionsheet.Item onPress={() => handleReportUser('Inappropriate Picture')}>
              <MenuItem>
                <ActionBlockContainer>
                  <IconImage color={isDark ? '#fff' : '#000'} />
                </ActionBlockContainer>
                <MenuItemTxt>{i18n.t('userprofile.actionsheet_report_opt1')}</MenuItemTxt>
              </MenuItem>
            </Actionsheet.Item>
            <Actionsheet.Item onPress={() => handleReportUser('Inappropriate Content')}>
              <MenuItem>
                <ActionBlockContainer>
                  <IconPaper color={isDark ? '#fff' : '#000'} />
                </ActionBlockContainer>
                <MenuItemTxt>{i18n.t('userprofile.actionsheet_report_opt2')}</MenuItemTxt>
              </MenuItem>
            </Actionsheet.Item>
            <MYButton color="grayOutline" style={{ width: '100%', marginTop: 20 }} onPress={onClose}>
              {i18n.t('userprofile.actionsheet_btn_cancel')}
            </MYButton>
          </View>
        )}
        {reportStep === 3 && (
          <View>
            <BlockedTitle>{i18n.t('userprofile.actionsheet_blocked_title')}</BlockedTitle>
            <BlockedDesc>{i18n.t('userprofile.actionsheet_blocked_description')}</BlockedDesc>
            <MYButton color="red" style={{ width: '100%', marginTop: 24 }} onPress={handleUnBlockUser}>
              {i18n.t('userprofile.actionsheet_blocked_btn_unblock')}
            </MYButton>
            <MYButton color="grayOutline" style={{ width: '100%', marginTop: 20 }} onPress={onClose}>
              {i18n.t('global.close')}
            </MYButton>
          </View>
        )}
      </Container>
    </Actionsheet.Content>
  );
}

const Container = styled.View`
  padding: 16px 25px 20px;
  width: 100%;
`;
const ReportTitle = styled.Text`
  font-family: Gilroy-SemiBold;
  font-size: 16px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dusk)};
  margin-top: 2px;
  text-align: center;
`;
const ReportSubTitle = styled.Text`
  font-family: Gilroy-Light;
  font-size: 14px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.light_blue_grey : p.theme.myColors.bluey_grey)};
  text-align: center;
  margin-bottom: 40px;
`;
const MenuItem = styled.View`
  display: flex;
  flex-direction: row;
`;
const MenuItemTxt = styled.Text`
  font-family: Gilroy-Light;
  font-size: 14px;
  color: ${(p) => (p?.red ? p.theme.myColors.watermelon : p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dark2)};
  text-align: center;
  font-weight: 500;
  margin-bottom: 8px;
  margin-left: 15px;
`;
const ActionBlockContainer = styled.View`
  width: 15px;
  height: 15px;
`;
const BlockedTitle = styled.Text`
  font-family: Gilroy-Bold;
  font-size: 16px;
  color: ${(p) => p.theme.myColors.coral};
  text-align: center;
  margin-top: 24px;
`;
const BlockedDesc = styled.Text`
  font-family: Gilroy-Medium;
  font-size: 14px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.light_blue_grey : p.theme.myColors.bluey_grey)};
  text-align: center;
  margin-top: 15px;
`;
