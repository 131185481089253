import { Spinner, HStack, Center, NativeBaseProvider, View } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { Text, TouchableOpacity, StyleSheet } from 'react-native';
import styled from 'styled-components/native';

import { useTheme } from '../template/ThemeProvider';

const bgColorMap = {
  light: {
    blue: '#6C6CFF',
    red: '#FF5D3B',
    slateblue: '#67679b',
    grayOutline: 'transparent',
  },
  dark: {
    blue: '#6C6CFF',
    red: '#FF5D3B',
    slateblue: '#67679b',
    grayOutline: 'transparent',
  },
};

const borderColorMap = {
  light: {
    grayOutline: '#e7e7f1',
  },
  dark: {
    grayOutline: '#484664',
  },
};

const txtColorMap = {
  light: {
    blue: '#fff',
    red: '#fff',
    slateblue: '#fff',
    grayOutline: '#221f3b',
  },
  dark: {
    blue: '#fff',
    red: '#fff',
    slateblue: '#fff',
    grayOutline: '#fff',
  },
};

function MYButton({ onPress, children, style, color, loading = false, icon }) {
  const { colors, isDark } = useTheme();

  const styles = StyleSheet.create({
    btn: {
      borderRadius: 18,
      backgroundColor: bgColorMap[isDark ? 'dark' : 'light'][color],
      borderWidth: borderColorMap[isDark ? 'dark' : 'light'][color] ? 1 : 0,
      borderColor: borderColorMap[isDark ? 'dark' : 'light'][color],
      padding: 18,
      shadowOffset: { width: 0, height: 5 },
      shadowColor: bgColorMap[isDark ? 'dark' : 'light'][color],
      shadowOpacity: 0.2,
      shadowRadius: 26,
    },
    txt: {
      color: txtColorMap[isDark ? 'dark' : 'light'][color],
      textAlign: 'center',
      fontFamily: 'Gilroy-Medium',
      padding: 1,
      fontSize: 17,
    },
  });

  return (
    <TouchableOpacity onPress={onPress} style={[styles.btn, style || {}]}>
      <Container>
        {icon && icon}
        <Text style={styles.txt}>{children}</Text>
        {loading && <Spinner_ size="sm" color="white" />}
      </Container>
    </TouchableOpacity>
  );
}

const Container = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Spinner_ = styled(Spinner)`
  margin-left: 7px;
`;

MYButton.propTypes = {
  color: PropTypes.oneOf(['blue', 'red', 'slateblue', 'grayOutline']),
  onPress: PropTypes.func,
  children: PropTypes.node,
  style: PropTypes.object,
  icon: PropTypes.node,

  // size: PropTypes.oneOf(["large", "medium", "small"]),
  // onClick: PropTypes.func,
  // className: PropTypes.string,
  // disabled: PropTypes.bool,
  // isLoading: PropTypes.bool,
  // type: PropTypes.string,
};

MYButton.defaultProps = {
  color: 'blue',
  icon: null,
  // size: "medium",
  // disabled: false,
  // onClick: null,
  // className: "",
  // isLoading: undefined,
  // type: "button",
};

export default MYButton;
