import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={16} height={20} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.75A3.25 3.25 0 004.75 5v3a.75.75 0 01-1.5 0V5a4.75 4.75 0 119.5 0v3a.75.75 0 01-1.5 0V5A3.25 3.25 0 008 1.75z"
        fill="#6C6CFF"
      />
      <Path fillRule="evenodd" clipRule="evenodd" d="M8 11.25a.75.75 0 01.75.75v3a.75.75 0 01-1.5 0v-3a.75.75 0 01.75-.75z" fill="#49497A" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 8.75A1.25 1.25 0 001.75 10v7A1.25 1.25 0 003 18.25h10A1.25 1.25 0 0014.25 17v-7A1.25 1.25 0 0013 8.75H3zm-1.945-.695A2.75 2.75 0 013 7.25h10A2.75 2.75 0 0115.75 10v7A2.75 2.75 0 0113 19.75H3A2.75 2.75 0 01.25 17v-7c0-.73.29-1.429.805-1.945z"
        fill="#49497A"
      />
    </Svg>
  );
}

export default SvgComponent;
