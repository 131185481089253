import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8.5a.934.934 0 00-.934.934v1.316a.75.75 0 01-1.5 0V9.434a2.434 2.434 0 114.868 0v1.316a.75.75 0 01-1.5 0V9.434A.934.934 0 0012 8.5z"
        fill="#FF5D3B"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 11.5a.25.25 0 00-.25.25v3A.25.25 0 0010 15h4a.25.25 0 00.25-.25v-3a.25.25 0 00-.25-.25h-4zm-1.237-.987A1.75 1.75 0 0110 10h4a1.75 1.75 0 011.75 1.75v3A1.75 1.75 0 0114 16.5h-4a1.75 1.75 0 01-1.75-1.75v-3c0-.464.184-.91.513-1.237zM3.839 4.015a.75.75 0 01.75.75V6.75h1.984a.75.75 0 010 1.5H3.839a.75.75 0 01-.75-.75V4.765a.75.75 0 01.75-.75z"
        fill="#FF5D3B"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.132 3.556a9.74 9.74 0 0113.312 3.575.75.75 0 01-1.3.748 8.24 8.24 0 00-14.28-.004.75.75 0 01-1.299-.75 9.74 9.74 0 013.567-3.569zM3.17 10.13c.41.053.7.43.647.84a8.226 8.226 0 008.18 9.28L12 21l.002-.75a8.225 8.225 0 008.18-9.28.75.75 0 111.487-.192A9.728 9.728 0 0112 21.75a9.726 9.726 0 01-9.67-10.972.75.75 0 01.84-.648z"
        fill="#FF5D3B"
      />
    </Svg>
  );
}

export default SvgComponent;
