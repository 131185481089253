import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={18} height={18} viewBox="0 0 18 18" color="#49497A" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.005.237a8.765 8.765 0 11-.007 17.53v-.002A8.762 8.762 0 01.238 9v-.012A8.889 8.889 0 018.994.238l.011-.001zM9 16.267a7.265 7.265 0 00.011-14.53 7.389 7.389 0 00-7.272 7.269 7.263 7.263 0 007.261 7.259v.002z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2 2.808a.75.75 0 010 1.06L3.867 15.2a.75.75 0 01-1.06-1.06L14.14 2.808a.75.75 0 011.06 0z"
        fill="currentColor"
      />
    </Svg>
  );
}

export default SvgComponent;
