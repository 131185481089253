import { Ionicons } from '@expo/vector-icons';
import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import * as React from 'react';

export default function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync();

        // Load fonts
        await Font.loadAsync({
          ...Ionicons.font,
          'Gilroy-UltraLight': require('../assets/fonts/Gilroy-UltraLight.ttf'),
          'Gilroy-Light': require('../assets/fonts/Gilroy-Light.ttf'),
          'Gilroy-Regular': require('../assets/fonts/Gilroy-Regular.ttf'),
          'Gilroy-Medium': require('../assets/fonts/Gilroy-Medium.ttf'),
          'Gilroy-SemiBold': require('../assets/fonts/Gilroy-SemiBold.ttf'),
          'Gilroy-Bold': require('../assets/fonts/Gilroy-Bold.ttf'),
          'Gilroy-ExtraBold': require('../assets/fonts/Gilroy-ExtraBold.ttf'),
        });
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setTimeout(() => {
          setLoadingComplete(true);
          SplashScreen.hideAsync();
        }, 500);
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  return isLoadingComplete;
}
