import i18n from 'i18n-js';
import React, { useState, useEffect } from 'react';
import { BackHandler } from 'react-native';
import styled from 'styled-components/native';

import HeaderWithIcons from '../../components/common/HeaderWithIcons';
import IconUser from '../../components/svg/IconUser';
import { useTheme } from '../../components/template/ThemeProvider';
import MYButton from '../../components/ui/MYButton';
import Myinput from '../../components/ui/MYInput';

export default function SelectName({ route, navigation }) {
  const { colors, isDark, setScheme } = useTheme();
  const [loadingBtn, setloadingBtn] = useState(false);
  const { email, gender, access_token } = route.params;
  const [nameValue, setNameValue] = useState(null);

  // console.log('name', access_token);
  useEffect(() => {
    const backAction = () => {
      navigation.goBack();
      return true;
    };

    const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

    return () => backHandler.remove();
  }, []);

  function handlePressNext() {
    if (!nameValue) {
      // toast
      // toast.show({
      //   description: 'please enter your email',
      // });
      return;
    }
    navigation.navigate('Auth_SelectAge', {
      email,
      gender,
      access_token,
      name: nameValue,
    });
  }

  return (
    <Container>
      <HeaderWithIcons title={i18n.t('auth.signup_header_title')} onPress={() => navigation.goBack()} />
      <InnerContainer>
        <SelectContainer>
          <Title>{i18n.t('auth.your_frist_name')}</Title>
          <InputsBox>
            <Myinput
              variant="unstyled"
              InputLeftElement={<IconUser color={isDark ? colors.bluey_grey : colors.dark} />}
              placeholder={i18n.t('auth.placeholder_name')} // mx={4}
              onChangeText={(txt) => setNameValue(txt)}
            />
          </InputsBox>
        </SelectContainer>
      </InnerContainer>
      <ButtonsContainer>
        <MYButton color="blue" style={{ width: '100%' }} onPress={handlePressNext} loading={loadingBtn}>
          {i18n.t('auth.continue')}
        </MYButton>
      </ButtonsContainer>
    </Container>
  );
}

const Container = styled.View`
  display: flex;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
  flex: 1;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
`;
const InnerContainer = styled.ScrollView`
  width: 100%;
`;
const ButtonsContainer = styled.View`
  padding: 0 34px;
  width: 100%;
  margin-bottom: 28px;
  flex-shrink: 0;
`;

const SelectContainer = styled.View`
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 50px; */
  margin-top: 7%;
  flex: 1;
  width: 100%;
  padding: 0 35px;
`;

const Title = styled.Text`
  font-family: Gilroy-Bold;
  margin-bottom: 51px;
  font-size: 22px;
  text-align: center;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dusk)};
`;

const InputsBox = styled.View`
  border-radius: 28px;
  border: solid 1px;
  border-color: ${(p) => (p.theme.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey)};
  padding: 15px 20px;
`;
