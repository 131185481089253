import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg width={21} height={19} viewBox="0 0 21 19" color="#323232" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path fillRule="evenodd" clipRule="evenodd" d="M13 .25a.75.75 0 01.75.75v2a.75.75 0 01-1.5 0V1A.75.75 0 0113 .25z" fill="#323232" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 2a.75.75 0 01.75-.75h2a.75.75 0 010 1.5h-2a.75.75 0 01-.75-.75zM18 3.25a.75.75 0 01.75.75v4a.75.75 0 01-1.5 0V4a.75.75 0 01.75-.75z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.25 6a.75.75 0 01.75-.75h4a.75.75 0 010 1.5h-4a.75.75 0 01-.75-.75zM8.963 1.631a.75.75 0 01-.047.81 6.415 6.415 0 007.215 9.861.75.75 0 01.276-.052h.003a.75.75 0 01.666 1.095A8.75 8.75 0 118.245 1.253a.75.75 0 01.718.378zm6.01 12.453A7.915 7.915 0 016.85 3.047a7.25 7.25 0 108.123 11.037z"
        fill="currentColor"
      />
    </Svg>
  );
}

export default SvgComponent;
