import * as React from 'react';
import Svg, { Defs, G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: style */

const SvgComponent = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" color="#000" width={24} height={24} {...props}>
    <Defs></Defs>
    <G id="Photo_Image_Square" data-name="Photo, Image, Square" transform="translate(0 -.014)">
      <Path
        id="Path_6049"
        data-name="Path 6049"
        d="M0 .014h24v24H0z"
        style={{
          fill: 'none',
        }}
      />
      <Path id="Path_6050" data-name="Path 6050" stroke="currentColor" d="m21 16.014-4.293-4.293a1 1 0 0 0-1.414 0L11 16.014" />
      <Path
        id="Path_6051"
        data-name="Path 6051"
        stroke="currentColor"
        d="M18 21.014H6a3 3 0 0 1-3-3v-12a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3z"
      />
      <Path id="Path_6052" data-name="Path 6052" stroke="currentColor" d="m16 21.014-6.293-6.293a1 1 0 0 0-1.414 0l-4.847 4.847" />
      <Path id="Path_6053" data-name="Path 6053" stroke="currentColor" d="M9.265 8.749a.375.375 0 1 1-.53 0 .375.375 0 0 1 .53 0" />
    </G>
  </Svg>
);

export default SvgComponent;
